.interests-container {
  .interest-row {
    display: flex;
    margin-bottom: 30px;

    .interest-information {
      flex-grow: 1;
      margin-left: 10px;
      max-width: 437px;
      .header {
        font-size: 13px;
        font-weight: 500;
        color: $charcoal-gray;
        margin-bottom: 10px;
      }
      .description {
        font-size: 12px;
        color: $gray-text;
        margin-bottom: 10px;
        word-break: break-word;
      }
      .details {
        width: 50%;
        float: left;
        .details-row {
          display: flex;
          font-size: 12px;
          margin-right: 5px;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .title {
            font-weight: 500;
            color: $charcoal-gray;
            min-width: 46%;
          }
          .content {
            word-break: break-word;
            color: $gray-text;
          }
        }
      }
    }

    .date-circle {
      width: 63px;
      height: 63px;
      margin-right: 10px;
      color: $date-circle-blue;
      &.not-current {
        color: $historical-bg;
      }
      .circle {
        border: 1px solid;
        font-size: 12px;
        flex-flow: column;
        height: 100%;
        width: 63px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
