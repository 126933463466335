@import "../../variable";

.tool-tip-component {
  .icon-container {
    line-height: 2;
    font-size: 7px;
    position: relative;
    color: $charcoal-gray;
  }

  &.gray {
    .icon-container {
      color: $gray-text;
    }
  }
  &.white {
    .icon-container {
      color: white;
    }
  }
  &.black {
    .icon-container {
      color: black;
    }
  }
  &.occupancy-tooltip {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 3px;
    font-size: 16px;
    vertical-align: middle;
    line-height: 1px;
    color: $charcoal-gray;
    cursor: pointer;
  }
  &.property-notes-tooltip {
    margin-left: 0!important;
  }
}