.avm-detail {
  ul {
    margin-bottom: 0px;
  }

  .avm-confidence {
    .avm-confidence-lvl {
      background-color: $body-bg;
      padding: 5px 10px 5px 10px;
      border-radius: 11px;
      color: $gray-text;
      font-size: 13px;
      & > i {
        &:first-child {
          font-size: 8px;
          margin-right: 2px;
          position: relative;
          top: -2px;
        }
      }
      img {
        margin-left: 2px;
        margin-top: -2px;
      }
    }
  }
  .avm-confidence p {
    display: inline-block;
  }

  .flexed-panel {
    display: flex;
    padding: 0;

    > [class^="col"] {
      flex: 1;
    }

    .flexed-panel-end {
      align-self: flex-end;
    }

    .flexed-panel-center {
      align-self: center;
      h4 {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

  .avm-unavailable {
    height: 150px;
    padding: 10px;
    font-family: $poppins;
    .error-fetching {
      padding: 0px;
      min-height: 0px;
    }
  }

  .rental-avm {
    text-align: right;
    a {
      color: $blue;
    }
  }
  .mdi-information-outline {
    margin-left: 3px;
    font-size: 16px;
    vertical-align: text-top;
  }
  .mdi-brightness-1 {
    font-size: 8px;
    padding: 4px 2px;
    vertical-align: text-top;
  }

  .flex-row {
    display: flex;
    align-items: center;

    &.valuation-range-header {
      .legend {
        font-size: 14px;
        font-weight: 500;
        color: $heading-color;
      }
    }

    &.valuation-range-footer {
      .legend {
        font-size: 26px;
        font-weight: 500;
        color: $heading-color;
      }
      .flex-grow {
        font-size: 20px;
        align-self: center;
      }
    }

    &.valuation-range-visual {
      margin-top: 10px;
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }

    .flex-grow {
      flex: 1;
      flex-grow: 1;

      strong {
        color: #000000;
        font-weight: 500;
      }
    }
    .flex-shrink {
      flex-shrink: 1;
    }
  }

  .property-panel-body {
    min-height: 150px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0;
  }

  .live-avm-link {
    text-align: center;
    margin-top: 10px;
    &.not-hyperlink {
      cursor: default;
    }
  }

  #rental-avm-details {
    margin-top: 20px;
  }

  .block-ui {
    height: 230px;
  }
  .error-fetching {
    padding-top: 30px;
  }
}

.avm-unavailable-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
