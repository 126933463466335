@import '../../../variable';

.update-attributes-container {
  display: flex;

  .attribute-container {
    &:not(:last-child) {
      margin-right: 34px;
    }

    .attribute-label {
      color: $charcoal-gray;
      font-size: 14px;
      font-weight: 500;
    }
    .attribute-field {
      &.has-error {
        border-color: $errorFieldBorder;
        background-color: $errorFieldBg;
      }
      margin-top: 9px;
      width: 69px;
      height: 37px;
      border-radius: 2px;
      border-color: $gray-border-light;
    }
    .attribute-field-with-unit {
      position: relative;
      width: 100px;
      & > * {
        display: inline-block;
      }
      .attribute-field {
        border-radius: 2px 0 0 2px;
      }
      .attribute-unit {
        display: inline-block;
        width: 30px;
        height: 37px;
        text-align: center;
        background-color: $gray-darker-background;
        line-height: 37px;
        font-size: 14px;
        border-radius: 0 2px 2px 0;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
