#filter-last-sale {
  .row {
    min-height: auto;
  }
  .form-section {
    margin-bottom: 10px;
  }
}

  // #material-ui-date-picker:before, #material-ui-date-picker:after {
  //   display: none !important;
  // }


.filter-last-sale-text-label {
    width: 100%;
    height: 18px;
    font-size: 13px;
    font-weight: 500;
    color: $heading-color;
    margin-bottom: 15px;
}

.last-sale-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  .sale-date-label {
    width: 40px;
    height: 18px;
    font-size: 13px;
    color: $heading-color;
  }
  > div.col-md-5 {
    div.react-datepicker-wrapper {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
  & > div {
    &:nth-child(2) {
      & > div {
        & > div {
          &:before, &:after {
            display: none;
          }
        }
      }
    }
  }
  #exclude-agent-advice{
    width: 13px;
    height: 13px;
    padding: 0;
    margin:0;
    vertical-align: bottom;
    position: relative;
    top: 3px;
    *overflow: hidden;
  }
  .exclude-aa-label{
    color: $heading-color;
  }
}
.picker-custom-input {
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 34px;
  color: #555 !important;
  background-color: #fff;
  display: flex;
  align-items: center;
}
