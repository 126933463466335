@import 'source/css/crux/mixins';

#additional-information-view{
  position: relative;
  .additional-information-panel {
    .additional-information-container{
      height: calc(100% - 150px);
      margin-right: 1px;
      position: relative;
      .loader {
        background-color: white;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 180px;
        left: 0;
        @include center-align;
      }
    }
    .hide {
      display: none;
    }
    .error-fetching {
      padding: 0;
      margin: auto;
    }
    .property-panel {
      min-height: 315px;
    }
  }
  .show-more-container {
    padding-top: 20px;
    & a {
      color: $blue;
    }
  }
  .withheld-tooltip-info {
    align-items: flex-end;
  }
}

#additional-information {
  height: 100%;
  .land-valuation-history {
    margin-top: -15px;
    align-items: center;
    display: flex;
    padding-top: 17px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 17px;
    & > p {
      & > span {
        &:first-child {
          color: $heading-color;
          font-weight: 500;
          margin-right: 20px;
        }
      }
    }
  }
  .land-values-header, .additional-lots-header {
    align-items: center;
    display: flex;
    height: 20%;
    color: $heading-color;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .additional-info-row {
    height: 10%;

    padding-top: 10px;
    padding-bottom: 10px;
  }
  .additional-lots-row {
    height: 10%;
    padding-top: 10px;
  }
  .no-info-available {
    padding-top: 20%;
    text-align: center;
  }
}

.primary-blue-button {
  color: $blue;
}
.similar-property {
  button {
    @extend .primary-blue-button;
  }
}
.button-order-titles {
  min-width: 190px !important;
}
