.map-key-container {
  background-color: $dirty-white;
  display: none;
  font-size: 14px;
  color: #38424a;
  position: absolute;
  min-height: 125px;
  width: 100%;
  top: 100%;
  z-index: 99;
  padding: 20px 0;

  .title {
    font-weight: 500;
    padding-bottom: 5px;
  }

  .section {
    padding: 0 20px;
    &.salesInfo {
      width: 30%;
      &.column-2 {
        width: 40%;
      }
      .layer {
        min-width: 150px;
      }
    }

    &.recentListingInfo {
      width: 30%;
      .layer {
        min-width: 130px;
      }
    }

    &.areaResearch {
      width: 30%;
      .layer {
        min-width: 110px;
      }
    }
  }

  .layer-item {
    .layer {
      @extend .no-horizontal-padding;
      font-size: 10px;
      align-items: center;
      display: flex;
      margin: 3px 0;

      .key-box {
        height: 16px;
        width: 16px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 5px;

        &.red { background-color: #990000; }
        &.lavender { background-color: #996699; }
        &.pink { background-color: #e03c31; }
        &.orange { background-color: #ff9900; }
        &.purple { background-color: #660066; }
        &.green { background-color: #669933; }
        &.lightblue { background-color: #006699; }

        &.et-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-et-ticked.png");
          border: solid 1px #d9d9d9;
        }

        &.et-bg-nz {
          background-image: url("../../../../../assets/img/map-area-research/ar-et-ticked-nz.png");
          border: solid 1px #d9d9d9;
        }

        &.ctr-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-ctr-ticked.png");
        }

        &.lga-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-lga-ticked.png");
        }

        &.loc-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-loc-ticked.png");
        }

        &.bf-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-bushfire-ticked.png");
        }

        &.fl-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-flood-ticked.png");
        }

        &.hr-bg {
          background-image: url("../../../../../assets/img/map-area-research/ar-heritage-ticked.png");
        }

        &.ctr-bg, &.lga-bg, &.loc-bg {
          background-repeat: no-repeat;
          background-position: center;
          border: solid 1px #d9d9d9;
        }
      }
    }
  }
}

