@import '../../variable';

.crux-form {
  height: 37px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  background-color: #ffffff;
  border-color: $gray-border-light;
  font-size: 12px;
  margin: 5px 0;
  box-shadow: none;
  &:focus {
    border-color: $blue;
    outline: none;
  }
}
.crux-form-select {
  &__indicator-separator {
    display: none;
  }
  &__value-container {
    color: $gray-text;
    text-align: center;
    div {
      color: inherit;
    }
  }
  &__control {
    @extend .crux-form;
    box-shadow: none !important;
  }
}
.crux-input-container {
  position: relative;
  margin: 5px 0;
  &.with-input-validation {
    svg {
      height: 15px;
      font-size: 15px;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      &.fa {
        &-check {
          color: $soft-green;
        }
        &-times {
          color: $error;
        }
      }
    }
    .crux-form-text,
    .crux-form-email,
    .crux-form-number,
    .crux-form-password,
    .crux-form-tel {
      padding-right: 30px;
    }
  }
  .crux-form-text,
  .crux-form-email,
  .crux-form-number,
  .crux-form-password,
  .crux-form-tel {
    @extend .crux-form;
    padding: 10px;
    width: 100%;
    margin: 0;
    &.crux-form-error, &.crux-form-error:focus {
      border-color: $errorFieldBorder;
      background-color: $errorFieldBg;
    }
  }
  &:disabled {
    color: $disabled-dark;
  }
  input[disabled] {
    color: $gray-text;
    -webkit-text-fill-color: $gray-text;
    opacity: 1;
  }
}

.crux-form-label {
  color: $heading-color;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
}
.form-required {
  color: $error;
}
.form-required-legend {
  font-size: 13px;
  color: $gray-text;
  font-weight: normal;
}
.mui-info-icon {
  width: 18px !important;
  position: relative;
  left: 10px;
  top: 2px;
  cursor: pointer;
  // should apply in safari only -  see https://stackoverflow.com/a/23948854
  &:not(:root:root) {
    margin-right: 5px;
  }
}
