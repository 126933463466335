@import 'source/css/crux/variable';
@import 'source/css/crux/mixins';

#favorites-container {
  &.favorites-page {
    width: 1140px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tab-panel-favourites {
    min-height: calc(100vh - 480px);
  }

  .block-ui {
    min-height: 1000px;
  }

  .block-ui-container .block-ui-overlay {
    background-color: #fafafa;
  }

  .savedSearch-tabs {
    .tab-menu {
      & > span {
        font-family: $poppins;
      }
    }
    .disabled {
      cursor: default;
      & > span {
        color: $disabled-dark!important;
      }
    }
  }

  .card-view-container {
    .card-view-panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      width: 365px;
      margin: 15px 8px 15px 8px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
      &:hover,
      &:active {
        box-shadow: 0 0 5px 1px $blue;
        transition: all ease 0.3s;
      }
    }

    .more-filter-panel {
      padding: 0 0 16px 16px;
    }

    .property-tag {
      height: 22px;
      font-family: $poppins;
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      display: inline-block;
      margin: 0 5px;
      padding: 4px 8px;
      text-transform: uppercase;
      border-radius: 4px;
      float: right;
      &.tag-red {
        background-color: $search-recent-sale-tag;
      }
      &.tag-green {
        background-color: $search-for-rent-tag;
      }
      &.tag-blue {
        background-color: $search-for-sale-tag;
      }
      &.tag-purple {
        background-color: $search-recent-advice-tag;
      }
      &.tag-orange {
        background-color: $search-historical-tag;
      }
    }

    .card-view-head {
      margin-bottom: 6px;
      .card-view-title {
        font-family: $poppins;
        font-weight: 500;
        font-size: 14px;
        max-width: 240px;
        word-break: break-word;
        min-height: 20px;
        display: block;

        & > span {
          word-wrap: break-word;
        }

        &.search-dialog-title {
          max-width: fit-content;
        }

        &.search-name-btn {
          border: none;
          background-color: inherit;
          padding: 0;
          display: inline-block;
          color: $charcoal-gray;
          vertical-align: middle;

          &:hover{
            text-decoration:underline;
            color: $blue;
          }
        }

        &.hidden-overflow {
          @extend .text-ellipsis;
        }
      }

      .card-view-subtitle {
        font-family: $poppins;
        font-weight: 500;
        font-size: 12px;
        color: $black-50-opaque;
      }

      .top-align {
        vertical-align: top;
      }
    }

    .card-view-body {
      font-family: $poppins;
      font-weight: 400;
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 3px;

      &.hidden-overflow {
        width: 320px;
        @extend .text-ellipsis;
      }
      &.primary-search-filters {
        ul {
          flex-wrap: wrap;
          li:last-child {
            overflow: visible;
          }
        }
      }
    }

    .card-view-footer {
      margin-top: 15px;
    }

    .more-filter-attribute {
      padding-top: 8px;
      padding-bottom: 8px;

      .attribute-name {
        font-family: $poppins;
        font-weight: 400;
        font-size: 12px;
        color: $black-60-opaque;
      }

      .attribute-value {
        font-family: $poppins;
        font-weight: 400;
        font-size: 12px;
      }

      .card-view-body {
        &__text-field {
          & > span {
            overflow-wrap: anywhere;
          }
        }
      }
    }

    .space-between {
      display: flex;
      justify-content: space-between;
    }

    .notification-wrapper {
      height: 91px;
      width: 100%;

      .notification-text-indicator {
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      .notification {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        margin-top: 4px;
        margin-bottom: 2px;
        width: 100%;
        height: 27px;

        &.new-properties,
        &.removed-properties,
        &.updated-properties {
          border-radius: 4px;

          & > span {
            font-family: $poppins;
            margin-left: 10px;
            font-size: 12px;
            letter-spacing: 0.4px;
          }

          & > svg {
            font-size: 18px;
            margin-left: 6px;
          }
        }

        &.new-properties,
        &.updated-properties {
          background-color: rgba(76, 175, 80, 0.08);
        }
        &.removed-properties {
          background-color: rgba(255, 152, 0, 0.08);
        }
      }
    }

    .icon-text-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.right-align {
        float: right;
      }

      .setting-btn {
        margin-left: 4px;
      }
    }

    .more-filter-container {
      margin-top: 3px;
      margin-bottom: 3px;
      height: 20px;
    }

    .card-view-btn {
      border: none;
      background-color: inherit;
      font-family: $poppins;
      font-weight: 500;
      font-size: 13px;
      padding: 0;
      color: $blue;

      &.more-filter-btn {
        font-size: 12px;
        font-weight: 400;
      }

      &:hover{
        text-decoration:underline;
      }
    }

    ul {
      &.list-inline{
        display: flex;
        align-items: center;
        margin-bottom: 0;
        max-width: 333px;

        li {
          white-space: nowrap;
          padding: 0 3px;
          &:last-child {
            overflow: hidden;
          }
        }
      }
      .property-attribute {
        display: block;
        i {
          font-size: inherit;
          margin-left: 7px;
        }
        .property-attribute-unit {
          display: table-cell;
          vertical-align: middle;
          text-align: left;
          p {
            margin-bottom: 0;
          }
        }
        .property-attribute-val {
          display: flex;
          vertical-align: middle;
          padding: 3px 0;
          color: $heading-color;
          font-weight: 500;
          font-size: 12px;
          @media screen and (max-width: 1024px) {
            padding: 5px 0;
          }
          > span {
            display: flex;
            align-items: center;
            overflow: hidden;
            > span {
              padding-left: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .empty-saved-search-container,
  .unsubscribed-page-container {
    min-height: calc(100vh - 555px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $black-87-opaque;
    gap: 5px;
    .icon-wrapper {
      width: 60px;
      margin: 0 auto;
      & > svg {
        color: $blue;
        font-size: 60px;
      }
    }
  }

  .dialog-close-btn {
    margin: 7px 5px 7px auto;
  }

  .dialog-paper {
    padding: 0;
    margin-right: 0;
    margin-bottom: 16px;
    width: 400px;
    max-height: 400px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-darker-background;
    }

    &::-webkit-scrollbar-thumb {
      background: $blue;
      border-radius: 3px;
    }
  }

  .coming-soon-tool-tip {
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal;
    width: auto;
    text-align: center;
    box-shadow: none;
  }
}
