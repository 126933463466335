@import 'source/css/crux/mixins';

.search-result-tag {
  color: #FFFFFF;
  min-width: 80px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 400;
}

#search-result-table {
  position: relative;
  transition: left 0.3s ease-in-out;
  left: 0;
  &.slide-out {
    left: -100vw;
  }
  #card-view-container {
    .card-view {
      .property-image-carousel {
        .app-image-gallery-component {
          .image-gallery {
            .image-gallery-index {
              background-color: $black-54-opaque;
              @include border-radius(50px);
              padding: 5.5px 10px;
              right: 50%;
              margin-right: -30px;
              top: 16px;
              z-index: 2;
            }
          }
        }
      }
    }
  }
  .table-container, .table-responsive {
    overflow: initial !important;
    .table {
      thead {
        background-color: $body-bg;
        & > tr {
          border: 1px solid $gray-border-light;
          th {
            white-space: nowrap;
            vertical-align: middle;
            border-bottom: none;
            a.sort-icon {
              color: $gray-darker-text;
              font-weight: 500;
              cursor: pointer;
              &.cursor-initial {
                cursor: initial;
              }
              span.sort-icon-arrows {
                vertical-align: middle;
                display: inline-flex;
                flex-flow: column;
                margin-left: 5px;
                height: 20px;
                img {
                  display: block;
                  margin-top: 2px;
                  margin-bottom: 2px;
                  width: 10px;
                  &.ascending {
                    margin-bottom: auto;
                  }
                  &.descending {
                    margin-top: auto;
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }

      tbody {
        background-color: #FFFFFF;
        & > tr > td {
          color: $black-87-opaque;
        }
      }

      .col-text-center {
        text-align:center;
      }

      .col-attributes {
        width: 58px;
      }

      .col-land-size {
        div {
          display: flex;
          align-items: center;
        }
        width: 85px;
      }

      .owner-column {
        & > div {
          display: inline-block;
        }
      }

      .search-result-tag-cont {
        display: flex;
        top: 0;
        line-height: 20px;
        letter-spacing: 1.5px;
        font-size: 10px;
        flex-wrap: wrap;
        .recent-sale-tag {
          background-color: $search-recent-sale-tag;
          margin-right: 10px;
          margin-bottom: 1px;
          flex-basis: 45%;
          @extend .search-result-tag;
        }
        .for-sale-tag {
          background-color: $search-for-sale-tag;
          margin-right: 10px;
          flex-basis: 45%;
          @extend .search-result-tag;
        }
        .for-rent-tag {
          background-color: $search-for-rent-tag;
          margin-right: 10px;
          flex-basis: 45%;
          @extend .search-result-tag;
        }
        .recent-advice-tag {
          background-color: $search-recent-advice-tag;
          margin-right: 10px;
          margin-bottom: 1px;
          flex-basis: 45%;
          @extend .search-result-tag;
        }
        .historical-tag {
          background-color: $search-historical-tag;
          margin-right: 10px;
          flex-basis: 45%;
          @extend .search-result-tag;
        }
        .custom-tool-tip {
          line-height: normal;
          letter-spacing: normal;
          width: auto;
          text-align: center;
          box-shadow: none;
        }
      }

      .flex-row {
        display: flex;
        align-items: center;
        position: relative;
        .flex-grow {
          flex: 1;
          flex-grow: 1;
          &.search-result-address-container {
            display: flex;
            align-items: center;
          }
        }

        .flex-shrink {
          flex-shrink: 1;

          &.search-result-img-container {
            flex-basis: 120px;
            position: relative;
            & > .saved-list-star-wrapper {
              position: absolute;
              right: 4px;
              top: 4px;
            }
            & > .search-result-img {
              min-height: 80px;
              background-position: center;
              background-size: contain;
              display: flex;
              flex-direction: column;
              &.no-image {
                display: block;
              }
              img {
                min-width: 120px;
              }
              img:not(.img-responsive) {
                display: block;
                max-width: 120px;
                height: auto;
              }
              & > img {
                max-width: 120px;
              }
            }
            .loader-container {
              min-height: 80px;
              min-width: 120px;
              .loading-bullet {
                font-size: 1.3em;
              }
            }
          }
        }
      }

      .crux-typo-link {
        color: $black-87-opaque;
        font-weight: 400;
        &:hover {
          color: $blue;
        }
      }

      .search-result-address-container {
        margin-left: 15px;
        margin-right: 15px;
        width: 230px;
        a {
          text-transform: uppercase;
        }
      }

      span.search-result-land-area-cell {
        .md-left-5 {
          padding-left: 5px;
          font-size: 16px;
        }
        #search-result-land-size {
          font-size: 10px;
        }
      }
      .search-result-sale-details {
        .block-ui-overlay {
          background-color: transparent;
        }
        .loading-bullet {
          font-size: 1.5em;
          color: #D7D7D7;
        }
        span.search-result-sale-date {
          white-space: nowrap;
        }
        .name-button {
          border: none;
          background: transparent;
          padding: 0;
          text-align: left;
          .owner-name {
            color: $blue;
            &:hover {
              color: $blue-hover;
            }
          }
        }
      }
    }
    .sale-price {
      width: 115px;
    }
  }
  .show-more-container {
    min-height: 36px;
    .show-more {
      border-radius: 4px !important;
      background-color: $blue;
      color: white;
      text-transform: initial;
      font-size: 13px;
      padding: 5px 12px;
      &:hover {
        background-color: #005fbe;
        transition: all ease 0.5s;
      }
      &:disabled {
        background-color: $disabled !important;
      }
    }
    .result-count-limit {
      padding-top: 1px;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    .block-ui-overlay {
      background-color: transparent;
    }
  }
}
.display-message {
  height: 100%;
  @include center-align;
  font-size: 14px;
}
