@import '../../../mixins';
@import '../../../variable';

#timeLine {
  .button-dep-calc {
    min-width: 190px !important;
    margin-left: auto;

    svg {
      position: relative;
      top: 2px;
      opacity: 0.6;

      & + span {
        position: relative !important;
        top: -1px;
      }
    }
  }

  .nav-container {
    background-color: #f3f2f1;
    padding: 5px 10px;
    @include border-radius(4px);

    .nav-pills {
      & > {
        li {
          cursor: pointer;
          cursor: hand;

          > button {
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            color: $heading-color;
            font-family: $poppins;

            &.text-capitalize {
              text-transform: capitalize !important;
            }
          }

          &.active {
            & > button {
              background-color: #fff;
              color: $blue;
            }
          }
        }
      }
    }
  }

  .timeline-container {
    height: 440px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 30px;

    .no-history {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      text-align: center;
      line-height: 440px;
    }

    ul {
      padding-top: 30px;
      position: relative;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        background-color: #d9d9d9;
        position: absolute;
        top: 15px;
        left: 23.5px;
        @include border-radius(50%);
        @media screen and (max-width: 1024px) {
          left: 25.5px;
        }
      }

      &:after {
        content: 'Present Day';
        color: $heading-color;
        font-weight: 500;
        font-size: 14px;
        position: absolute;
        top: 10px;
        left: 83px;
        @media screen and (max-width: 1024px) {
          left: 81px;
        }
      }

      li {
        display: flex;
        align-items: center;
        padding: 15px 0;
        overflow: hidden;

        &:first-child {
          padding: 20px 0 10px;
        }

        &:last-child {
          padding: 10px 0 0;
        }

        .date-circle {
          width: 63px;
          height: 63px;
          min-width: 63px;
          display: inline-block;
          margin-right: 20px;
          position: relative;
          z-index: 9;
          @media screen and (max-width: 1024px) {
            margin-right: 15px;
            width: 65px;
            height: 65px;
            min-width: 65px;
          }

          &:after {
            content: '';
            width: 2px;
            background-color: #d9d9d9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 500px;
            z-index: -1;
          }

          & > .circle {
            flex-flow: column;
            @include center-align ;
            height: 100%;
            @include border-radius(50%);
            background-color: white;
            @media screen and (max-width: 1024px) {
              font-size: 13px;
            }
          }

          &.green-circle {
            .circle {
              border-color: $search-for-rent-tag;
              color: $search-for-rent-tag;
            }
          }

          &.red-circle {
            .circle {
              border-color: $search-recent-sale-tag;
              color: $search-recent-sale-tag;
            }
          }

          &.blue-circle {
            .circle {
              border-color: $search-for-sale-tag;
              color: $search-for-sale-tag;
            }
          }

          &.yellow-circle {
            .circle {
              border-color: $yellow;
              color: $yellow;
            }
          }

          &.black-circle {
            .circle {
              border-color: black;
              color: black;

              & > span {
                width: 100%;
                text-align: center;
              }
            }
          }

          .circle {
            border: 1px solid;
            font-size: 12px;
          }
        }

        .prop-info {
          flex-grow: 1;
          max-width: 465px;

          .single-space {
            padding-right: 5px;
          }

          .double-space {
            padding: 0 5px;
          }

          button {
            border: 0;
            color: $blue;
            background-color: transparent;
          }

          .ago {
            padding-left: 5px;
          }

          .sale-info {
            & > div {
              @media screen and (max-width: 1024px) {
                width: 100%;
              }

              h5 {
                @media screen and (max-width: 1024px) {
                  margin: 0;
                }
              }

              &:last-child {
                font-size: 13px;
                margin-bottom: 10px;

                button {
                  margin-top: 5px;
                }
              }
            }

            .view-desc-link {
              padding-right: 30px;
            }
          }

          & > .h5 {
            h5 {
              margin-top: 0;
              display: inline;
            }
          }

          &.rent-list {
            & > .row {
              & > div[class^="col"] {
                @media screen and (max-width: 1024px) {
                  width: 100%;
                  text-align: left;
                  h5 {
                    margin: 0;
                  }
                }

                &:last-child {
                  @media screen and (max-width: 1024px) {
                    margin-bottom: 10px;
                  }

                  button {
                    margin-top: 5px;
                  }
                }
              }
            }

            .view-desc-link {
              button {
                padding: 2px 0 0 0;
              }
            }
          }

          .undisclosed-price-info {
            color: $header-dark;
          }
        }

        h5 {
          font-size: 14px;
          color: $heading-color;
          font-weight: 500;
        }
      }
    }

    .undisclosed-sales {
      > span {
        @include header-text(14px, 500);
      }
    }
  }

  a, a:hover {
    color: $link;
  }
}

.pr-20 {
  padding-right: 20px;

  button {
    padding: 0;
  }
}

.flex-row {
  display: flex;

  .flex-grow {
    flex-grow: 1;
  }
}

.sale-property-campaign {
  margin-right: 20px;

  &[hidden] {
    margin-right: 0;
  }
}

.sale-property-campaign, .sale-agency-campaign {
  button {
    padding: 0;
  }
}

a, a:hover {
  color: $link;
}

// For timeline wrapper
.timeline-panel {
  padding: 20px 30px 30px;
  min-height: 618px;

  .property-timeline__timeline {
    font-family: $poppins;
  }

  .property-timeline__timeline--tab {
    font-weight: 500;
  }

  .property-timeline__timeline--tab-content {
    ul {
      li {
        .date-circle {
          width: 65px;
          height: 65px;
          min-width: 65px;

          .circle {
            font-size: 11px;
          }
        }

        .heading {
          font-weight: 500;
        }
      }

      &:after {
        font-weight: 500;
      }
    }
  }

  & > .white-blocker {
    height: 516px;
  }

  .depreciation-calculator-container {
    float: right;
  }
}

@media(max-width: 1365px) {
  .property-timeline__timeline--tab {
    padding: 5px 10px;
    text-align: center;
  }
}

.property-timeline {
  &__timeline {
    font-family: $open-sans;

    &--tabs {
      display: flex;
      background-color: $light-gray;
      padding: 5px 10px;
      border-radius: 4px;
      justify-content: space-around;
      color: $dark;
    }

    &--tab {
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      min-height: 36px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 12px;
      font-weight: 600;

      &.active {
        background-color: #fff;
        color: $blue;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }

    &--tab-content {
      margin: 30px 0px 0px;
      height: 440px;

      ul {
        padding: 30px 15px 0 0;
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;

        &:before {
          content: "";
          width: 15px;
          height: 15px;
          background-color: $gray-border-light;
          position: absolute;
          top: 15px;
          left: 23.5px;
          border-radius: 50%;
        }

        &:after {
          content: "Present Day";
          color: $dark;
          font-weight: 600;
          font-size: 14px;
          position: absolute;
          top: 10px;
          left: 83px;
        }

        li {
          &:first-child {
            padding: 20px 0 10px;
          }

          &:last-child {
            padding: 9px 0 2px;
          }

          display: flex;
          -webkit-box-align: center;
          align-items: center;
          padding: 15px 0;
          overflow: hidden;
          list-style: none;

          &.year-built-circle {
            .date-circle {
              .circle {
                border-color: black;
                color: black;
                text-align: center;
              }
            }
          }

          .date-circle {
            width: 63px;
            height: 63px;
            min-width: 63px;
            display: inline-block;
            margin-right: 20px;
            position: relative;
            z-index: 9;

            &:after {
              content: "";
              width: 2px;
              background-color: $gray-border-light;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              height: 500px;
              z-index: -1;
            }

            .circle {
              border: 1px solid;
              font-size: 12px;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              flex-flow: column;
              height: 100%;
              border-radius: 50%;
              background-color: #fff;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              text-align: center;
            }

            &.blue {
              border-color: $history-listing-blue;
              color: $history-listing-blue;
            }

            &.red {
              border-color: $history-sale-red;
              color: $history-sale-red;
            }

            &.green {
              border-color: $history-rental-green;
              color: $history-rental-green;
            }

            &.yellow {
              border-color: $history-da-yellow;
              color: $history-da-yellow;
            }

            &.yellow-2 {
              border-color: $history-bc-yellow;
              color: $history-bc-yellow;
            }
          }

          .prop-info {
            flex-grow: 1;
            max-width: 465px;
            box-sizing: border-box;

            .link-container {
              .property-anchor__link {
                margin-right: 20px;
                display: inline-block;
                cursor: pointer;
                user-select: none;
                font-family: inherit;
                color: $blue;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }

          .heading {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
            margin-bottom: 10px;
            margin-top: 10px;
            line-height: 1.1;
          }

          .details {
            font-size: 13px;
            color: $gray;
            width: 98%;
          }
        }
      }

      .no-history {
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        text-align: center;
        line-height: 440px;
      }
    }
  }
}
