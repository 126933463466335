@import 'source/css/crux/mixins';

#card-view-container, .card-view-container {
  .card-view {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    min-height: 286px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.09);
    @include border-radius(4px);
    background-color: #ffffff;
    position: relative;

    .property-image-carousel {
      border-right: 1px solid rgba(lighten($gray-text, 1%), 0.1);
      width: 430px;
      height: 286px;
      flex: none;
      box-sizing: content-box;
      position: relative;
      overflow: hidden;
      img {
        object-fit: cover;
        object-position: left;
        height: 100%;
        width: 100%;
      }
      & > .loader-container {
        width: 100%;
      }
      .app-image-gallery-component {
        .image-gallery-slide-wrapper {
          > span {
            > button {
              opacity: 0;
              transition: opacity 0.3s ease;
            }
          }

          &:hover {
            > span {
              > button {
                opacity: 1;
                transition: opacity 0.3s ease;
              }
            }
          }
          .image-gallery-swipe {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            .image-gallery-slide {
              cursor: default;
            }
          }
        }
      }
      .radius-from-target {
        position: absolute;
        left: 0;
        bottom: 15px;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 7px 11px 7px 15px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.31);
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 12px;
        color: $heading-color;
        @include center-align;
        & > span {
          margin-left: 5px;
        }
      }
    }
    .property-details {
      flex-grow: 1;
      display: flex;
      .details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 15px 10px 10px 20px;
        .heading {
          display: flex;
          padding-bottom: 6.5px;
          border-bottom: 1px solid rgba(lighten($gray-text, 1%), 0.1);
          margin-bottom: 10px;
          .address-complete {
            margin-right: 10%;
          }
          .property-attributes {
            flex-grow: 1;
            margin-top: -4px;
            .list-inline {
              justify-content: flex-end;
              .property-attribute {
                &[type="bed"], &[type="bath"], &[type="car"] {
                  width: 40px;
                }
                &[type="bed"], &[type="bath"] {
                  img {
                    width: 24px;
                  }
                }
                &[type="car"] {
                  img {
                    width: 27px;
                  }
                }
                .property-attribute-val {
                  padding: 0;
                }
              }
            }
          }
          & > a {
            text-transform: uppercase;
          }
        }
        .property-attribute-container {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .property-attribute-val {
            img {
              width: 20px !important;
            }
          }
        }
        #loader {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          &.block-ui {
            height: 100%;
            .block-ui-container {
              z-index: 1;
            }
            .block-ui-overlay {
              background-color: transparent;
            }
            .loading-bullet {
              color: #797979;
            }
          }
        }
        .property-summary {
          flex-grow: 1;
          .row {
            .summary-column {
              .summary-container {
                margin-bottom: 8px;
                display: flex;
                .block-ui {
                  display: inline-block;
                  width: 50px;
                  min-height: 18px;
                  height: 18px;
                  overflow: hidden;
                  .block-ui-container {
                    min-height: 18px;
                    overflow: hidden;
                    .block-ui-overlay {
                      background-color: transparent;
                    }
                    .loading-bullet {
                      font-size: 1.5em;
                      color: #D7D7D7;
                      line-height: 18px;
                    }
                    span.search-result-sale-date {
                      white-space: nowrap;
                    }
                  }
                }
                .summary-label {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 166%;
                  letter-spacing: 0.4px;
                  color: $black-60-opaque;
                  display: inline-block;
                  vertical-align: top;
                  white-space: nowrap;
                }
                .summary-value {
                  margin-left: 5px;
                  color: $black-87-opaque;
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 166%;
                  letter-spacing: 0.4px;
                  .withheld-tooltip-info {
                    align-items: flex-end;
                  }
                }
                .summary-value.text-capitalize{
                  text-transform: lowercase;
                }
                .summary-value.text-capitalize::first-letter{
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .search-result-tag-cont {
          .recent-sale-tag {
            background-color: $search-recent-sale-tag;
            margin-right: 10px;
            @extend .search-result-tag;
          }
          .for-sale-tag {
            background-color: $search-for-sale-tag;
            margin-right: 10px;
            @extend .search-result-tag;
          }
          .for-rent-tag {
            background-color: $search-for-rent-tag;
            margin-right: 10px;
            @extend .search-result-tag;
          }
          .recent-advice-tag {
            background-color: $search-recent-advice-tag;
            margin-right: 10px;
            @extend .search-result-tag;
          }
          .historical-tag {
            background-color: $search-historical-tag;
            margin-right: 10px;
            @extend .search-result-tag;
          }
        }
      }
      .action-buttons {
        .button-container {
          display: flex;
          height: 100%;
          gap: 16px;
          .MuiButtonBase-root {
            > span {
              font-size: 13px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0.46px;
              padding-left: 5px;
            }
          }

          .reports-dropdown {
            height: 25%;
            display: flex;
            flex-flow: column;
            .MuiButtonBase-root {
              > span {
                font-size: 13px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.46px;
                padding-left: 5px;
              }
            }
          }
          > a {
            height: 25%;
            cursor: pointer;
            @include center-align;
            &.disabled {
              & svg {
                & > path {
                  fill: $disabled-dark;
                }
              }
            }
          }
          .tooltip-text {
            max-width: 33.33%;
            text-align: center;
            .report-trigger {
              text-align: left;
            }
          }
          .reports-menu {
            &.disabled {
              @include center-align;
            }
            &.inactive {
              cursor: default;
            }
          }
          .dropdown-menu {
            .reports-dropdown {
              .dropdown-menu {
                display: block;
              }
            }
          }
          .reports-dropdown {
            .dropdown-menu {
              top: 40px;
              right: 0px;
              @extend .noselect;
            }
          }
          &.show-report {
            .reports-dropdown {
              .dropdown-menu {
                display: block;
              }
            }
          }
        }
        .__react_component_tooltip.type-dark.place-left:after {
          border-left-color: $heading-color;
        }
        .__react_component_tooltip.place-left{
          margin-left: -22px;
        }
      }

    }
    .saved-list-star-wrapper {
      position: absolute;
      top: 10px;
      left: 378px;
    }
  }
  h6.search-result-label {
    color: $black-87-opaque;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
    margin-top: 0;
  }
  .search-result-label {
    font-size: 14px;
    line-height: 20.02px;
    letter-spacing: 0.15px;
  }
  .property-update-cards {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 20px;
    &.empty {
      padding: 0;
      margin-bottom: 0;
    }
    &:before {
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background-color: rgba(51, 153, 255, 0.08);
      z-index: -1;
    }
  }
  .deleted-properties {
    width: 100%;
    position: absolute;
    top: -55px;
    left: 100vw;
    .go-back {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      span {
        display: block;
        margin-left: 8px;
        font-weight: 500;
      }
      svg {
        font-size: 20px;
      }
    }
    .card-view:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
}

html[data-useragent*='Mozilla/5.0'] {
  #card-view-container {
    .reports-dropdown {
      .__react_component_tooltip {
        &:after {
          right: -5px;
        }
      }
    }
  }
}
