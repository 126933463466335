@import '../../variable';

.historical-flag {
  width: 90px;
  padding: 6px 0;
  margin-right: 10px;
  .historical-inner-container {
    background-color: $historical-bg;
    text-align: center;
    span {
      display: block;
      line-height: 17px;
      font-family: $poppins;
      font-size: 10px;
      color: white;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
