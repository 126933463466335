@import 'variable';

// MIXINXS
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin bootstrapWidth($columnNo, $paddingLeft: 15px, $paddingRight: 15px) {
  width: ((100/12) * $columnNo) * 1%;
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
  vertical-align: top;
}


// Can be use in any header text that is #38424a in color;
@mixin header-text($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $heading-color;
}

@mixin fa-clickable($font-size, $radius) {
  border: none;
  padding: 0;
  font-size: $font-size;
  line-height: 0;
  background-color: white;
  @include border-radius($radius);
  &:focus {
    outline: none;
  }
}

@mixin blur-background($color, $blur) {
  backdrop-filter: blur($blur);
  background-blend-mode: multiply;
  background-color: $color;
}

@mixin ellipsis($max-width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $max-width;
  display: block;
}

@mixin center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin suggestion-heading {
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px 0 10px 0;
  font-size: 12px;
  color: $gray-text;
  border-bottom: solid 1px #d9d9d9;
}

@mixin generic-checkbox-styling {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 400;
  color: lighten($heading-color, 10%);
  margin: 0;
  padding: 8.5px 15px;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  & > input {
    display: none;
    &:checked {
      & + .check-icon {
        background-color: $heading-color;
        border-color: $heading-color;
        & > i {
          display: block;
        }
      }
    }
  }
  &:hover {
    & > .check-icon {
      border-color: $heading-color;
    }
  }
  & > .checkbox-label {
    font-size: 13px;
    margin-left: 10px;
    vertical-align: middle;
    text-transform: capitalize;
    color: $heading-color;
  }
  & > .check-icon {
    // flexbox does not respect fixed dimensions
    min-width: 19px;
    min-height: 19px;
    max-width: 19px;
    max-height: 19px;
    background-color: #fff;

    @include center-align;
    border-radius: 3px;
    border: 1px solid darken($gray-border-lighter, 10%);
    & > img {
      display: block;
      width: 12px;
      height: 12px;
    }
  }
}
