.marketing-contact-blocker {
  min-height: unset;
  min-width: 3em;
  .block-ui-container {
    min-width: 4em;
    .block-ui-overlay {
      background-color: transparent;
    }
    .block-ui-message-container {
      .loading-indicator {
        text-align: left;
        .loading-bullet {
          font-size: 1.5em;
        }
      }
    }
  }
}