@import '../../mixins';
@import '../../variable';

$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
.crux-home-SLAS-wrapper {
  #crux-search-bar {
    #crux-advance-search {
      width: 100%;
    }
  }
}
#crux-search-bar {
  z-index: 1;
  font-style: normal;
  #crux-advance-search {
    & {
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
    &.crux-search {
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: -1;
    }
    .input-group-lg > .form-control,
    .input-group-lg > .input-group-addon,
    .input-group-lg > .input-group-btn > .btn {
      & {
        height: 68px;
        font-size: 14px;
      }
    }
    &.nz {
      .address-name-search-panel {
        & > .address-name-form-fields,
        & .address-name-form-fields {
          @extend .input-group-lg;
          & > div {
            &:not(:last-child) {
              & > div {
                width: 170px;
                > button:first-child {
                  padding-left: 12px;
                  padding-right: 6px;
                }
              }
            }
          }
        }
      }
    }
    .address-name-search-panel {
      z-index: 2;
      & > .address-name-form-fields,
      & .address-name-form-fields {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        position: relative;
        @extend .input-group-lg;
        & > div {
          &:not(:last-child) {
            margin-right: 9px;
            height: 40px;
            & > div {
              width: 165px;
              height: 40px;
            }
          }
        }
        .auto-suggest-container {
          position: relative;
          .search-keyword {
            height: 100%;
            & > input {
              height: 100%;
              font-size: 13px;
              border-radius: 6px;
              &::placeholder {
                color: $gray-text;
              }
            }
          }
          .search-suggestions {
            width: 200%;
            top: 120%;
            left: -100%;
            border-top-left-radius: 4px;
            box-shadow: $box-shadow;
          }
          @extend .tooltip-style-search-bar;
        }
        .chev-icon {
          display: inline-block;
          margin-left: 20px;
          width: 13px;
          padding-bottom: 1px;
        }
        .chev-state-icon {
          margin-left: 33px;
          width: 13px;
          padding-bottom: 1px;
        }
        .search-icon {
          display: inline-block;
          margin: 0;
          width: 19px;
          padding-bottom: 2px;
        }
        > .search-inputs {
          position: relative;
          border-radius: 6px;
          height: inherit;
          .form-control {
            &.slas-keyword {
              .form-control {
                & {
                  margin-left: 9px;
                  height: inherit;
                }
              }
              &.slas-focused {
                & > input {
                  box-shadow: 0 0 0 2px $blue;
                }
              }
            }
          }
          #advanced-search-options {
            .custom-checkbox {
              label,
              label span.MuiFormControlLabel-label {
                color: white;
              }
            }
          }
        }
        .form-input-search-field {
          height: 100%;
          border-radius: 4px;
          &::placeholder {
            color: $gray-placeholder-text;
          }
          div:not(.tooltip-popper) {
            &:before,
            &:after {
              border: none;
            }
            &#dropdown-modal {
              height: auto;
            }
            &.tooltip-popper {
              height: auto;
            }
            &.field-error {
              position: absolute;
              left: 0;
              top: 94%;
              z-index: 1;
              background-color: $error;
              width: 100%;
              color: white;
              padding: 5px;
              line-height: 16px;
              font-size: 10px;
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
              display: flex;
              flex-wrap: wrap;
              &.relative {
                position: relative;
              }
            }
          }
          #lastName,
          #firstName,
          #suburb,
          #ownerFirstName,
          #ownerLastName,
          #companyName,
          #buildingName,
          #valuationReference,
          #volumeNumber,
          #folioNumber,
          #addressSuburb {
            &::placeholder {
              color: $gray-placeholder-text;
              opacity: 1;
            }
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            padding: 0 12px;
          }
        }
        .lastName-field,
        .firstName-field,
        .addressSuburb-field,
        .companyName-field,
        .buildingName-field,
        .ownerLastName-field,
        .ownerFirstName-field,
        .valuationReference-field,
        .volumeNumber-field,
        .folioNumber-field,
        .suburb-field {
          position: relative;
          background-color: transparent;
          width: 219px;
          input {
            height: 100%;
            position: relative;
            z-index: 2;
            width: 100%;
            &::-ms-clear {
              display: none;
            }
          }
          &.error {
            .form-control {
              .Mui-focused {
                border-color: transparent;
              }
            }
          }
          @extend .tooltip-style-search-bar;
        }
        .lastName-field.lastName-focused,
        .firstName-field.firstName-focused,
        .ownerLastName-field.ownerLastName-focused,
        .ownerFirstName-field.ownerFirstName-focused,
        .addressSuburb-field.addressSuburb-focused,
        .companyName-field.companyName-focused,
        .suburb-field.suburb-focused {
          input {
            transition: all 0.3s ease;
          }
        }
        .state-field {
          width: 100%;
          &:hover {
            cursor: pointer;
          }
          @include center-align;
          span {
            color: $gray-placeholder-text;
            cursor: pointer;
            font-size: 12px;
          }
          & > i {
            font-size: 15px;
            margin-left: 15px;
            color: lighten($heading-color, 20%);
          }
          .has-value {
            color: #555;
          }
        }
        .state-field-container {
          position: relative;
          width: 117.84px;
          background-color: transparent;
          .state-field {
            position: relative;
            z-index: 3;
            border: 2px solid #fff;
            border-radius: 4px;
            transition: all 0.3s ease;
            justify-content: space-between;
            height: 100%;
          }
          &.error {
            .state-field {
              border: 1px solid $error;
            }
          }
          & > span {
            position: absolute;
            top: 6px;
            right: 6px;
            line-height: 1px;
            z-index: 5;
          }
        }
        & > div {
          border-radius: 6px;
          cursor: pointer;
          #tenure.custom-tool-tip {
            margin-top: -5px;
          }
          &.crux-search-type-select {
            z-index: 3;
            position: relative;
            width: 120px;
            > span:first-child {
              background-color: $blue;
              height: 68px;
              position: relative;
              border-radius: 6px;
              transition: background-color 0.3s ease;
              @include center-align;
              &:hover, &.active {
                background-color: $blue-hover;
              }
            }
          }
          & > span {
            color: white;
            & > i {
              font-size: 25px;
              line-height: 1px;
              vertical-align: middle;
              margin-left: 10px;
            }
            & > span {
              vertical-align: middle;
            }
          }
          &.search-inputs {
            z-index: 1;
            min-width: 600px;
            .roll-valuation-search-primary-fields {
              .name-search-btn {
                height: 40px !important;
                margin-top: 0;
              }
            }
            &.more-actions {
              display: inline-flex;
              &.nz {
                & > div:first-child > input + .slas-suggestions {
                  width: calc(99.7% - 5px);
                  margin-left: -1px;
                }
              }
              .slas-keyword {
                margin-right: 9px;
                &:before {
                  padding: 8px 16px !important;
                  height: 100%;
                }
              }
              & > div:first-child > input + .slas-suggestions {
                width: calc(99.7% - 52px);
                right: auto;
              }
              .crux-search-more {
                #dropdown-modal {
                  width: 250px;
                  padding: 8px 16px;
                  .tooltipText {
                    font-size: 12px;
                    text-transform: none;
                    color: #999999;
                    padding-left: 24px;
                  }
                  .custom-checkbox {
                    label {
                      margin-bottom: 0;
                    }
                    .crux-checkbox {
                      color: $charcoal-gray;
                      &.Mui-checked {
                        color: $blue;
                      }
                    }
                    span:nth-child(2) {
                      min-width: 100%;
                      color: $charcoal-gray;
                    }
                  }
                }
                & > span.more-actions-btn {
                  position: relative;
                  width: 40px;
                  height: 40px;
                  &:hover {
                    color: $blue-hover;
                    border-color: $blue-hover;
                  }
                  &:active,
                  &.active {
                    &:before {
                      content: "";
                      position: absolute;
                      background-color: $blue;
                      opacity: 0.08;
                      top: 0;
                      left: 0;
                      height: 100%;
                      width: 100%;
                    }
                  }
                }
              }
              .name-search-primary-fields,
              .company-search-primary-fields,
              .building-search-primary-fields,
              .volume-folio-search-primary-fields {
                .name-search-btn {
                  height: 40px !important;
                  margin-top: 0;
                }
              }
              .company-search-primary-fields,
              .building-search-primary-fields,
              .volume-folio-search-primary-fields {
                width: 680px;
              }
              .name-search-primary-fields,
              .volume-folio-search-primary-fields {
                .form-input-search-field {
                  .form-control {
                    input {
                      width: 150px;
                    }
                  }
                }
              }
            }
            & > div {
              &:first-child {
                height: 40px;
                padding: 0;
                background-color: transparent;
                display: flex;
                & > input {
                  border-radius: 4px;
                  margin: 0 !important;
                  padding: 6px 16px 6px 45px;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.4px;
                  &::placeholder {
                    color: $gray-placeholder-text;
                  }
                  & + .slas-suggestions {
                    & {
                      width: 100%;
                      position: absolute;
                      top: 38px;
                      left: 0;
                      right: 0;
                      margin-top: 8px;
                      margin-left: auto;
                      margin-right: auto;
                      background-color: white;
                      border-radius: 4px;
                      box-shadow: $box-shadow;
                    }
                    & > ul {
                      padding: 10px 20px 10px 20px;
                      max-height: 44vh;
                      overflow-y: auto;
                      @media screen and (max-height: 830px) {
                        max-height: 35vh;
                      }
                      > li {
                        display: flex;
                        align-items: center;
                        .suggestion-type-icon {
                          width: 20px;
                          margin-right: 15px;
                          img {
                            height: 20px;
                            width: 20px;
                          }
                          .tooltip-suggestion-type {
                            width: auto;
                            font-size: 12px;
                            text-transform: capitalize;
                            & + div {
                              padding: 5px 15px;
                            }
                          }
                        }
                        a {
                          display: block;
                          flex-grow: 1;
                          padding: 6px 0;
                          color: $heading-color;;
                          font-size: 13px;
                          letter-spacing: .3px;
                          width: calc(100% - 50px);
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      }
                      .slas-suggestion-highlight {
                        a {
                          color: $blue;
                        }
                      }
                    }
                    .slas-divider {
                      @include suggestion-heading;
                      .historic-checkbox {
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-end;
                        margin-top: -8px;
                        & > label {
                          @include generic-checkbox-styling;
                          padding: 0;
                          cursor: pointer;
                          input:checked + .check-icon {
                            background-color: $blue;
                            border-color: $blue;
                          }
                          &:hover {
                            .check-icon {
                              border-color: $blue;
                            }
                          }
                          & > .checkbox-label {
                            font-size: 12px;
                            text-transform: none;
                            color: $charcoal-gray;
                          }
                          span:not(.Mui-checked) {
                            color: $charcoal-gray;
                          }
                        }
                      }
                    }
                  }
                }
                &.slas-keyword {
                  &:before {
                    content: "search";
                    display: block;
                    font-family: "Material Icons", sans-serif;
                    position: absolute;
                    padding: 4px 15px;
                    color: $black-54-opaque;
                    font-size: 21px;
                  }
                  & {
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    z-index: 3;
                  }
                  &::placeholder {
                    color: $gray-placeholder-text;
                    font-size: 12px;
                  }
                  .slas-divider {
                    display: none;
                  }
                  &.slas-keyword-open {
                    .slas-divider {
                      display: flex;
                    }
                  }
                }
                &.name-search-primary-fields,
                &.company-search-primary-fields,
                &.building-search-primary-fields,
                &.roll-valuation-search-primary-fields,
                &.volume-folio-search-primary-fields {
                  .grid-container,
                  .form-input-search-field,
                  .item {
                    height: 100%;
                    .item {
                      padding-right: 5px;
                    }
                  }
                }
              }
            }
            .crux-search-more {
              display: flex;
              position: relative;
            }
          }

        }
        .home-search-options, .home-state-options {
          .selected {
            color: $blue;
          }
          &:hover {
            & > .checkbox-label {
              color: $blue;
            }
          }
        }
        .home-search-options-modal {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: flex-end;
          z-index: -1;
          top: 85%;
          border-radius: 6px;
        }
        .home-state-options-modal {
          min-width: unset;
          width: 100%;
          height: 100%;
          display: table;
          z-index: 2;
          color: $charcoal-gray;
          top: 38px;
          left: 0;
          margin-top: 8px;
          border-radius: 6px;
          label {
            padding: 6px 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: $black-87-opaque;
            cursor: pointer;
            .checkbox-label {
              margin-left: 0;
              font-size: 12px;
            }
          }
          .tooltipText {
            padding: 8.5px 12px 0;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: $black-60-opaque;
            cursor: default;
            hr {
              margin-top: 0;
              margin-bottom: 10px;
            }
            &:before {
              content: "";
              border-top: 1px solid $black-23-opaque;
              width: 100%;
              height: 1px;
              display: block;
              position: absolute;
              left: 0;
              margin-top: -8.5px;
            }
          }
        }
        .state-on-focus {
          .state-field {
            border: solid 2px $blue;
            transition: all ease;
          }
        }
        div {
          &.input-group-addon {
            background-color: $blue;
            width: 157px;
            text-align: center;
            color: white;
            padding: 0;
          }
        }
        .crux-dropdown {
          .address-name-tab-title {
            opacity: 1;
            color: $black-60-opaque;
            &.Mui-selected {
              color: $black-87-opaque;
              background-color: transparent;
            }
          }
        }
      }
      span.MuiTabs-indicator {
        background-color: $blue;
      }
      .MuiTabs-root {
        .address-name-tab-title {
          color: white;
          &.disabled-tab {
            color: $disabled;
          }
        }
      }
      .address-name-tab-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.4px;
        opacity: 0.5;
        min-width: 100px;
        &.Mui-selected {
          opacity: 1;
        }
        &.disabled-dropdown {
          cursor: auto;
          color: $black-23-opaque !important;
          &:hover {
            background-color: #FFFFFF;
          }
        }
      }
    }
    .form-control,
    .input-group-addon {
      border: 0;
      box-shadow: none;
    }
    .btn {
      &.btn-info {
        background-color: #6DABF4;
        min-width: 109px;
        text-align: center;
      }
      span {
        vertical-align: middle;
        margin-left: 10px;
      }
    }
    .input-group-addon {
      & {
        background-color: #fff;
        border-right: none;
        color: #888888;
        padding-right: 0;
      }
      i {
        vertical-align: middle;
        font-size: 18px;
      }
    }
    span {
      &.input-group-addon {
        background-color: $blue;
        width: 157px;
        text-align: center;
        color: #fff;
        padding: 0;
      }
    }
    .crux-slas-advance {
      & {
        padding: 10px 0;
      }
      a {
        color: white;
      }
    }
    .search-result {
      position: initial;
    }
    #advanced-search-options {
      display: flex;
      align-items: center;
      height: 28px !important;
      margin-top: 10px;
      justify-content: flex-end;
      position: relative;
      z-index: -2;
      .custom-checkbox {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > label {
          @include generic-checkbox-styling;
          padding-left: 0;
          padding-right: 0;
        }
        .tool-tip-component {
          line-height: 1px;
          margin-left: 8px;
        }
      }
    }
    .chev-icon.rotate-icon,
    .chev-state-icon.rotate-state-icon,
    .chev-advanced-search-icon.rotate-icon {
      transform: rotateZ(180deg);
    }
  }
  .search-bar-container {
    position: relative;
    min-height: 110px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    z-index: 9;
  }
  .search-bar-nav {
    height: 100%;
    & > div {
      &#crux-advance-search {
        &.crux-search {
          max-width: 850px;
          z-index: initial;
          height: 100%;
          padding: 13px 0;
        }
        .address-name-search-panel {
          width: 100%;
          position: relative;
          z-index: 1;
          flex-wrap: wrap;
          @include center-align;
          & > .address-name-form-fields {
            max-width: 850px;
            flex-wrap: nowrap;
            position: relative;
            justify-content: center;
            .search-inputs {
              .name-search-primary-fields,
              .company-search-primary-fields,
              .building-search-primary-fields,
              .roll-valuation-search-primary-fields,
              .volume-folio-search-primary-fields {
                height: 40px;
                width: 850px;
              }
              .roll-valuation-search-primary-fields {
                width: 100%;
              }
            }
            .crux-search-type-select {
              & > span {
                height: 40px;
              }
              height: 40px;
            }
            .name-search-btn {
              width: 45px;
              min-width: 45px;
            }
            .state-field-container {
              .state-field {
                z-index: 4;
                height: 100%;
                & > img {
                  margin-left: 15px;
                }
              }
              & > .tool-tip {
                z-index: 4;
              }
              & > .home-state-options-modal {
                z-index: 3;
              }
            }
            .ownerLastName-field,
            .ownerFirstName-field,
            .state-field-container {
              & > span {
                @extend .tooltip-style-nav;
              }
              & > input {
                z-index: 3;
              }
            }
            .state-field-container {
              .state-field {
                z-index: 4;
                & > img {
                  margin-left: 15px;
                }
              }
              & > .tool-tip {
                z-index: 4;
              }
            }
            .lastName-field, .firstName-field, .state-field-container, .suburb-field {
              & > span {
                top: 3px;
                right: 4px;
                z-index: 5;
                & > span {
                  font-size: 6px !important;
                }
              }
              & > input {
                z-index: 3;
              }
            }
          }
          .field-error {
            z-index: 2;
            top: 90%;
          }
          .field-error {
            z-index: 2;
            top: 90%;
          }
          .auto-suggest-container {
            .tool-tip-component {
              @extend .tooltip-style-nav;
            }
          }
        }
        #advanced-search-options {
          height: 40px;
          position: relative;
          .suburb-field {
            width: 175px;
            & > span {
              top: 3px;
              right: 4px;
              & > span {
                font-size: 6px !important;
              }
            }
          }
        }
      }
    }
  }
  &.crux-search-v2 {
    #crux-advance-search {
      .address-name-search-panel {
        .lastName-field,
        .firstName-field,
        .addressSuburb-field,
        .ownerLastName-field,
        .ownerFirstName-field,
        .companyName-field,
        .buildingName-field,
        .valuationReference-field,
        .volumeNumber-field,
        .folioNumber-field,
        .suburb-field {
          width: 100%;
        }
        .state-field-container {
          width: 100%;
        }
      }
    }
  }
}

.tooltip-style-search-bar {
  & > span {
    position: absolute;
    top: 6px;
    right: 6px;
    line-height: 1px;
    z-index: 2;
  }
}
.tooltip-style-nav {
  top: 3px;
  right: 4px;
  z-index: 4;
  & > span {
    font-size: 6px !important;
  }
}
.company-search-primary-fields,
.building-search-primary-fields {
  #dropdown-modal {
    height: auto !important;
  }
}
.company-search-tooltip,
.building-search-tooltip,
.volume-folio-search-tooltip,
.roll-valuation-search-tooltip {
  width: 225px;
  &.shrink {
    width: inherit;
  }
}
.roll-valuation-search-tooltip {
  margin-left: -2px;
  &.valuationReference-tooltip {
    width: 225px!important;
  }
}
.search-results {
  &.company-search-tooltip {
    &.addressSuburb-tooltip {
      &.au-tooltip {
        width: 142px;
      }
    }
  }
}
.is-home-page {
  #crux-search-bar {
    .search-bar-container {
      height: 188px;
      &.extended {
        height: 228px;
      }
    }
    #crux-advance-search.crux-search {
      align-self: flex-start;
      .address-name-form-fields {
        .search-inputs {
          .roll-valuation-search-primary-fields {
            .name-search-btn {
              height: 39px!important;
              margin-top: -1px;
            }
          }
        }
      }
    }
  }
}
