.listing-modal {
  & > div {
    &:last-child {
      max-width: 900px;
      width: 100%;
      height: calc(100vh - 300px);
    }
  }
  &.development-application-modal {
    & > div {
      &:last-child {
        max-width: 991px;
      }
    }
  }
  .listing-dialog-close-button {
    a {
      &:hover {
        text-decoration: none;
        i {
          color: #005FBE;
        }
      }
      i {
        display: block;
        color: $heading-color;
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
  .listing-dialog-back-button {
    a {
      position: absolute;
      top: 20px;
      left: 40px;
      z-index: 3;
      border: 0;
      font-weight: 500;
      color: #3399ff;
      background-color: transparent;
      &:hover {
        color: #005FBE;
      }
    }
  }
  .listing-description-loading {
    &.block-ui {
      height: 100%;
      .block-ui-overlay {
        background-color: #fff;
      }
    }
  }
  .header {
    .heading-text {
      font-family: $poppins;
      font-size: 20px;
      font-weight: 500;
    }
  }
  #campaign-modal-content .campaign-modal,
  .listing-description,
  .listing-description--details,
  .rental-campaign--list-item,
  .rental-campaign .rental-campaign-details .panel-header,
  .rental-campaign-details .panel-header--title,
  .development-application--modal-content,
  .development-application .development-application-details {
    font-family: $poppins;
  }
  .building-consent__panel-header,
  .building-consent--item-header .title,
  #campaign-modal-content .campaign-modal__header--item,
  #campaign-modal-content .campaign-modal__list-item-header--item,
  .listing-description--summary-title,
  .listing-description--summary-row .listing-description--summary-label,
  .listing-description--summary-header .summary-back-btn,
  .rental-campaign-details .panel-header--title,
  .rental-campaign--list-item .rental-campaign--expanded-header,
  .rental-campaign .rental-campaign-details .panel-header,
  &.development-application .development-application-details .panel-title .panel-header .heading,
  &.development-application .development-application--da-list-item .panel-body .development-application--expanded-panel-content .column .title {
    font-weight: 500 !important;
  }
}
.listing-modal,
.listing-modal.building-consent {
  font-family: $poppins;
}
.list-description-content {
  overflow-y: hidden !important;
  padding: 0px 0px 0px 0px !important;
  margin-top: -10px;
  .list-description-body {
    padding: 0px 10px 10px 40px;
    .listing-dialog-title {
      padding: 0px 0px 20px 0px;
      border: 0;
      font-family: $poppins;
      & > span {
        font-size: 20px;
        font-weight: 500;
        color: $heading-color;
      }
    }
    .scroller {
      padding: 10px 0px 10px 0px;
      height: calc(100vh - 440px);
      min-height: 150px;
    }
  }
  .list-description-agents {
    &.col-xs-5 {
      padding-bottom: 48px !important;
    }
    padding: 7px 0px 0px 0px !important;
    background-color: $body-bg;
    height: calc(100vh - 353px);
  }
  #property-desc {
    color: $heading-color;
    padding-right: 20px;
    & > div {
      padding-right: 20px;
    }
  }
  .list-desc-line-break {
    padding-left: 15px;
    padding-right: 20px;
  }
  .list-desc-advert-date,
  .list-desc-advert-agency,
  .list-desc-advert-agent,
  .list-desc-advert-agent-phone{
    padding: 0px 15px 10px 20px;
    overflow:auto;
    p {
      padding-left: 0px !important;
      &:first-child {
        padding-right: 0px !important;
        color: $heading-color;
        font-weight: 500;
      }
      &:last-child {
        padding-left: 10px !important;
      }
    }
  }
}

// RP-REUSE OVERRIDE STYLES
.listing-modal-dialog {
  & > div {
    & > div:first-child {
      max-width: 900px;
      width: 100%;
      height: calc(100vh - 300px);
    }
  }
  .listing-description--close-icon i {
    color: $charcoal-gray;
    font-weight: 400;
  }
  .listing-description--summary-header {
    .summary-back-btn {
      display: none;
    }
  }
}
