@import '../../variable';
@import '../../mixins';

.crux-classic-modal {
  & > div {
    &:last-child {
      overflow-y: visible;
      min-width: 753px;
      border-radius: 6px;
    }
  }
  .crux-classic-modal-title {
    line-height: 24.5px;
    display: flex;
    justify-content: space-between;
    @include header-text(20px, 500);
    border-radius: 6px 6px 0 0;
    padding: 16px 30px;
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      background: transparent;
      &:hover {
        color: $blue;
      }
    }
    &.tall-title {
      padding: 25px;
      .close-button {
        margin: 24px 30px;
      }
    }
    span.sub-title {
      display: block;
      font-size: 14px;
      color: $charcoal-gray;
    }
  }
  .dialog-footer {
    display: flex;
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    &.left {
      justify-content: flex-start;
    }
  }
}
