@import '../../variable';

/**
 * Footer Component
 */
#footer{
  position: relative;
  padding-top: 20px;
  .navbar-header {
    .navbar-brand {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        max-width: 128px;
        margin-right: 6px;
      }
      .cl-logo-txt {
        border-left: 1px solid $gray-border-light;
        color: $gray-text;
        font-weight: 500;
        font-size: 14px;
        padding-left: 6px;
        line-height: 25px;
        height: 25px;
      }
    }
  }
  .navbar {
    margin-bottom: 30px;
    .container {
      .footer-heading {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 10px;
        overflow: hidden;
      }
      .navbar-nav {
        margin: 0;
        padding-top: 7px;
        & > li {
          a {
            font-size: 12px;
            font-weight: normal;
            color: $gray-text;
            border: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .footer-content {
    padding-bottom: 50px;
    .container {
      .disclaimers {
        p {
          word-wrap: break-word;
          font-size: 10px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.6;
          letter-spacing: normal;
          color: $gray-text;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
      .flex-row {
        display: flex;
        align-items: stretch;
        margin: 0 -15px;
        .flex-grow {
          flex-grow: 1;
          padding: 0 15px;
        }
      }
    }
  }
  #mobile-app {
    flex-basis: 33.34%;
    padding: 0 15px;
    border-left: 1px solid $gray-border-light;
    section {
      margin-left: 15px;
      margin-right: 15px;
      width: 85%;
      max-width: 280px;
      .mobile-badge-container {
        display: flex;
        justify-content: space-between;
        margin-left: -5px;
        margin-right: -5px;
        .mobile-badge {
          width: 135px;
          margin: 0 5px;
          img {
            max-height: 40px;
            width: 100%;
          }
        }
      }
      h5 {
        margin-top: 0;
        line-height: 1.3em;
        font-weight: 500;
        font-size: 14px;
        color: $header-dark;
      }
    }
  }
}



@media screen and (max-width: 767px) {
  #footer {
    .footer-content {
      .container {
        .flex-row {
          margin: 0;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    #mobile-app {
      border-left: none;
      section {
        margin: 0;
        width: auto;
        max-width: unset;
      }
    }
  }
}
