.title-details-modal {
  &-paper {
    max-width: 960px !important;
  }
  &-pdf {
    height: 100%;
  }
  &-content > div {
    height: 100%;
  }
  .rcs-custom-scrollbar {
    height: calc(100vh - 261px);
  }
  .rcs-inner-container {
    > div {
      > div {
        min-height: 1263px;
      }
    }
  }
  .block-ui {
    min-height: 780px;
  }
  .block-ui-overlay {
    background-color: unset;
  }
  .error-message {
    display: flex;
    align-items: center;
    place-content: center;
  }
  .pdf-button-icon {
    margin-right: 5px;
  }
}
