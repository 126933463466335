.withheld-tooltip-info {
  line-height: 1;
  display: flex;
  align-items: center;
}
[id*=deed7-address-search-result] {
  line-height: 1.3em;
}
#legal-withheld-info {
  z-index: 99999;
}