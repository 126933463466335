@import 'source/css/crux/mixins';

#market-insights {
  min-height: calc(100vh - 159px);
  & > h3 {
    font-size: 20px;
    font-weight: 500;
    color: $heading-color;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .flex-container {
    display: flex;
    .flex-item {
      flex-grow: 1;
    }
  }
  .search-bar-container {
    max-width: 50%;
    position: relative;
  }

  .time-frame-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .time-frame-select {
      width: 173px;
      background-color: $body-bg;
      .dropdown-heading {
        background-color: white;
        box-shadow: $crux-shadow;
        padding: 9px 30px 9px 12px;
        border-color: transparent;
        text-transform: initial;
        &:hover {
          border-color: $blue;
        }
      }
      &.open {
        .dropdown-heading {
          border-color: $blue;
        }
      }
      .dropdown-modal {
        width: 100%;
        top: 100%;
        background-color: $body-bg;
        ul {
          margin: 0;
          li {
            padding: 10px;
            color: $charcoal-gray;
            font-size: 12px;
            cursor: pointer;
            cursor: hand;
            position: relative;
            &:after {
              content: '';
              width: 89%;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              border-bottom: 1px solid $line-break-gray;
            }
            &:hover {
              background: $blue;
              color: white;
            }
          }
        }
      }
    }

  }

  .market-insights-search-results {
    margin-top: 30px;
    .begin-market-insights {
      font-size: 20px;
      font-weight: 500;
      color: $heading-color;
      flex-direction: column;
      & > span {
        text-align: center;
        margin-bottom: 20px;
        &:first-child {
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .error-fetching {
      font-size: 20px;
    }
    .begin-market-insights, .error-container, .no-results-found {
      height: calc(100vh - 360px);
      @include center-align;
    }
    .no-results-found {
      font-size: 18px;
      color: $gray-text;
    }
    .error-container {
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      h4 {
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: 30px;
        font-weight: 500;
        color: $heading-color;
      }
      span {
        max-width: 70%;
        color: $heading-color;
        text-align: center;
      }
    }
  }

  .market-insights-chart-cont {
    margin-top: 55px;
  }
  .line-chart-wrapper {
    position: relative;
    padding-top: 15px;
    padding-bottom: 55px;
    .no-data-message {
      width: 265px;
      font-size: 12px;
      color: $gray-text;
      text-align: center;
      position: absolute;
      top: 40%;
      left: 40%;
    }
    .button-primary{
      color: #FFFFFF;
      padding: 5px 12px;
      float: right;
      font-size: 12px;
    }
    .zoom {
      span {
        font-size: 12px;
      }
      height: 33px;
      font-weight: normal;
      display: flex;
      justify-content: space-between;
      .zoomed-false {
        background-color: $gray-border-light !important;
      }
    }
    .highcharts-reset-zoom {
      display: none;
    }
  }
}

.init-loader {
  height: calc(100vh - 108px);
}

.market-insight-blocker {
  height: 100%;
  min-height: 300px;
  &.header-floating {
    padding-top: 68px;
  }
  & > .block-ui-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
  .loading-bullet {
    color: #797979;
  }
}
