@import 'source/css/crux/variable';

.property-info-window {
  display: flex;
  align-items: center;
  margin: 0;
  height: 101px;
  overflow: hidden;
  max-width: 500px;
  font-family: $poppins;
  cursor: auto;
  &+.property-info-window {
    border-top: 1px solid $gray-border-light;
  }
  &-error {
    height: 50px;
    .property-marker-content {
      .property-marker-address {
        width: 270px;
        padding: 0px 10px 0px 0px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.45em;
        font-weight: normal;
        color: $heading-color;
      }
    }
  }
  .property-marker-image {
    flex: 0 0 150px;
    width: 150px;
    height: auto;
    min-height: initial;
    img {
      width: 100%;
      height: auto;
      border-right: $gray-border-light solid 1px;
    }
    .loader-container {
      min-height: 150px;
    }
  }
  .property-marker-content {
    h4 {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 1.45em;
      font-weight: 500;
      color: $heading-color;
    }
    .property-marker-tag {
      h4 {
        &:hover {
          color: $link !important;
          text-decoration: underline;
        }
      }
    }
    ul {
      line-height: 1.4em;
      & > li {
        div[type="land-area"] {
          color: red;
          .property-attribute-unit {
            .land-area-tool-tip-div {
              position: relative;
              .__react_component_tooltip {
                position: absolute !important;
                top: -9px !important;
                left: -158px !important;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    .transaction-summary {
      > p {
        font-family: $poppins;
        font-size: 12px;
        line-height: 1.4em;
      }
    }
    .property-attribute-val {
      font-family: $poppins;
      font-size: 12px;
    }
  }
  .list-inline li .property-attribute .property-attribute-val img {
    width: 20px;
  }
}

.group-property-marker-container, .single-property-marker-container {
  border-radius: 6px;
  overflow: visible !important;
  transform: translate(13px, 0) !important;
  // The similar property info window generates a width
  // which causing the property address to go next line.
  width: auto !important;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFFFFF;
  }
}

.group-property-marker-container {
  > div {
    border-radius: 6px;
    overflow: hidden;
  }
}

.group-marker-bubble {
  width: 100%;
  background-color: #FFFFFF;
  overflow-y: visible;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 10px;
  .property-info-window {
    .property-marker-content {
      padding: 10px;
    }
  }
}
.group-marker-bubble-properties {
  max-height: 303px;
  overflow-y: auto;
  box-sizing: content-box;
  &+.group-marker-bubble-summary, .group-marker-bubble-summary {
    border-top: 1px solid $gray-border-light;
  }
  .group-marker-bubble-summary {
    padding: 10px 15px 0px 40px;
  }
}

.group-marker-bubble-properties-mini {
  @extend .group-marker-bubble-properties;
  border-top: 1px solid $gray-border-light;
  max-height: 210px;
  .property-info-window {
    @extend .property-info-window;
    height: 80px;
    .property-marker-image {
      flex: 0 0 100px;
      width: 100px;
      height: auto;
      min-height: initial;
      img {
        width: 100%;
        height: auto;
        border-right: $gray-border-light solid 1px;
      }
      .loader-container {
        min-height: 100px;
      }
    }
  }
  &+.group-marker-bubble-summary, .group-marker-bubble-summary {
    border-top: 1px solid $gray-border-light;
  }
  .group-marker-bubble-summary {
    padding: 10px 15px 0px 40px;
  }
}

.group-marker-bubble-summary {
  font-family: $poppins;
  font-size: 12px;
  padding: 10px 15px 10px 15px;
  &+.group-marker-bubble-properties {
    border-top: 1px solid $gray-border-light;
  }
  #identify-property-show-more {
    color: $blue;
  }
}

.single-property-marker-container {
  border-radius: 6px;
  .property-info-window {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 6px;
    .property-marker-content {
      padding: 10px;
    }
    .property-marker-image {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      overflow: hidden;
    }
  }
}

.identify-popup {
  overflow: visible !important;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #FFFFFF;
  }
  .block-ui-overlay {
    background-color: #FFFFFF;
  }
  .property-info-loading {
    width: max-content;
  }
  .identify-popup-content {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    min-height: 60px;
    .property-info-window {
      .property-marker-alert {
        padding: 5px;
        font-family: $poppins;
        font-size: 14px;
        line-height: 1.45em;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
      }

      .property-marker-content {
        padding: 10px 0 10px 10px;
      }
      .property-marker-address {
        width: 270px;
        padding: 0px 10px 0px 0px;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 13px;
        line-height: 1.45em;
        font-weight: 500;
        color: $heading-color;
      }
      .property-attribute-val {
        font-size: 12px;
      }
      .last-sale {
        position: relative;
        .last-sale-text {
          font-family: $poppins;
          font-size: 12px;
          line-height: 1.4em;
          .undisclosed-price-info {
            position: initial;
          }
        }
      }
    }
  }
}
