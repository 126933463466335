@import 'source/css/crux/variable';
@import 'source/css/crux/mixins';

/*
------------------------------------------------
================================================
  NOTE: ALL COMMON STYLING GOES HERE.
================================================
------------------------------------------------
 */

#report-options-modal {
  @include center-align;
  .report-options-container {
    outline: none;
    width: 320px;
    border-radius: 5px;
    overflow: visible;
    .header {
      background-color: $heading-color;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      & > span {
        display: flex;
        align-items: center;
        .img-icon {
          margin-right: 8px;
        }
      }
    }
    .content {
      background-color: #fff;
      padding: 10px 20px 20px;
      .flex-row {
        display: flex;
        align-items: center;
        .flex-label {
          flex-grow: 1;
        }
        .flex-input {
          &.switch-container {
            @include center-align;
            height: 48px;
          }
        }
      }
      .form-group {
        label {
          color: $heading-color;
          font-size: 12px;
          text-transform: capitalize;
          font-weight: 500;
        }
        p {
          max-width: 218px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .container-divider {
        margin-bottom: 0;
        p {
          font-weight: 500;
          font-size: 13px;
        }
      }
      hr {
        margin-top: 7px;
        margin-bottom: 10px;
      }
      .report-modal-calendar {
        width: 100%;
        height: 100%;
        padding-left: 7px;
        .placeholder{
          color: $black-54-opaque;
        }
        span.custom-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $black-54-opaque;
        }
        .dropdown-modal {
          width: 100%;
          .rc-calendar,
          .rc-calendar .rc-calendar-panel {
            width: 100%;
          }
        }
      }
    }
    #property-report-form {
      .content {
        .flex-row {
          margin-bottom: 2px;
        }
        > div:last-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.modal-bg-opacity {
  & > div {
    &:first-child {
      background-color: rgba(0, 0, 0, 0.65) !important;
    }
  }
}
.modal-clear-bg {
  & > div {
    &:first-child {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }
}

.white-blocker {
  .block-ui-overlay {
    background-color: white;
    opacity: 1;
  }
  .loading-indicator {
    .loading-bullet {
      font-size: 2.5em;
    }
  }
}

.button-with-tooltip {
  &.bwt-disabled{
    cursor: default;
    background-color: $disabled !important;
    opacity: 0.6;
    border: none;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  & + .custom-tool-tip {
    width: unset !important;
  }
}

// Generic style for head container
.header-container{
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  button {
    margin-left: auto;
  }
}

.suppress-padding {
  padding: 0;
}

.three-dots {
  color: $charcoal-gray;
}

// Tooltip Popper
.tooltip-popper {
  background-color: $off-white;
  border-radius: 4px;
  margin-top: 10px;
  padding: 8px 12px;
  z-index: 1200;
  cursor: default;
  &.col-3 {
    margin-left: 1px;
  }
  &.col-4 {
    &.search-results {
      margin-left: 2px;
    }
  }
  &:before {
    content: "";
    border-bottom: 7px solid $off-white;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    display: block;
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    top: -7px;
    width: 7px;
  }
  & > div {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $black-60-opaque;
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    .close-popper {
      color: $blue;
      display: block;
      font-size: 10px;
      font-weight: 500;
      float: right;
      line-height: 14px;
      margin-top: 5px;
    }
  }
}

.name-button {
  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  .owner-name {
    color: $blue;
    &:hover {
      color: $blue-hover;
    }
  }
}
