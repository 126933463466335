.crux-ui {
  &__header {
    &--h2 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      font-family: $poppins;
    }
  }
}

button {
  &.map-unavailable {
    &.disabled {
      background-color: $disabled !important;
      border: none;

      &:hover {
        background-color: $disabled !important;
      }
    }
  }
}
