@import '../../variable';

.market-trends {
  .loader {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .header-container{
    //override generic header-container style
    margin-bottom: 10px;
  }
  .content {
    overflow: hidden;

    .trend-row {
      min-height: 100px;
      display: flex;
      align-items: flex-start;
      padding: 10px 0 0 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      }

      .trend {
        cursor: pointer;
        &:nth-child(odd) {
          width: 30%;
        }
        &:nth-child(even) {
          width: 40%;
        }
        text-align: center;
        color: $heading-color;
        .value {
          font-size: 32px;
          margin-bottom: 5px;
          .sign {
            font-size: 24px;
          }
        }
        .caption-container {
          font-size: 12px;
          white-space: pre-line;
          display: flex;
          justify-content: center;
          .caption{
            padding: 0 10px;
          }
        }
        .icon {
          margin: 0 8px;
          font-size: 25px;

          &.up { color: #48b295; }
          &.equal {
            font-size: 18px;
            color: $heading-color;
            position: relative;
            bottom: 4px;
          }
          &.down {
            color: #b11e2b;
            position: relative;
            bottom: 5px;
          }
        }
      }

    }
  }
}
