@import "../mixins";

.wrapper {
	.home {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		background-size: cover;
		height: calc(100vh - 68px);
		min-height: 768px;
		background-position-y: center;
		.home-container {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			.container {
				height: inherit;
			}
		}
		.quote-container {
			margin-bottom: 20px;
			max-width: 85%;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			font-style: normal;
			p {
				color: #fff;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.15px;
				&:last-child {
					font-size: 14px;
					font-weight: 500;
					text-transform: capitalize;
					line-height: 24px;
					letter-spacing: 0.4px;
				}
			}
		}
		&:before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3)
		}
		.crux-home-component {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			&.rss-no-article {
				margin-top: 87px;
			}
			#crux-home-greeting {
				color: white;
				font-size: 40px;
				font-style: normal;
				font-weight: normal;
				line-height: 24px;
				text-align: center;
			}
			#crux-home-beta-msg {
				color: white;
				font-size: 19px;
			}
			.crux-home-SLAS-wrapper {
				position: relative;
			}
			.crux-home-SLAS-blur-background {
				position: absolute;
				background-color: rgba(0,0,0,0.6);
				backdrop-filter: blur(4px);
				border-radius: 8px;
				width: 100%;
				height: 100%;
			}
			#crux-search-bar {
				margin-top: 42px;
				#crux-advance-search {
					.input-group-lg {
						&.address-name-search-panel {
							&.slas-focused {
								div {
									&.input-group-addon {
										border-top: solid 2px;
										border-bottom: solid 2px;
									}
								}
								input {
									&.form-control {
										border: solid 2px;
										transition: all ease;
									}
								}
							}
						}
					}
					.address-name-search-panel {
						.search-inputs {
							min-width: 100%;
						}
					}
				}
				&.crux-search-bar {
					margin-top: 28px;
					.search-bar-container {
						padding: 20px;
					}
				}
			}
		}
		#crux-ui__rss-feed {
			height: 380px;
			border-radius: 7px;
			margin: 10px auto;
			@include blur-background(rgba(0, 0, 0, .60), 5px);
			.crux-ui__header--h2 {
				color: white;
			}
			.crux-ui__rss-feed {
				&--container {
					height: 100%;
					display: flex;
					align-items: center;
				}
				&--body {
					width: 100%;
					margin-top: 0;
					display: flex;
					flex: 1;
					overflow-y: hidden;
					> div {
						width: 100%;
					}
					& .error-fetching span {
						font-size: 14px;
						width: 350px;
					}
				}
			}
		}
		.home-inner-container {
			display: flex;
			flex-direction: column;
			height: inherit;
			align-items: center;
		}
		.home-inner-container-header {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-width: 1046px;
		}
	}
}

@media (min-width: 1199px) {
	.home {
		.crux-home-component {
			width: 900px;
		}
	}
}
