@import 'source/css/crux/mixins';

.wrapper {
  .error-handler {
  padding: 0;
    margin: 0 auto;
    @include center-align;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 300px);
    min-height: 500px;
    background-position-y: center;

    &:before{
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    & > .row {
      width: 100%;
      h4 {
        font-size: 30px;
        color: $heading-color;
        font-weight: 500;
      }
      p {
        color: $heading-color;
        max-width: 75%;
        margin: 0 auto;
      }
      .homepage-button, .reload-page-button {
        margin-top: 30px;
        width: 158px;
      }
      .homepage-button {
        margin-left: 20px;
      }
      .crux-error-icon {
        margin-bottom: 20px;
      }
    }
  }
}
