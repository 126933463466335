// VARIABLES
$blue: #3399ff;
$blue-hover: #005FBE;
$green: #29de74;
$mint-green: #60bca3;
$red: #db0f00;
$red-lighter: #DF443C;
$red-darker: #bb3b46;
$yellow: #ffc000;
$link: #3399ff;
$gray-text: #949494;
$gray-border-light: #d9d9d9;
$gray-border-lighter: #e4e4e4;
$gray-darker-text: #797979;
$mirage: #383e42;
$charcoal-gray: #38424a;
$heading-color: $charcoal-gray;
$azure: #00adf0;
$open-sans: "Open Sans", sans-serif;
$poppins: "Poppins", sans-serif;
$off-white: #f5f5f5;
$dirty-white: #fafafa;
$white: #ffffff;
$body-bg: $dirty-white;
$property-grid-tile-height: 400px;
$light-gray-bg: #f7f5f3;
$lighter-gray-bg: #f3f2f1;
$search-for-sale-tag: #71A9DA;
$search-recent-sale-tag: #CD2D2E;
$search-for-rent-tag: #6BA753;
$search-recent-advice-tag: #8E7DC1;
$search-historical-tag: #FB7B36;
$soft-green: #5dc26b;
$gray-darker-background: #d8d8d8;
$errorFieldBg: #fef5f4;
$errorFieldBorder: #F44336;
$error-field-border-hover: #BA1011;
$header-dark: #4c4c4c;
$error: #E31B0C;
$disabled: #d9d9d9;
$disabled-dark: #b9b9b9;
$orange: #f58d4f;
$tooltip-bg: #222;
$dropzone-bg: #f3f3f3;
$map-tools-white: #fbfafa;
$panel-header-white: #fbfbfb;
$coming-soon-red: #ff0000;
$table-header-light-gray: #f8f8f8;
$line-break-gray: rgba(219, 219, 219, 0.32);
$crux-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);
$date-circle-blue: #71a9da;
$historical-bg: #fb7b36;
$badge-red: #e75555;
$filter-input-bg: rgba(51, 153, 255, 0.05);
$black-8-opaque: rgba(0, 0, 0, 0.08);
$black-12-opaque: rgba(0, 0, 0, 0.12);
$black-23-opaque: rgba(0, 0, 0, 0.23);
$black-25-opaque: rgba(0, 0, 0, 0.25);
$black-30-opaque: rgba(0, 0, 0, 0.30);
$black-38-opaque: rgba(0, 0, 0, 0.38);
$black-50-opaque: rgba(0, 0, 0, 0.5);
$black-54-opaque:  rgba(0, 0, 0, 0.54);
$black-60-opaque:  rgba(0, 0, 0, 0.60);
$black-87-opaque:  rgba(0, 0, 0, 0.87);
$panel-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);
$gray-placeholder-text: #9E9E9E;


// from rp-reuse variables
$blue: #39f;
$dark: #38424a;
$gray: #949494;
$light-gray: #f3f2f1;
$gray-darker-background: #d8d8d8;
$very-light-gray: #fafafa;
$gray-border-light: #d9d9d9;
$gray-border-lighter: #e4e4e4;
$charcoal-gray: #38424a;
$heading-color: $charcoal-gray;
$blue-hover: #005fbe;
$dirty-white: #f7f5f3;
$history-da-yellow: #ffc000;
$history-bc-yellow: #FEBF29;
$history-rental-green: #6BA753;
$history-listing-blue: #71a9da;
$history-sale-red: #cd2d2e;
$open-sans: "Open Sans", sans-serif;

// titles NZ
$titles-nz-dark: #38424a;
$titles-nz-light-dark: #515b63;
$titles-nz-gray: #e1e1e1;
$titles-nz-blue: #3399ff;
$titles-nz-light-gray: #fafafa;
$titles-nz-dark-gray: #949494;
$titles-nz-red: #b11e2b;
$titles-nz-red-hover: #770a13;
$titles-nz-blue-hover: #005fbe;
$titles-nz-input-border: #d9d9d9;
$titles-nz-disabled: #d9d9d9;