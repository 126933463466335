@import 'source/css/crux/mixins';

/**
 * Breadcrumbs Component
 */

#breadcrumbs {
  .breadcrumb {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 0;
    align-items: center;
    & > li {
      @include center-align;
    }
    & >li, >li>a {
      text-transform: uppercase;
      cursor: pointer;
      font-size: 13px;
      color: $heading-color;
      font-weight: 500;
    }
    & >li>a {
      padding: 0 10px 0 10px;
    }
  }
  .breadcrumb>li+li:before {
    font-family: sans-serif;
    color: #b2b2b2;
    font-size: 15px;
    line-height: 18.57px;
    padding: 0;
    font-weight: 400;
    content: none;
  }
  .breadcrumb>li {
    span {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .breadcrumb>li:last-child {
    color: $red-lighter !important;
    cursor: default;
  }
  .breadcrumb>li:first-child {
    cursor: pointer;
    span {
      padding-left: 0px;
    }
  }
}
