@import "source/css/crux/variable";

.terms-and-conditions {
  margin: 60px auto 0;
  padding: 24px;
  color: $charcoal-gray;
  min-height: calc(100vh - 432px);
  border: 1px solid var(--elevation-outlined, rgba(224, 224, 224, 1));

  &__content {
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  &__checkbox {
    margin: 30px 0 8px;
    .custom-checkbox {
      margin: 0 30px;
      & > label {
        @include generic-checkbox-styling ;
        padding: 0;
        justify-content: flex-start;
        .checkbox-label {
          font-size: 12px;
          font-weight: normal;
          margin-left: 8px;
          span {
            text-transform: none;
          }
        }

        .check-icon {
          width: 14px;
          height: 14px;
          border-radius: 2px;
          > img {
            width: 10px;
            height: 10px;
          }
        }

        input:checked + .check-icon {
          background-color: $charcoal-gray;
          border-color: $charcoal-gray;
        }

        &:hover {
          .check-icon {
            border-color: $charcoal-gray;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    @media screen and (max-width: 430px) {
      justify-content: space-between;
    }
  }

  .terms-and-conditions__content {
    .pageHeading {
      font-size: 20px;
      letter-spacing: normal;
      line-height: 2;
      font-weight: bold;
      margin-bottom: 28px;
      display: block;
    }

    p > p {
      margin-block: inherit !important;
    }

    .marginBottom {
      margin-block: inherit;
    }
  }

}
