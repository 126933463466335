@import "../../variable";

.box {
  position: relative;
  margin-bottom: 25px;
  > *:not(.tools-container) {
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    background: white;
    padding: 30px;
    box-shadow: $panel-shadow;
  }
  &-header,
  &-content {
    font-family: $poppins;
  }
  &-header {
    display: flex;
    padding: 24px 27px;
    background-color: $panel-header-white;
    color: $heading-color;
    font-weight: 500;
    font-size: 20px;
    border-bottom: 1px solid $light-gray-bg;
    justify-content: space-between;
    .coming-soon {
      font-size: 10px;
      color: $coming-soon-red;
      margin-left: 20px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  &-content {
    padding: 0 27px;
    font-size: 12px;
    color: $gray-text;
    &-msg {
      width: 86%;
      padding: 27px 0 0;
      margin: 0;
    }
    &-body {
      padding: 24px 0;
    }
  }
}
.mini-box {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  .box{
    display: flex;
  }
}
