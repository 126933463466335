.backdrop-message {
    position: absolute;
    top: 52%;
    width: auto;
    display: flex;
    flex-direction: column;
}

#exports-modal {
    .backdrop-message {
        top: 45%;
    }
}

.crux-loader-svg {
    fill: #505050;
}
.home {
    .loader-container {
        display: none;
    }
}
.block-ui-no-scroll {
    overflow: hidden !important;
}