#legal-description {
  .flex-container {
    & {
      display: flex;
    }
    > div {
      display: block;
      padding-left: 15px;
      padding-right: 15px;
      @media screen and (max-width: 991px) {
        flex: none;
        width: 100%;
      }
    }
    > .flex-label {
      & {
        flex-basis: 33%;
        width: 33.33%;
      }
      p {
        color: $heading-color;
        font-weight: 500;
      }
    }
    > .flex-content {
      width: 66.66%;
      flex-grow: 1;
      p {
        word-break: break-all;
      }
    }
    .legal-description-show-more-less {
      color: $blue;
    }
  }
  .flex-container {
    &.legal-desc-row {
      &:not(:first-child) {
        padding-top: 10px;
      }
      .hyper-link {
        cursor: pointer;
        color: $blue !important;
      }
    }
  }
  .no-info {
    padding-top: 20%;
    text-align: center;
  }
}
