@import 'source/css/crux/mixins';

.campaign-collapse-container {
  .toggle-collapse-trigger {
    width: 30px;
    height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    float: left;
    button {
      border: 0;
      color: $blue;
      background-color: transparent;
      font-weight: 500;
    }
    .fa {
      font-size: 18px;
    }
  }
  hr {
    background-color: $gray-border-light;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &.panel-group {
    margin-bottom: 0;
  }
  .listing-desc {
    white-space: nowrap;
    margin-left: 28px;
  }
}

.advertisement-list-loader {
  .row {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .advertisement-list-header {
    font-weight: 500;
  }
}

.campaign-modal-body {
  padding: 10px 10px 24px 10px !important;
  display: flex;
  overflow-y: hidden !important;

  .campaign-loader,
  .development-application-details-loader{
    width: 100%;
  }
  .campaign-loader {
    & > .block-ui-container {
      .block-ui-overlay {
        background-color: #fff;
        opacity: 1;
      }
    }
  }
}

.campaign-modal-header {
  background-color: $body-bg;
  padding: 15px;
}

.light-gray {
  background-color: $body-bg;
}

.header-content {
  display: flex;
  justify-content: space-between;
  position: relative;
  & > .main {
    min-height: 62px;
    height: 62px;
    width: 100%;
    > div {
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      height: 100%;
      &:first-child {
        padding-left: 40px;
      }
      .h4 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: $heading-color;
        font-family: $open-sans;
        font-weight: 500;
        line-height: 1.75em;
        font-size: 18px;
      }
      &.col-xs-5 {
        justify-content: flex-end;
      }
      &.col-xs-7 {
        justify-content: flex-start;
      }
      #DA-tooltip {
        @include center-align;
        margin-left: 10px;
        color: $heading-color;
      }
    }
  }
  .agency-property-campaign-toggle[hidden] {
    display: none !important;
  }
  .sale-campaign-action-btns {
    padding-right: 67px;
  }
  .close-button {
    @include center-align;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 45px;
    margin-right: 12px;
    &:hover {
      text-decoration: none;
      i {
        color: #005FBE;
      }
    }
    i {
      color: $heading-color;
      font-weight: 700;
    }
  }
}

.alert-error {
  flex: 1;
  width: 100%;
  align-self: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
