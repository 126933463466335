@import '../../variable';
@import '../../common';

.auto-suggest-container {
  z-index: 1;
  position: relative;
  .search-keyword {
    position: relative;
    input[type="suburb-postcode-search"] {
      height: 55px;
      border-color: white;
      border-radius: 6px;
      box-shadow: $crux-shadow;
      padding-right: 95px;
      &:focus {
        border-color: $blue;
      }
    }
    .search-suggestions {
      width: 100%;
      background: white;
      position: absolute;
      top: 55px;
      left: 0;
      z-index: 1;
      border-radius: 4px;
      .search-divider {
        @include suggestion-heading;
      }
      & > ul {
        padding: 20px;
        li {
          color: $heading-color;
          font-size: 13px;
          cursor: hand;

          &:not(:last-child) {
            padding-bottom: 12px;
          }

          &.search-suggestion-highlight {
            color: $blue;
          }
          & > .search-suggestion {
            .matched-suggestion {
              font-size: inherit;
              font-family: inherit;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  & > .white-blocker {
    position: absolute;
    height: 50px;
    width: 65px;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    .loading-indicator {
      .loading-bullet {
        font-size: 2em;
      }
    }
  }
}
