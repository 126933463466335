@import '../../variable';
@import '../../mixins';
@import '../../variable';

.activity-bar-actions, .reports-dropdown {
  font-family: $poppins;
  .custom-tooltip-disabled.tooltip-text {
    width: unset;
    text-align: center;
  }
  .reports-dropdown-trigger {
    &:hover{
      .ti-angle-down::before {
        content: "\E648";
      }
    }
    & > span {
      & > span {
        line-height: 1;
      }
    }
    &.disabled {
      cursor: default;
      background-color: $disabled !important;
      box-shadow: none;
      & > span:first-child span:last-child {
        margin-left: 5px;
        font-size: 21px;
      }
      & > span:last-child{
        display: none;
      }
    }
  }
  .__react_component_tooltip.type-dark {
    &.place-left:after {
      border-left-color: $heading-color;
    }
    &.place-bottom:after {
      border-bottom-color: $heading-color;
    }
  }
  .dropdown-menu {
    right: -10px;
    left: auto;
    padding: 0;
    margin: 0;
    min-width: 530px;
    max-width: 530px;
    display: block;
    @include border-radius(5px);

    .report-collection-loader {
      font-size: 8px;
      &.loading {
        height: 140.25px;
      }
      .block-ui-overlay {
        background-color: white;
      }
    }

    .menu-container {
      height: auto;
      padding: 15px;
      overflow: hidden;
      @include border-radius(5px);
      background-color: white;
      box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      h5 {
        @include header-text(14px, 500);
      }
      .menus {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        margin-right: -10px;
        margin-left: -10px;
        > * {
          flex-basis: 33.33%;
        }
        & .report-trigger {
          width: 33.33%;
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          white-space: nowrap;
          position: relative;
          @include border-radius(5px);
          &.is-loading-report {
            background-color: rgba(100, 100, 100, 0.1);
          }
          &:hover {
            transition: all ease 0.3s;
            background-color: rgba(100, 100, 100, 0.1);
          }
          z-index: 9;
          img {
            max-width: 33px;
            width: 33px;
          }
          button {
            font-style: italic;
          }
          span {
            font-size: 12px;
            color: $heading-color;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
              transition: none;
              background-color: inherit;
            }
          }
          .report-loader {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .custom-tool-tip.__react_component_tooltip {
          width: auto;
        }
        .tooltip-text {
          .report-trigger {
            width: 100%;
          }
        }
      }
    }
    .container {
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;
      padding-bottom: 15px;
      padding-top: 15px;
      width: auto;

      .disabled {
        color: #A9A9A9;
      }

      // override switch height of material ui switch button
      .switch-container {
        & > span {
          margin-left: -10px;
          margin-right: -10px;

          & > span:first-child {
            height: auto;
          }
        }
      }
      .container-divider {
        border-bottom: 1px solid $gray-border-light;
        color: $gray-text;
        font-size: 13px;
        font-weight: 500;
        font-family: inherit;

        p {
          color: inherit;
        }
      }

      .flex-row {
        display: flex;
        align-items: center;
        .flex-label {
          width: 83.325%;
          flex-basis: 83.325%;
          flex-grow: 1;
          color: $heading-color;
          font-size: 12px;
        }
        .flex-input {
          width: 16.665%;
        }
      }

      .form-group {
        label {
          color: $heading-color;
          font-size: 12px;
          font-weight: 500;
        }
        input[type="text"] {
          border-radius: 2px;
          height: 40px;
          font-size: 12px;
          font-family: inherit;
          border: 1px solid $gray-border-light;

          &::placeholder {
            color: $gray-text;
            font-size: inherit;
            font-family: inherit;
            opacity: 0.8;
          }
        }
      }
    }

    .header {
      background-color: $heading-color;
      padding: 10px 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      span {
        color: #FFFFFF;
      }
    }
    .tooltip-text {
      max-width: 33.33%;
    }
  }
}
