@import "source/css/crux/variable";
@import "source/css/crux/common";

// Styling for dropdown
#checkbox-dropdown, .dropdown-select {
  display: block;
  position: relative;
  &.open {
    & > {
      .dropdown-heading {
        border-color: #39f;
        background-color: $dirty-white;
      }
    }
  }
  & > .dropdown-heading {
    border: 1px solid $gray-border-lighter;
    padding: 12px 30px 12px 7px;
    font-size: 13px;
    font-weight: 400;
    color: $heading-color;
    overflow: hidden;
    border-radius: 3px;
    min-height: 38px;
    max-height: 38px;
    cursor: pointer;
    min-width: 78px;
    width: 100%;
    position: relative;
    text-transform: capitalize;
    &:hover {
      border-color: #39f;
    }
    & > .dropdown-heading-value {
      max-width: 190px;
      min-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }
    & > svg {
      position: absolute;
      top: 0;
      right: 8px;
      bottom: 0;
      margin: auto;
    }
  }
  & > .dropdown-options {
    & .option {
      @include generic-checkbox-styling;
    }
  }
}

.dropdown-select {
  & > .dropdown-options {
    & > .land-size-min-max-container, & > .range-select-filter-container,
    & > #year-range-select {
      padding: 10px 20px;
      & > p {
        color: #38424a;
        font-weight: 500;
        font-size: 13px;
      }
      & .menu-select {
        white-space: nowrap;
        & > div {
          & > div {
            display: inline-block;
            width: 130px;
            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }
        & > p {
          span {
            font-weight: 700;
          }
        }
      }
    }
    & > .range-select-filter-container {
      & .menu-select {
        white-space: nowrap;
        & > div {
          & > div {
            width: 103px;
            &:not(:last-child) {
              margin-right: 12px;
            }
          }
        }
      }
      > p {
        height: 13px;
        > span {
          > img {
            width: 20px;
          }
        }
      }
    }
  }
}

#checkbox-dropdown {
  &.search-filter-dropdown {
    width: 180px;
    .dropdown-options {
      width: 1000px;
      display: flex;
      flex-wrap: wrap;
      & .option-container {
        width: 25%;
        label.option {
          white-space: normal;
          line-height: 1.6;
          align-items: flex-start;
          .checkbox-label {
            word-break: break-word;
          }
          .check-icon {
            margin-top: 1px;
          }
        }
      }
    }
    .options-container {
      flex-direction: row;
      height: 250px;
      overflow-y: auto;
    }
    .error-fetching {
      display: flex;
      justify-content: center;
      min-height: initial;
      padding: initial;
      width: 100%;
    }
  }
  .options-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
  }
  .dropdown-search-filter {
    width: 100%;
    padding: 10px 15px;
    & > input {
      width: 250px;
      display: inline-block;
      font-size: 13px;
      height: 30px;
    }
    & > button {
      color: $blue;
      font-size: 13px;
      font-weight: 400;
      text-transform: none;
      min-height: 30px;
      margin-left: 5px;
      vertical-align: unset;
      padding: 6px 15px;
      &:disabled {
        color: $disabled;
      }
    }
  }
}

