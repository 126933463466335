@import '../../mixins';
@import '../../variable';

#search-result-table {
  .custom-checkbox.search-select {
    &.is-card-view {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 10px;
    }
    & > label {
      @include generic-checkbox-styling;
      padding: 0;
    }
  }
}
.dropdown-modal {
  &.search-result-dropdown-panel {
    .option.search-options:hover, .label-selected {
      background: $blue;
      .checkbox-label {
        color: #fff;
      }
    }
    .option.search-options.disabled, .option.search-options.disabled:hover {
      background: #FFF;
      .checkbox-label {
        color: $disabled-dark;
      }
    }
    .label-selected[for=clear-option] {
      background: #ffffff;
      .checkbox-label {
        color: #4e5c67;
      }
    }
    .custom-tool-tip[id^="disabled-option-"] {
      white-space: normal;
      text-align: justify;
    }
  }
}
.multi-property-activity-menu {
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  left: 20px;
  &.map {
    position: absolute;
    align-items: center;
    left: 385px;
    bottom: 10px;
  }
  .activity-menu-wrapper {
    display: inline-flex;
    white-space: nowrap;
    .main-button-container {
      z-index: 2;
      button.activity-btn {
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
        border-radius: 100px;
        min-width: 50px !important;
        min-height: 50px !important;
        padding: 0 !important;
        background-color: $heading-color;
        cursor: pointer;
        border: 2px solid #FFF;

        span.fa {
          font-size: 19.5px;
          color: #FFF;
        }
      }
    }
    .child-button-container {
      max-width: 600px;
      overflow: hidden;
      margin-left: -22px;
      padding-left: 37px;
      height: 46px;
      margin-top: 2px;
      border-radius: 0 100px 100px 0;
      background-color: $heading-color;
      transition: all 0.3s ease-in-out;
      padding-right: 20px;
      @include center-align;
      button.activity-btn {
        text-transform: initial;
        color: #FFF;
        padding: 10px 0;
        font-size: 14px;
        font-weight: 400;
        &:not(:first-child) {
          padding-left: 20px;
        }
        span.fa {
          margin-right: 5px;
        }
        &:hover {
          background-color: transparent;
        }
        &:disabled {
          color: $gray-text !important;
          pointer-events: none !important;
        }
        &.disabled {
          color: $gray-text !important;
          cursor: default;
          & > span:last-child{
            display: none;
          }
        }
        .title {
          & > span {
            & > span {
              // Export CSV Badge
              background-color: $badge-red;
              font-size: 9px;
              width: 18px;
              height: 18px;
              align-content: initial;
            }
          }
          &.no-badge {
            & > span {
              & > span {
                display: none;
              }
            }
          }
        }
        .button-export-to-csv {
          .fa {
            font-size: 16px;
          }
        }
      }
      .custom-tool-tip.__react_component_tooltip {
        width: auto !important;
        &.type-info.place-top:after{
          color: $heading-color;
          border-top-color: $heading-color !important;
        }
      }
      &.no-width {
        max-width: 0;
        padding: 0;
      }
    }
    .no-property-selected {
      color: #FFF;
      font-size: 14px;
    }
  }
}
.crux-classic-modal.export-classic-modal {
  > div {
    > div {
      &:last-child {
        padding: 0 0 24px 0;
        overflow: hidden;
        color: $charcoal-gray;
        .simplebar-wrap__custom-scroll {
          max-height: 680px;
          padding: 0 24px;
        }
      }
    }
  }
  .simplebar-content {
    height: 100%;
    .white-blocker.loading {
      height: calc(100% - 32px);
    }
  }
}
#exports-modal {
  margin-right: -15px;
  padding-left: 3px;
  padding-right: 3px;
  .no-data {
    line-height: 20px;
  }
  .exports-activity-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .marketing-export-statement {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .export-disclaimer {
    margin-bottom: 20px;
  }
  .export-limit {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 14px;
    color: $charcoal-gray;
  }
  .exports-activity {
    background-color: darken($dirty-white, 1%);
    padding: 8px 16px;
    hr {
      margin-top: 0;
      margin-bottom: 10px;
      border-top: 1px solid $black-12-opaque;
    }
    & > .flex-row {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:last-child {
        flex-grow: 1;
      }
      & span {
        font-size: 14px;
        font-weight: 400;
        color: $charcoal-gray;
      }
    }
    &.error{
      background-color: fade($error, 20%);
      & > .flex-row.error > .flex-col {
        &:first-child {
          width: 7%;
          & > span {
            font-size: 24px;
            font-weight: 100;
            line-height: 1.3;
          }
        }
        & > span {
          color: $error;
        }
      }
    }
  }
  .export-disclaimer {
    .custom-checkbox {
      & > label {
        @include generic-checkbox-styling;
        white-space: normal;
        align-items: flex-start;
        .checkbox-label > span {
          text-transform: none;
          font-size: 12px;
        }
      }
    }
  }
  .display-options {
    margin-bottom: 10px;
  }
  .error-exports {
    max-height: 634px;
    height: calc(100vh - 170px);
    @include center-align;
    & > p {
      text-align: center;
      max-width: 45%;
      color: $gray-text;
    }
  }
}
