#market-insights-card {
  width: 289px;
  height: 257px;
  display: inline-block;
  background-color: white;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);

  .market-insights-details {
    height: 100%;
    color: $charcoal-gray;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    padding: 28px 0;
    .header {
      .metric {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        .market-insights-tooltip {
          margin-left: 4px;
          .fa-stack {
            margin-top: -3px;
          }
        }
        .tooltip-text {
          width: auto;
          max-width: 237px;
          text-align: left;
        }
      }
      & > :last-child {
        font-size: 12px;
      }
    }

    .current-value {
      font-size: 60px;
      font-weight: 500;
    }

    .footer {
      font-size: 12px;
      &.green {
        color: $mint-green;
      }
      &.red {
        color: $red-darker;
      }
      .arrow-up {
        display: inline-block;
        margin: 0 6px 2px 0;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $mint-green;
      }
      .arrow-down {
        display: inline-block;
        margin: 0 6px 2px 0;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $red-darker;
      }
      & > :first-child {
        font-size: 16px;
        font-weight: 500;
      }
      & > :last-child {
        font-size: 12px;
      }
    }

    .no-value-message {
      margin: auto;
      font-size: 12px;
      padding: 0 10px;
    }

  }
}
