.titles-container {
  margin-top: 115px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 47px;
  min-height: calc(100vh - 484px);
  .block-ui {
    > :not(.block-ui-container) {
      visibility: hidden;
    }
    .block-ui-overlay {
      background-color: transparent;
    }
  }
}

// need to overwrite rp-reuse
.titles-container *,
.MuiPaper-root ul li {
  font-family: $poppins !important;
}

.titles-container > .titles-nz__search-page .titles-nz__search-page--title,
.titles-nz__order--address-title,
.titles-nz__order--option-item-details-container .titles-nz__order--option-item-details-title,
.titles-nz__document-summary--table-title-container,
.titles-nz__payment-confirmation-page--title,
.titles-nz__payment-confirmation-page--button-container .titles-nz--link {
  font-weight: 500 !important;
}
