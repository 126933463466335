@import 'source/css/crux/mixins';

.search-filter-main-container {
    & {
       .tab-container{
          box-shadow: none;
          background-color: $body-bg;
          .panel-modal-tabs{
            color: $blue;
            .tab-menu{
              min-width: 73px;
              text-transform: none;
              color: $gray-text;
              border-bottom: 1px solid #d7d7d7;
              &[aria-selected="true"]{
                span {
                  color: $blue;
                }
              }
              & > span {
                & > span {
                  padding: 0;
                  & span {
                    font-size: 13px;
                    font-weight: 500;
                  }
                }
              }
            }
            & > div {
              & > div {
                & > div {
                  & + span {
                    &:last-child {
                      background-color: $blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
      & {
        .filter-property-type{
          padding-top: 3%;
          & {
            .ptype-clear-label{
              margin-bottom: 20px;
              display: flex;
              & > p:last-child {
                cursor: pointer;
                margin-left: auto;
                text-align: right;
              }
            }
          }
          & {
            .property-type-row{
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-flow: wrap;
              max-width: 330px;
              margin: 0 auto;
              &.ptype-label {
                padding-left: 10px;
                justify-content: right;
              }
              & > .col-3 {
                margin-bottom: 15px;
                width: 33.33%;
                display: flex;
                justify-content: center;
                padding-right: 5px;
                padding-left: 5px;
                &:nth-child(3n+4), &:first-child {
                  justify-content: flex-start;
                  padding-left: 0;
                }
                &:nth-child(3n+3) {
                  justify-content: flex-end;
                  padding-right: 0;
                }
                div {
                  cursor: pointer;
                  width: 90.9px;
                  height: 28px;
                  border-radius: 14px;
                  border: solid 1px #d9d9d9;
                  background-color: #ffffff;
                  @include center-align;
                  &.property-type-btn-selected{
                    background-color: $heading-color;
                    & {
                      .property-type-label{
                        color: white;
                      }
                    }
                  }
                  & > .property-type-label {
                    color: $heading-color;
                    margin-bottom: 0;
                    text-align: center;
                    line-height: 1;
                  }
                }
              }

            }
          }
          & {
            .filter-element-label {
              width: 100px;
              height: 18px;
              font-size: 13px;
              font-weight: 500;
              color: $heading-color;
              margin-bottom: 0;
            }
          }
          & {
            .clear-element-label {
              width: 100px;
              height: 18px;
              font-size: 13px;
              font-weight: 500;
              color: $blue;
              margin-bottom: 0;
            }
          }
        }
      }
    label {
      height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: #38424a;
      margin-bottom: 15px;
    }
  }
  .apply-filter-container {
    padding-top: 20px;
    cursor: pointer;
    .apply-filter {
      border: 0;
      background-color: $blue;
      width: 100%;
      color: white;
      text-transform: initial;
      &:hover {
        background-color: #005fbe;
        transition: all ease 0.5s;
      }
    }
  }
