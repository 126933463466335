@import "../../variable";

#account-container {
  width: 1140px;
  margin: 30px auto;
  min-height: calc(100vh - 484px);
  .coming-soon-tool-tip.__react_component_tooltip {
    width: auto !important;
  }
  .box > div {
    padding: 0;
  }

  .tab-menu.disabled {
    cursor: default;
    color: $disabled-dark;
  }

  .mui-account-panel-with-header {
    .error-fetching {
      color: $gray-text;
    }

    .pencil-icon {
      fill: #000000;
      fill-opacity: 0.2;
      &:hover {
        fill: #3399FF;
        fill-opacity: 1;
        cursor: pointer;
      }
    }
  }

  .organisation-phone-container {
    margin-top: 7px;
    margin-left: 0;
    margin-right: 0;
    .organisation-phone-label, .organisationPhone-tooltip {
      font-size: 11px;
      color: $black-54-opaque;
    }
    .organisationPhone-tooltip {
      left: 3px;
      top: 1px;
    }
    .organisation-phone-label {
      line-height: 12px;
      letter-spacing: 0.15px;
      font-weight: 400;
    }
  }

  .organisation-phone {
    display: flex;
    height: 38px;
    align-items: center;
    margin-top: 5px;
    & > span {
      margin-top: 1px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.15px;
      color: $black-60-opaque;
    }
  }
  #integrations {
    .box-content-msg {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
#crux-nav.search-type-address {
  &+.wrapper {
    #account-container {
      &.sticky-bar {
        margin-top: 115px !important;
      }
    }
  }
}
#crux-nav.search-type-address {
  &+.wrapper {
    #account-container {
      &.sticky-bar {
        margin-top: 115px !important;
      }
    }
  }
}
.enter-manual-address {
  .Mui-error {
    margin-left: 0;
    margin-right: 0;
  }
}
