@import 'source/css/crux/mixins';

#location-search-suggestion,
#radius-search,
#name-search-results,
#company-search-results,
#roll-valuation-search-results,
#building-name-search-results,
#volume-folio-search-results,
#parcel-and-legal-description-search-results,
#saved-search-results,
#saved-territory-search-results {
  position: relative;
  min-height: 500px;
  .results-pane {
    padding-left: 15px;
    padding-right: 15px;
    .filters {
      min-width: 314px;
      padding-right: 0;
      background-color: #f4f4f4;
      .search-filter-main-container {
        padding: 0 15px 0 25px;
        height: 100%;
        header {
          background-color: #f4f4f4;
        }
        border-right: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
      }
    }
    .results {
      position: relative;
      padding: 0;
      min-height: 255.44px;
      .result-page-header-container {
        height: 100%;
        padding: 10px 0;
        label {
          font-size: 14px;
        }
        &.switching-view {
          display: flex;
          align-items: center;
          position: relative;
          & > div {
            &:last-of-type {
              margin-left: auto;
            }
          }
        }
        .result-page-sort-container {
          font-size: 14px;
          display: flex;
          align-items: center;
          padding-left: 0;
          padding-right: 0;
          & > span.sort-label {
            margin-right: 20px;
          }
          & > div {
            display: inline-block;
            top: 100%;
          }
          input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .MuiAutocomplete-noOptions {
          font-size: 14px;
        }
        .switch-views-container {
          width: 165px;
          margin: 0 0 0 auto;
          z-index: 1;
          background-color: $lighter-gray-bg;
          .tab-menu {
            & > span {
              display: inline-block;
              & > span {
                &:not(:last-child) {
                  padding-right: 8px;
                  &.active {
                    color: $blue;
                  }
                }
              }
            }
          }
          .nav-pills {
            padding: 5px;
            & > {
              li {
                cursor: hand;
                > button{
                  width: 100%;
                  font-size: 12px;
                  font-weight: 500;
                  color: $charcoal-gray;
                  padding:5px;
                  &.text-capitalize {
                    text-transform: capitalize;
                  }
                  .v-align-middle {
                    padding-right: 7px !important;
                  }
                }
                &.active {
                  & > button {
                    background-color: white;
                    color: $blue;
                  }
                }
              }
            }
          }
        }
        .custom-checkbox.search-select {
          @include center-align;
          & > label {
            @include generic-checkbox-styling;
            padding: 0;
            & > .check-icon {
              height: 12px;
              width: 12px;
              & > img {
                height: 9px;
                width: 9px;
              }
              &:after{
                font-weight: 300;
                font-size:17px;
                content: '-';
                color: #fff;
                margin-left: 0;
                margin-bottom: 2px;
              }
            }
          }
          &.search-select-all {
            display: inline-block;
            margin-left: 12px;
          }
          &.search-select-all-card-view {
            display: inline-block;
          }
          & ~ .dropdown-modal {
            width: 200px;
            height: 123px;
            z-index: 2;
            color: #38424a;
            border-radius: 0 0 6px 6px;
            .search-options {
              .selected {
                color: #39f;
              }
              &:hover {
                & > .checkbox-label {
                  color: #39f;
                }
              }
            }
            &.search-options-modal {
              top: 65%;
              left: 12px;
            }
            &.search-options-modal-card-view {
              top: 100%;
              left: 24%;
            }
          }
        }
      }
      // padding-right: 7%;
      .result-count {
        white-space: nowrap;
        margin: 15px 0;
        font-size: 14px;
        display: flex;
        &.saved-list {
          &.text-center {
            justify-content: flex-start;
            padding-left: 0;
          }
        }
        .total-elements {
          margin: 0 5px;
        }
        .current-element-count {
          margin-right: 5px;
        }
        &.result-count-bottom {
          position: absolute;
          bottom: 0;
          height: 36px;
          margin: 0;
        }
        &.text-center {
          width: 33.33333333%;
          text-align: center;
          justify-content: center;
        }
        .selected-properties-count {
          color: $heading-color;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.list-view {
          display: flex;
          align-items: center;
        }
      }
      .limit-and-view {
        display: flex;
        flex-direction: row;
        .crux-mui-select {
          margin-right: 5px;
        }
      }
    }
    .display-message {
      @include center-align;
      min-height: 330px;
    }
    .centered-result {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
    }
  }
  &.floating-search-filter {
    padding-top: 137px;
  }
  .__react_component_tooltip {
    font-weight: 400;
  }
}
#radius-search {
  .target-property-details {
    .__react_component_tooltip {
      margin-left: 10px;
      background-color: $charcoal-gray;
    }
  }
  .results-pane {
    .display-message {
      @include center-align;
      min-height: 235px;
    }
  }
}
.multi-select-action-wrapper {
  position: relative;
  display: flex;
  margin: 0 10px 0 0;
  .check-icon {
    width: 19px;
    height: 19px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    &:hover {
      border-color: #38424a;
    }
    &.select-checked {
      background-color: #38424a;
      border-color: #38424a;
    }
    &.select-dashed {
      background-color: #38424a;
      border-color: #38424a;
      img {
        display: none;
      }
      &:after {
        content: '';
        height: 2px;
        width: 9.5px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
}
