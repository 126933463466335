@import '../../../../css/crux/variable';

.titles-nz {
  &__search-page {
    background-color: $titles-nz-light-gray;
    text-align: left;
    width: 100%;

    &--title {
      color: $titles-nz-dark;
      font-size: 20px;
      font-weight: 600;
      margin: 5px 0;
      font-family: $open-sans;
    }

    .titles-nz--button {
      width: 160px;
      float: right;
    }

    &--orders-container {
      min-height: 240px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__payment-confirmation-page {

    &--title {
      color: $titles-nz-dark;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0;
      font-family: $open-sans;
    }

    &--cost-breakdown {
      padding: 5px;
      color: $titles-nz-dark;
      font-size: 14px;
      font-weight: 600;
      margin: 20px 5px;
      font-family: $open-sans;

      .text-left {
        text-align: left;
        color: $titles-nz-light-dark;
      }

      .text-right {
        text-align: right;
      }

      .text-light {
        color: $titles-nz-dark-gray;
        font-weight: 500;
      }

      .text-dark {
        color: $titles-nz-dark;
      }
    }

    &--content-container {
      border: 2px solid $titles-nz-gray;
      margin: 10px 20px;
      border-radius: 7px;
      padding: 10px 10px;
      text-align: left;
    }

    &--button-container {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      display: flex;
      align-items: center;

      .titles-nz--link {
        margin: 0 40px;
        font-weight: 600;
      }
      .titles-nz--button {
        width: 150px;
      }
    }
  }

  &__payment-status-page {
    display: flex;
    align-items: center;
    flex-direction: column;

    &--title {
      color: $titles-nz-dark;
      font-size: 20px;
      font-weight: 600;
      margin: 5px 0;
      font-family: $open-sans;
    }

    &--content-container {
      border: 2px solid $titles-nz-gray;
      padding: 16px;
      border-radius: 7px;
      margin: 10px 20px;
      text-align: center;

      .titles-nz__payment-status-page--title {
        font-size: 16px;
      }
    }

    &--button-container {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .titles-nz--button {
        margin-bottom: 10px;
        width: 150px;
      }

      .titles-nz--link {
        margin: 0 40px;
        font-weight: 600;
      }
    }
  }

  &--button {
    border: 0;
    color: #fff;
    font-family: $open-sans;
    font-size: 14px;
    background-color: $titles-nz-blue;
    border-radius: 5px;
    padding: 0 12px;
    height: 40px;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline:0;
    }

    &:hover {
      background-color: $titles-nz-blue-hover;
    }

    &[disabled], &[disabled]:hover {
      background-color: $titles-nz-disabled;
      cursor: default;
    }

    .MuiCircularProgress-svg {
      margin: 8px;
      color: #fff;
    }
  }

  &--input-error {
    border-color: $titles-nz-red;

    &::placeholder {
      color: $titles-nz-red;
    }
  }

  &--link {
    text-decoration: none;
    color: $titles-nz-blue;
    font-size: 14px;
    cursor: pointer;
  }

  &--helper-text {
    font-size: 12px;
    color: $titles-nz-dark;
  }
}
