@import 'source/css/crux/mixins';

#property-features {
  .flex-container {
    & {
      display: flex;
    }
    > div {
      display: block;
      padding-left: 15px;
      padding-right: 15px;
      @media screen and (max-width: 991px) {
        flex: none;
        width: 100%;
      }
    }
    > .flex-label {
      & {
        width: 35%;
      }
      p {
        color: $charcoal-gray;
        font-weight: 500;
      }
    }
    > .flex-content {
      width: 65%;
    }
    > .toggle-container {
      color: $blue;
    }
  }
  .flex-container {
    &.property-feature-row {
      &:not(:first-child) {
        padding-top: 10px;
      }
    }
  }
  .no-info{
    height: calc(100% - 125px);
    @include center-align;
    padding-top: 20%;
  }
}
