#year-picker-input__container {
  border: 1px solid $gray-border-lighter;
  padding: 9px 30px 12px 5px;
  font-size: 13px;
  font-weight: 400;
  color: $charcoal-gray;
  border-radius: 3px;
  min-height: 38px;
  max-height: 38px;
  cursor: pointer;
  min-width: 127px;
  width: 144px;
  position: relative;
  text-transform: capitalize;

  &:hover {
    border-color: $blue;
  }

  & > input {
    cursor: pointer;
    border: none;
    background: transparent;
    width: 120px;
    &:focus{
      outline: none;
    }
  }

  & > i {
    font-size: 15px;
  }

  & > div > div {
    top: 81px !important;
  }

  .rc-calendar-year-panel{
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  }

  .default--text {
    color: hsl(0,0%,50%);
  }

  .rc-calendar-year-panel-last-decade-cell, .rc-calendar-year-panel-next-decade-cell {
    pointer-events: none;
    cursor: default !important;
  }
}

.year-picker-input__container--down-error,
.year-picker-input__container--open-error {
  background-color: $errorFieldBg !important;
  border-color: $errorFieldBorder !important;
}

.year-built-filter-error {
  .dropdown-heading {
    background-color: $errorFieldBg !important;
    border-color: $errorFieldBorder !important;
  }
}

.year-picker-input__container--open-ok{
  border-color: $blue !important;
  background-color: $body-bg !important;
}

#year-picker-input__container {
  .rc-calendar-picker {
    top: -40.8281px !important;
  }
}

#year-range-select {
  & > div > span {
    display: inline-flex;
  }
  .year-build-picker__min {
    margin-right: 12px;
  }
  .year-built-header__container {
    display: flex;
    & > div:first-child {
      width: 265px;
      color: $charcoal-gray;
      font-weight: 500;
      font-size: 13px;
    }
    & > div:last-child {
      width: 35px;
      cursor: pointer;
      text-align: end;
      color: $blue;
      &:hover {
        text-decoration: underline;
      }

    }
    margin: 0 0 10px;
  }
}

#year-built-filter {
  & > p {
    color: $charcoal-gray;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .dropdown-heading {
    min-width: 127px;
    width: 127px;
  }
}
