.live-avm-modal-container {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $heading-color !important;

  & .scroll-body {
    overflow-y: visible;
    min-width: 753px;
    border-radius: 6px;
  }
  & * :not(.fa) {
    font-family: $poppins;
  }

  .live-avm-dialog-title {
    background-color: $body-bg;
    border-radius: 6px 6px 0 0;
    padding: 16px 30px;
    font-size: 20px;
    .live-avm-title {
      font-weight: 500;
      color: $heading-color;
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      background: transparent;
      &:hover {
        color: $blue;
      }
    }
  }

  .live-avm-modal-body {
    padding: 0;
    overflow-y: visible;
    .live-avm-modal-body-container {
      height: 100%;
      padding: 20px 30px;

      .error-message {
        padding-top: 10px;
        min-height: 40px;
        text-align: center;
      }

      .additional-fields-container {
        margin-top: 2px;
        display: flex;
        justify-content: space-between;
        .sale-fields-container {
          &:nth-child(1) {
            max-width: 69px;
          }
          &:nth-child(2) {
            max-width: 134px;
          }
          &:nth-child(3) {
            max-width: 117px;
          }
        }
        .sale-fields-label {
          font-size: 14px;
          font-weight: 500;
        }
        .year-built-field {
          width: 69px;
          margin-top: 9px;
          height: 37px;
          border-radius: 2px;
          border-color: $gray-border-light;
        }
        .sale-date-field {
          .dropdown-heading-value {
            line-height: 1;
          }
        }
        .dropdown-select {
          width: 134px;
          margin-top: 9px;
          height: 37px;
          border-radius: 2px;
          border-color: $gray-border-light;
        }
        .sale-price-field {
          width: 117px;
          margin-top: 9px;
          height: 37px;
          border-radius: 2px;
          border-color: $gray-border-light;
        }
        .construction-field {
          width: 181px;
        }
        .has-error {
          border-color: $errorFieldBorder;
          background-color: $errorFieldBg;
        }
      }

      #avm-range {
        width: calc(100% - 140px);
        margin: 35px auto 0;
        min-height: 87.641px;
        .text-left, .text-right {
          color: $gray-text;
        }
        .valuation-range-header {
          font-size: 14px;
          font-weight: 500;
        }
        .valuation-range-footer {
          div {
            &.text-left, &.text-right {
              font-size: 20px;
            }
            &.text-center {
              font-size: 26px;
              font-weight: 500;
            }
          }
        }
        img {
          width: 100%;
        }
      }
      .property-panel-body {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0;
      }
      .avm-unavailable {
        text-align: center;
        padding: 10px;
        font-family: $poppins;
        color: $gray-text;
        min-height: 122.641px;
        position: relative;
        > .avm-unavailable-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }

    .live-avm-footer {
      margin-top: 50px;
      display: flex;

      .confirmation-container {
        flex-grow: 1;
        .confirmation-label {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .valuation-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        position: relative;

        .update-valuation-button, .download-button {
          margin-left: 20px;
          bottom: 0;
          border-radius: 2px;
          &.disabled {
            background-color: $gray-border-light !important;
            cursor: default;
          }
          & > span {
            font-weight: 500;
          }
        }
        .download-button {
          width: 100px;
        }

        .license-agreement-text {
          font-size: 10px;
          margin-top: 5px;
        }
        .error-container {
          width: 347px;
          font-size: 12px;
          position: absolute;
          bottom: 100%;
          text-align: right;
        }
      }
      .button-container {
        display: flex;
        align-items: center;
        .block-ui {
          height: 32px;
          min-height: 32px;
          width: 100px;
          margin-left: 20px;
          .block-ui-container {
            .block-ui-overlay {
              background-color: white;
              opacity: 1;
            }
            .loading-indicator {
              .loading-bullet {
                font-size: 2em;
              }
            }
          }
        }
      }
    }
    .block-ui {
      height: 122.641px;
      .block-ui-overlay {
        background-color: transparent;
      }
      .loading-bullet {
        color: $heading-color,
      }
    }
    .error-fetching {
      padding-top: 30px;
      color: $gray-text;
    }
  }
}
