@import 'source/css/crux/variable';

.radius-search-modal-container {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $heading-color !important;

  & > div:last-child {
    overflow-y: visible;
    min-width: 321px;
    border-radius: 4px;
  }
  & * :not(.fa) {
    font-family: $poppins;
  }

  .radius-search-modal-title {
    font-size: 20px;
    font-weight: 500;
    background-color: white;
    padding: 8px 15px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $black-12-opaque;
    img {
      margin-right: 13px;
      margin-left: -15px;
    }
    .closeButton {
      padding: 0;
      color: $black-54-opaque;
      &:hover {
        background-color: transparent;
      }
      svg {
        font-size: 16px;
      }
    }
    .radius-search-modal-container {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $heading-color;

      & > div:last-child {
        overflow-y: visible;
        min-width: 321px;
        border-radius: 4px;
      }
      & * :not(.fa) {
        font-family: $poppins;
      }
    }
  }

  .radius-search-title {
    font-weight: 500;
    font-size: 15px;
    color: $charcoal-gray;
    display: inline-block;
    width: calc(100% - 25px);
  }

  .radius-search-modal-body {
    overflow: visible;
    padding: 15px;
    .radius-search-image {
      height: 193.78px;
      width: 291px;
      margin-bottom: 9px;
      margin-top: 15px;
      & > img {
        height: 193.78px;
        width: 291px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    .radius-search-modal-details {
      margin-left: 5px;
      max-width: 286px;

      .radius-search-description-label {
        margin-right: 7px;
        color: $heading-color;
        font-weight: 500;
        display: inline-block;
        font-size: 14px;
        line-height: 157%;
        letter-spacing: 0.1px;
        &.target-property {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }
      .radius-search-description {
        color: $black-60-opaque;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
      .radius-search-property-container {
        margin-top: 3px;
        margin-bottom: 5px;
      }
      .radius-search-distance-container {
        display: flex;
        align-items: center;
        .crux-mui-select {
          .mui-select-render-input__container {
            position: relative;
            #mui-select-tooltip {
              margin-top: -3px;
              margin-left: 3px;
            }
            & > div {
              & fieldset > legend > span {
                padding-left: 23px;
              }
            }
            #radius-modal-auto-complete{
              color: rgb(128, 128, 128);
            }
            .tool-tip-component {
              z-index: 999;
              position: absolute;
              bottom: 67%;
              right: 59%;
            }
            .tool-tip-component .icon-container {
              margin-left: 7px;
              font-size: 6px;
              display: inline-flex;
              height: 12px;
              width: 12px;
              top: 0px;
              right: 2px;
              > svg {
                position: absolute;
                right: -3px;
                bottom: -3px;
              }
            }
            .icon-container {
              color: #ffffff !important;
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.54);
              position: relative;
            }
            .tool-tip-component.white .icon-container {
              color: rgba(0, 0, 0, 0.54);
            }
          }
        }
      }
      .suburbOnly-tooltip, .includeHistoric-tooltip, .distance-tooltip {
        margin-right: 13px;
        & > span {
          font-size: 6px;
          color: #ffffff;
          border-radius: 10px;
          background: $black-54-opaque;
          position: relative;
          i.fa-info {
            font-size: 8px;
            line-height: 1.6em;
          }
        }
        & + .custom-tool-tip {
          line-height: normal;
        }
        .icon-container {
          display: inline-flex;
          height: 12px;
          width: 12px;
          top: 2px;
          right: 2px;
          > svg {
            position: absolute;
            right: -3px;
            bottom: -3px;
          }
        }
      }
      .suburbOnly-checkbox-container, .includeHistoric-checkbox-container {
        float: none;
        margin: 0;
        padding: 0;
        & > label {
          margin: 12px 0 0;
        }
      }
      #suburbOnlyFormControl,
      #includeHistoricFormControl {
        width: 100%;
        justify-content: space-between;
        .Mui-checked {
          + .MuiSwitch-track {
            background-color: $blue;
          }
        }
        & > span:first-child {
          & > span:last-child {
            opacity: .25;
          }
        }
      }
    }
  }
  .dialog-footer {
    padding: 10px;
    justify-content: center;
    border-top: 1px solid $black-12-opaque;
    .radius-search-button-container {
      margin: 0;
      text-align: center;
      .radius-search-button {
        // width similar to property img width
        width: 291px;
        border-radius: 4px;
        font-size: 13px;
        &.disabled {
          background-color: $gray-border-light !important;
          cursor: default;
        }
      }
    }
  }
}
