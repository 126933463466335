@import 'source/css/crux/variable';
@import 'source/css/crux/mixins';

#property-detail {
  padding-top: 30px;
  &.floating-header {
    padding-top: 150px;
  }
  #crux-search-bar {
    #crux-advance-search {
      & {
        width: 100%;
      }
      .input-group-lg {
        & > .form-control, .input-group-addon {
          & {
            height: 44px;
            font-size: 12px;
          }
        }
        & > .input-group-btn {
          & > .btn {
            & {
              height: 44px;
              font-size: 12px;
            }
          }
        }
        & > span {
          background-color: #6dabf4;
          min-width: 165px;
          text-align: center;
          color: #fff;
        }
      }

      .input-group-lg {
        > .form-control {
          &.slas-keyword {
            .form-control {
              & {
                height: 44px;
              }
            }
          }
        }
      }
    }
  }
  #property-grid {
    > .flex-container {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;
      @media screen and (max-width: 991px) {
        flex-flow: column;
      }
      & > div {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding-left: 15px;
        padding-right: 15px;
        height: 406px;
        @media screen and (max-width: 991px) {
          flex: none;
          width: 100%;
        }
      }
    }
    .box {
      .container {
        padding: 0;
        width: 100%;
      }
    }
    .mini-box {
      .box {
        .thumbnail {
          height: 100%;
          border: 0;
        }
      }
      & > [class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    & > .row {
      margin-right: -15px;
      margin-left: -15px;
      & > [class^="col-"] {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .thumbnail {
      .caption {
        padding: 20px 30px 30px;
      }
    }
  }
  .pad-bot-20 {
    padding-bottom: 20px;
  }

  .property-panel-header {
    height: calc(100% - 80%);
  }
}

.floor-plan {
  height: 20px;
  width: 20px;
}

.tooltip-box-shadow {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.16);
}

.street-view-container {
  position: relative;
  height: 100%;
  .map {
    height: 100%;
  }
}

.err-message {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  @include center-align;
  background-color: #DFDFDF;
  font-size: 16px;
}

#similar-props-panel, #street-view-panel, #nearby-schools-panel {
  a {
    .thumbnail {
      overflow: hidden;
      cursor: pointer;
      padding: 0;
      margin: 0;
      .map-container {
        max-height: 106px;
        overflow: hidden;
      }
      .caption {
        h4 {
          color: $heading-color;
          font-weight: 500;
          font-size: 13px;
        }
        p {
          color: $gray-text;
        }
      }
    }
  }
}

.scroll-container {
  height: calc(64vh);
  position: relative;
  .loader {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include center-align;
    z-index: 1;
  }
}

.no-property-available {
  @extend .err-message;
}

.home-container {
  position: relative;
  bottom: 23px;
  .home-button {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items : center;
    justify-content: center;
    margin-right: 10px;
    .__react_component_tooltip {
      visibility: hidden;
      background-color: $charcoal-gray;
      & * {
        font-family: $poppins;
      }
      &:after {
        border-left-color: $charcoal-gray;
        border-left-style: solid;
        border-left-width: 6px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        right: -6px;
        top: 50%;
        left: 100%;
        margin: -4px 0;
      }
    }
    &:hover {
      & > svg {
        color: #39f;
      }
      .__react_component_tooltip {
        visibility: visible;
      }
    }
    & > svg {
      font-size: 27px;
      color: #666;
    }
  }
  .home-button-tooltip {
    position: absolute !important;
    opacity: 1;
    left: -90px !important;
    top: 4px!important;
    color: white;
  }
}

#property-detail .box .tools-container .icon-tool > svg {
  opacity: 1;
}
