@import 'source/css/crux/mixins';

.nearby-school-panel {
  & > .nearby-school-container {
    .view-on-map{
      min-width: 141px;
    }
    & > #nearby-school-view {
      #nearby-school-listing-container {
        .content {
          padding: 0;
          .tab-container {
            background-color: #f3f2f1;
            margin-bottom: 5px;
            @include border-radius(5px);
            .tabs {
              .tab-menu {
                min-height: 31px;
              }
              padding: 5px;
            }
          }
          .error-fetching {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .nearby-school-listing-tabs {
          margin-bottom: 0;
        }
        .simplebar-content {
          margin-right: 0;
        }
      }
    }
    .header-container {
      justify-content: space-between;
    }
  }
}
