#development-application-details-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  .development-application-details-modal-body {
    padding: 10px 10px 24px 10px !important;
    display: flex;
    overflow-y: hidden !important;
    .development-application-details-loader{
      width: 100%;
      .toggle-collapse-trigger {
        width: 30px;
        height: 1px;
        padding-right: 10px;
        padding-left: 10px;
        float: left;
        button {
          border: 0;
          color: $blue;
          background-color: transparent;
          font-weight: 500;
        }
        .fa {
          font-size: 18px;
        }
      }
      hr {
        background-color: $gray-border-light;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      & .panel-group {
        margin-bottom: 0;
        .development-application-details {
          .panel-title {
            display: flex;
            .panel-header {
              flex-grow: 1;
              display: flex;
              .heading {
                width: 15%;
                padding: 0 15px;
                &:first-child, &:nth-child(4) {
                  width: 20%;
                }
                .cross {
                  margin-top: -20px;
                  display: inline-block;
                  margin-left: 1px;
                }
              }
            }
          }
        }
      }
      .DA-panel {
        .row {
          margin: 0;
          .DA-panel-content {
            display: flex;
            & > div {
              width: 15%;
              padding: 0 15px;
             &:first-child,&:nth-child(4) {
                width: 20%;
              }
            }
          }
        }
        & > .panel-collapse {
          .panel-body {
            .expanded-panel-content {
              display: flex;
              flex-wrap: wrap;
              padding-left: 45px;
              .column {
                flex: none;
                width: 33.33%;
                .title {
                  color: $heading-color;
                  font-weight: 500;
                  font-size: 13px;
                  text-transform: capitalize;
                  padding: 5px 0;
                  border-bottom: 1px solid #eee;
                }
                .value {
                  padding: 10px 0 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .__react_component_tooltip.type-light.place-bottom:after {
    border-bottom-color: $heading-color;
  }
  .da-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 10px;
    .cross {
      margin-top: -20px;
      display: inline-block;
      margin-right: 1px;
    }
  }
}
