@import 'source/css/crux/mixins';

#similar-property-view {
  .similar-property-details {
    .scroll-container {
      .simplebar-content {
        margin-right: 0;
      }
    }
    .content {
      background: white;
      margin-top: 20px;
      .tab-container {
        background-color: #f3f2f1;
        box-shadow: none;
        margin-bottom: 15px;
        margin-left: 15px;
        width: 93%;
        @include border-radius(5px);
        .similar-property-tabs {
          padding: 5px;
          .tab-menu {
            font-size: 12px;
            text-transform: none;
            min-width: 100px;
            min-height: 40px;
            flex-grow: 1;
            font-weight: 500;
            @include border-radius(5px);
            opacity: 1;
            color: $charcoal-gray;
            transition: all ease .5s;
            &:hover {
              background-color: #e0dfde;
            }
            & > span {
              padding: 0;
              & > span {
                padding: 6px 12px;
                > span {
                  font-weight: 500;
                  font-size: 12px;
                  color: $heading-color;
                }
              }
            }
            &[aria-selected="true"] {
              background-color: white;
              color: $blue;
            }
          }
          & > div {
            & > div {
              & > span:last-child {
                display: none;
              }
            }
          }
        }
      }
      .no-info {
        color: $gray-text;
        height: calc(100% - 125px);
        @include center-align;
      }
      .similar-property-list-container {
        padding-right: 7px;
        overflow: auto;
        & > li {
          position: relative;
          margin-left: 15px;
          margin-right: 15px;
          border-bottom: solid 1px #d9d9d9;
          background-color: lighten(#f3f2f1, 1%);
          &:first-child {
            margin-top: 5px;
          }
          &:last-child {
            margin-bottom: 5px;
          }
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          .img-container {
            height: initial;
            overflow: hidden;
            position: relative;
            .no-image {
              border: $gray-border-light solid 1px;
            }
            &:after {
              content: '';
              display: block;
              padding-top: 67%;
            }
            & > img {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
            }
            img {
              display: block;
              max-width: 100%;
              height: auto;
            }
            .app-image-gallery-component {
              width: 100%;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              .image-gallery-slide-wrapper {
                & > span {
                  & > button {
                    opacity: 0;
                    transition: opacity 0.3s ease;
                  }
                }
                & > .image-gallery-index {
                  opacity: 0;
                  transition: opacity 0.3s ease;
                }
                &:hover {
                  & > span {
                    color: red;
                    & > button {
                      opacity: 1;
                    }
                  }
                  & > .image-gallery-index {
                    opacity: 1;
                  }
                }
              }
            }
          }
          .caption {
            padding: 10px;
            & > .address {
              font-weight: 500;
              font-size: 14px;
              color: $heading-color;
              margin-bottom: 5px;
              position: relative;
              z-index: 2;
              &:hover {
                color: $blue;
                text-decoration: underline;
              }
              @media screen and (max-width: 1024px) {
                font-size: 14px;
                margin-bottom: 5px;
              }
            }
            .sub-text {
              font-size: 14px;
              color: lighten($gray-text, 20%);
              font-weight: 300;
            }
            .attributes-container {
              margin-top: 10px;
              display: flex;
              align-items: center;
              ul {
                &.list-inline{
                  li {
                    .property-attribute {
                      .property-attribute-val {
                        font-size: 12px !important;
                        img {
                          width: 20px;
                        }
                      }
                    }
                    padding: 0;
                    &:not(:last-child) {
                      margin-right: 10px;
                    }
                    &:first-child {
                      margin-left: 5px;
                    }
                  }
                }
              }
              .distance-container {
                margin-left: auto;
                span {
                  font-size: 12px;
                  color: $heading-color;
                  font-weight: 500;
                  @media screen and (max-width: 1024px) {
                    font-size: 12px;
                  }
                }
              }
              .land-area-tool-tip-div {
                & > p {
                  position: relative;
                  z-index: 2;
                }
              }
            }
            .transaction-summary {
              color: $gray-text;
              .undisclosed-sales--price-info {
                z-index: 2;
                position: relative;
                user-select: none;
              }
            }
          }
          &.active-list {
            -webkit-box-shadow: 0px 0px 7px 3px rgba(51, 153, 255, 1);
            -moz-box-shadow: 0px 0px 7px 3px rgba(51, 153, 255, 1);
            box-shadow: 0px 0px 7px 3px rgba(51, 153, 255, 1);
            transition: all ease 0.5s;
          }
          & > a.overlay-button {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            cursor: hand;
            cursor: pointer;
            &:hover {
              & + .img-container {
                .image-gallery-slide-wrapper {
                  button {
                    opacity: 1;
                    display: block;
                  }
                  .image-gallery-index {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
      .scroll-container {
        padding-top: 5px;
        padding-bottom: 5px;
        height: calc(100vh - 336px);
      }
    }
  }
}
