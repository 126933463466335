.back-to-top {
  position: fixed;
  bottom: 90px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  opacity: 0;
  z-index: 9;
  button {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    @include border-radius(50%);
    border: none !important;
    min-width: 45px !important;
    min-height: 45px !important;
    padding: 0 !important;
    background-color: #fff !important;
    cursor: default;
    img {
      margin: -5px 0 0;
      max-width: 20px;
    }
  }
  p {
    margin-top: 5px;
    color: $heading-color;
    font-weight: 500;
    cursor: default;
  }
  &.showing {
    opacity: 1;
    & > button {
      cursor: pointer;
    }
    & > p {
      cursor: text;
    }
  }
  .button-tertiary {
    &:hover, &.active {
      background-color: rgba(#D9D9D9, 1) !important;
      border-color: #39f !important;
    }
  }
}
