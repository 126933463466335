ul {
  &.list-inline{
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .property-attribute {
    & {
      display: table;
    }
    i {
      & {
        font-size: inherit;
        margin-left: 5px;
      }
    }
    &[type="bed"], &[type="car"], &[type="bath"] {
      width: 50px;
    }
    .property-attribute-unit {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      p {
        margin-bottom: 0;
      }
    }
    .property-attribute-val {
      display: flex;
      vertical-align: middle;
      padding: 5px 0;
      color: $black-87-opaque;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.15px;
      @media screen and (max-width: 1024px) {
        padding: 5px 0;
      }
      > span {
        display: flex;
        align-items: center;
        > span {
          padding-left: 5px;
        }
      }
    }
  }
  .update-data-button {
    margin-left: 5px;
  }
}
// similar property
#similar-property-view {
  .property-attribute {
    &[type="bed"], &[type="car"], &[type="bath"] {
      width: 20px;
    }
    .property-attribute-val {
      > span {
        display: flex;
        align-items: center;

      }
      & > {
        img {
          width: 20px;
        }
        span {
          & span {
            padding-left: 3px;
          }
        }
      }
    }
  }
}


// map bubble
.property-info-window {
  .list-inline {
    li {
      .property-attribute {
        &[type="bed"], &[type="car"], &[type="bath"] {
          width: 20px;
        }
        .property-attribute-val {
          > span {
            display: flex;
            align-items: center;
          }
          & > {
            img {
              width: 20px;
            }
            span {
              & span {
                padding-left: 3px;
              }
            }
          }
        }
      }
      padding: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:first-child {
        margin-left: 5px;
      }
    }
  }
}
