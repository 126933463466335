@import "source/css/crux/variable";
@import "source/css/crux/mixins";

.crux-dropdown {
  display: inline-block;
  font-size: 13px;
  color: $charcoal-gray;
  font-weight: 400;
  height: 38px;
  cursor: pointer;
  position: relative;
  &.crux-dropdown--status-type-filter {
    button {
      &:not(:last-of-type) {
        border-color: #fff;
        padding: 6px 16px;
        font-size: 14px;
      }
    }
  }
  &.crux-dropdown--search-type-filter {
    button :first-child {
      justify-content: flex-start;
    }
    button {
      height: 100%;
      &:not(:last-of-type) {
        justify-content: flex-start;
        border-color: $charcoal-gray;
        font-size: 14px;
        padding: 6px 16px;
      }
      &:not(:first-child) {
        height: 100%;
      }
      &:hover {
        background-color: $blue-hover;
      }
    }
  }
  .crux-dropdown-heading {
    &__icon-caret {
      cursor: pointer;
    }
  }
  .list-select-options {
    &__option {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      font-weight: 400;
      color: lighten($charcoal-gray, 10%);
      margin: 0;
      padding: 8.5px 15px;
      vertical-align: middle;
      white-space: nowrap;
      align-items: center;
      cursor: pointer;
      & > input {
        display: none;
        &:checked {
          & + .list-select-options__check-icon {
            background-color: $charcoal-gray;
            border-color: $charcoal-gray;
            & > i {
              display: block;
            }
          }
        }
      }
      &:hover {
        & > .list-select-options__check-icon {
          border-color: $charcoal-gray;
        }
      }
      &--hidden {
        display: none;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &--active {
        background-color: transparent;
        cursor: default;
        .list-select-options__checkbox-label__option-label, .list-select-options__checkbox-label__option-label--sub-label {
          color: rgba(0, 0, 0, 0.87);
        }
      }
      &--disabled {
        color: rgba(0, 0, 0, 0.25);
        &:hover {
          background-color: initial;
        }
      }
    }
    &__checkbox-label {
      font-size: 14px;
      vertical-align: middle;
      text-transform: capitalize;
      color: black;
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      letter-spacing: normal;
      &__option-label {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        &--sub-label {
          font-size: 12px;
          text-transform: none;
          color: lighten(black, 60%);
        }
      }
    }
    &__check-icon {
      // flexbox does not respect fixed dimensions
      min-width: 19px;
      min-height: 19px;
      max-width: 19px;
      max-height: 19px;
      margin-right: 10px;
      background-color: #fff;
      @include center-align;
      border-radius: 3px;
      border: 1px solid darken($gray-border-lighter, 10%);
      & > img {
        display: block;
        width: 12px;
        height: 12px;
      }
    }
  }
  .crux-range-select {
    padding: 10px 20px;
    &__container {
      display: flex;
      flex-wrap: nowrap;
      & > div {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      &__min-select, &__max-select {
        font-size: 12px;
        color: $gray-text;
        & > p {
          margin-bottom: 5px;
        }
        .dropdown-modal {
          width: 100%;
          padding: 0;
        }
        label {
          font-size: 14px!important;
        }
      }
    }
    &__label {
      @include header-text(14px, 400);
      margin-top: 0;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 400;
    }
    &__sub-label {
      font-size: 12px;
      color: $gray-text;
      & > span {
        text-transform: lowercase;
      }
    }
    &__error-message {
      color: $error;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    .MuiTabs-root {
      .MuiTabs-indicator {
        background-color: #39f;
      }
      .Mui-selected {
        color: $black-87-opaque;
      }
    }
  }

  .list-box {
    padding: 5px 15px;
    min-width: 300px;
    &--property-type {
      width: 332px;
    }
    &--property-sub-type{
      width: 955px;
    }
  }
}
