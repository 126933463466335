.flex-container {
  display: flex;
  position: relative;
  &.flex-container-col {
    flex-direction: column;
  }
  &.flex-container-row {
    flex-direction: row;
  }
}
.flex-margin {
  margin-left: -15px;
  margin-right: -15px;
}
.flex-padded {
  padding-left: 15px;
  padding-right: 15px;
}
.flex-row {
  display: flex;
  position: relative;
  flex-direction: row;
}
.flex-col {
  display: flex;
  position: relative;
  flex-direction: column;
}