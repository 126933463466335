@import '../../../variable';
@import '../../../mixins';

.display-options-container {
  h3 {
    font-size: 16px;
    color: $charcoal-gray;
    font-weight: 500;
  }
  .export-csv-headers-options, .export-csv-content-options, .export-csv-filter-options {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .export-csv-content-options {
    .custom-checkbox:nth-child(2) {
      flex-grow: 1;
    }
  }
  .custom-checkbox {
    flex-basis: 236px;
    & > label {
      @include generic-checkbox-styling;
      .checkbox-label > span {
        text-transform: none;
        font-size: 14px;
      }
      white-space: normal;
      align-items: flex-start;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .custom-checkbox-disabled {
    opacity: 0.3;
  }
  .disclaimer {
    margin-top: 15px;
    font-size: 12px;
  }
}
