.dppr-preview-modal {
  &.crux-classic-modal {
    & > div:last-child {
      max-width: initial;
    }
  }

  .block-ui.white-blocker, .crux-portal-container, .retry-report {
    height: calc(100vh - 261px);
    width: 955px;
  }

  .dialog-footer {
    margin-top: 23px;
    .dppr-preview-modal-footer {
      height: 24px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dppr-preview-copy-link {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        span {
          margin-left: 5px;
        }
      }
      .share-button {
        width: 100px;
        height: 40px !important;
        font-size: 14px !important;
        border-radius: 2px;
        font-weight: 500 !important;
      }
    }
  }
}
