@import '../../variable';
@import '../../mixins';

$rpd-sidebar-hover-color: rgba(100, 100, 100, 0.1);

#rpd-sidebar {
  .rpd-sidebar-menu {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 45px;

    .rpd-burger-menu {
      height: 14px;
      background-color: transparent;
      outline: none;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;

      & > span {
        display: block;
        border-top: 2px solid white;
        width: 18px;
        &:last-child {
          width: 60%;
        }
      }
    }

    &.right-align {
      margin-right: 0;
      margin-left: 45px;

      .rpd-burger-menu {
        align-items: flex-end;
      }
    }
  }

  #rpd-slider-pane {
    position: absolute;
    width: 370px;
    left: -345px;
    transition: left 0.3s ease-in-out;
    background-color: transparent;
    z-index: 1;

    #rpd-redirection-portal {
      width: 345px;
      height: 100%;
      background-color: white;
    }


    &.active {
      left: 0;
      overflow: hidden;
      #rpd-redirection-portal {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
      }
    }

    #rpd-sidebar-header {
      .rpd-opening-text {
        padding: 10px 45px;
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    .rpd-link {
      position: relative;
      & > button, & > span > button {
        outline: none;
        background-color: transparent;
        border: none;
        padding-left: 45px;
        padding-right: 45px;
        height: 69px;
        width: 100%;
        text-align: left;
        color: $heading-color;
        font-size: 13px;
        transition: background-color 0.1s ease-in-out;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //RPSUITE-180629619 added this since the styling clashes with rp-reuse's old material ui
        box-shadow: none;

        &:hover {
          background-color: $rpd-sidebar-hover-color;
        }

        // Overriding .disabled styles for button in typography.less
        &.disabled, &:disabled {
          background-color: transparent !important;
          color: $gray-text;
          &:hover {
            background-color: $rpd-sidebar-hover-color !important;
          }
        }

        & > span {
          border-radius: 4px;
          &.new-tag {
            color: white;
            padding: 3px 10px;
            font-size: 10px;
            line-height: 14.2833px;
            background-color: $blue;
            text-transform: capitalize;
          }
          &.coming-soon-tag {
            background-color: $charcoal-gray;
          }
        }
      }

      &:after {
        content: '';
        border-bottom: 1px solid $line-break-gray;
        width: 75%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &.rpd-sidebar-btn-active {
        background-color: $mirage;
        color: white;
        font-size: 14px;
        position: relative;
        &:before {
          content: '';
          border-right: 4px solid $blue;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
        &>button {
          color: #FFFFFF;
        }
      }
      &:last-child {
        position: absolute;
        bottom: 1px;
        width: 100%;
        button {
          display: flex;
          justify-content: flex-start;
          .icon-tag {
            padding: 3px 10px;
            font-size: 10px;
            svg {
              width: 20px;
              fill: #38424a;
            }
          }
        }
      }
    }

    #rpd-redirection-portal {
      position: relative;
      .MuiSnackbar-root {
        position: absolute;
        left: 10px;
        width: 325px;
      }
    }
  }
}

