:root{
    --track-color:rgba(216, 216, 216, .2);
    --scroll-color: #39f; // not working with scss variables
    --scroll-thickness: 6px;
    --scroll-border-radius: 3px;
}

.simplebar-wrap{
    &__custom-scroll{
       
        .simplebar-content {
            margin-right: 15px;
        }

        // scroll track
        .simplebar-track[style*="visible"] {
            background-color: var(--track-color);
        }

        .simplebar-track{
            // scroll track
            border-radius: var(--scroll-border-radius);
            &.simplebar-vertical {
                width: var(--scroll-thickness);
                right: 3px;
            }
            &.simplebar-horizontal {
                height: var(--scroll-thickness);
                .simplebar-scrollbar{
                    border-radius: inherit;
                    height: inherit;
                    top:0;
                }
            }

            // scroll bar
            &.simplebar-vertical .simplebar-scrollbar::before,
            &.simplebar-horizontal .simplebar-scrollbar::before{
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: var(--scroll-border-radius);
                background: var(--scroll-color);
            }
        }

        .simplebar-scrollbar.simplebar-visible:before {
            /* When hovered, remove all transitions from drag handle */
            opacity: 1;
            transition: opacity 0s linear;
        }

    }
}
