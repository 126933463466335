@import '../../mixins';

.dropdown-modal {
    background-color: #fff;
    position: absolute;
    top: 130%;
    left: 0;
    z-index: 9;
    padding-top: 10px;
    padding-bottom: 10px;
    @include border-radius(3px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    min-width: 110px;
    & .option {
        @include generic-checkbox-styling;
    }
    &.hidden {
        display: none;
    }
}
