.crux-portal-container {
  & > div {
    height: 100%;
    width: 100%;
    iframe {
      border: none;
    }
  }
  .portal-error-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
