@import 'source/css/crux/mixins';

.title-information-modal,
#title-details-panel {
  // TODO: check if we can make this more specific
  & * :not(.fa) {
    font-family: $poppins;
  }
  .scroll-body {
    width: 610px;
    height: 558px;
    border-radius: 6px;
  }
  .modal-title {
    font-size: 20px;
    font-weight: 500;
    background-color: $dirty-white;
    padding: 20px 30px;
    line-height: 1.16667em;
    .title-information-header {
      font-size: 20px;
      font-weight: 500;
      color: $charcoal-gray;
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      background: transparent;
      color: $charcoal-gray;
      &:hover {
        color: $blue;
      }
    }
  }
  .content-container {
    padding: 0 30px 18px;
    .titles-information-container {
      padding-bottom: 20px;
      padding-top: 17px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      .titles-row {
        display: flex;
        &:not(:last-child) {
          padding-bottom: 10px;
        }
        .titles-label {
          flex-basis: 35%;
          color: $charcoal-gray;
          font-weight: 500;
        }
        .titles-content {
          flex-basis: 65%;
          word-break: break-word;
          color: $gray-text;
        }
      }
    }
  }

  .error-fetching {
    height: 450px;
    padding: 0;
  }

  .block-ui {
    height: 100%;
    .block-ui-overlay {
      background-color: #FFF;
    }
  }

  .no-content {
    @include center-align;
    height: 100%;
  }
  .no-titles-message {
    @include center-align;
    height: 130px;
  }
}

#title-details-panel {
  padding: 20px 30px 30px;
  .content-container {
    padding: 7px 3px;
    .titles-information-container {
      font-size: 13px;
    }
  }
  .header-container {
    margin-bottom: unset;
  }
}