@import '../../variable';

.box {
  .property-attributes {
    h4 {
      font-weight: 500;
      color: $heading-color;
    }
    p {
      margin: 0;
    }
    hr {
      margin: 20px 0 !important;
    }
    .property-tags {
      padding: 0;
      .property-tags-row {
        margin-left: -5px;
        margin-right: -5px;
      }
      .property-tag {
        width: 123px;
        height: 30px;
        font-family: $poppins;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        text-align: center;
        color: #ffffff;
        display: inline-block;
        background-color: red;
        margin: 0 5px;
        padding: 7px 5px;
        text-transform: uppercase;
        &.tag-red {
          background-color: $search-recent-sale-tag;
        }
        &.tag-green {
          background-color: $search-for-rent-tag;
        }
        &.tag-blue {
          background-color: $search-for-sale-tag;
        }
        &.tag-purple {
          background-color: $search-recent-advice-tag;
        }
        &.tag-orange {
          background-color: $search-historical-tag;
        }
      }
      .property-tag-hidden {
        display: none;
      }
      .custom-tool-tip {
        width: auto;
        text-align: center;
      }
    }
    .property-attribute-label {
      color: $heading-color;
      font-weight: 500;
      white-space: nowrap;
    }
    .attr-container {
      margin: 5px 0;
      &.main {
        > .attr-info {
          margin-left: -8px;
          > div {
            padding-left: 8px;
          }
        }
      }
    }
    .sold-section {
      h4 {
        font-size: 20px;
        display: inline;
      }
      .h4 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .attr-container.col-lg-6 {
      padding: 0 !important;
    }
    .land-area-tool-tip-div {
      margin-left: 3px;
      p {
        line-height: 1;
      }
    }
    // override padding of col-md-6
    .custom-col-empty-padding-right {
      padding-right: 0 !important;
    }
    .custom-col-empty-padding-left {
      padding-left: 0 !important;
    }
    .sold-section {
      &:nth-child(2) {
        .row {
          margin-top: 10px;
        }
      }
      .sold-section-column {
        margin-top: 10px;
        & > div {
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
          & div[class^="col"] {
            @media screen and (max-width: 1024px) {
              width: 50%;
            }
          }
        }
        .custom-col-empty-padding-left {
          padding-left: 10px !important;
        }
      }
    }
    #attr-single-line-address {
      #copy-icon {
        font-weight: 500;
        padding-left: 10px;
        color: $blue;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .undisclosed-price-info {
      color: $header-dark;
    }
  }
}
