@import '../../variable';

.copy {
  color: $link;
  &:focus {
    color: $link;
  }
  &.disabled {
    color: $disabled !important;
  }
}
