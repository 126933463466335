/**
 * this style sheet will be used to override the node_modules/react-redux-ui/styles.css B4B4B4
 */
.loading-bullet {
    animation-duration: 2s;
    animation-delay: 0s;
    font-size: 3em;
    color: #4e4e4e;
}
.block-ui-overlay {
    background-color: rgb(155, 155, 155);
}

.crux-page-loader {
    z-index: 1400;
    height: 100vh;
    width: 100vw;
    position: fixed;
}

// for page loading
.page,
#location-search-suggestion,
#radius-search,
#name-search-results-container,
#saved-search-results {
    &.block-ui, >.block-ui {
        & > .block-ui-container {
            @extend .crux-page-loader;
        }
    }
}

// pdf viewer loader
.crux-print-dialog {
    .block-ui-overlay {
        background-color: transparent;
    }
    .loading-bullet {
        color: rgb(155, 155, 155);
    }
    .block-ui-container {
        height: calc(100vh - 261px);
    }
}
// for similar property
.similar-property-bottom {
    .block-ui-overlay {
        background-color: transparent;
    }
    .box-for-error-panel {
        margin-top: 30px;
        height: 250px;
    }
}

.show-more-container {
    .block-ui {
        height: auto;
        min-height: 17px;
        >div[tabindex="0"]:not(.block-ui-container) {
            visibility: hidden;
        }
        .block-ui-container {
            min-height: auto;
        }
    }
}

.block-ui {
    &.advertisement-list-loader,
    &.campaign-loader,
    &.sale-history-loader,
    &.development-application-details-loader {
        .block-ui-overlay {
            background-color: transparent;
        }
    }
    &.campaign-loader {
        width: 100%;
    }
    &.no-background {
        .block-ui-overlay {
            background-color: transparent;
        }
    }
    &.move-to-back {
        z-index: 0;
    }
}
