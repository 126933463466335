@import '../../variable';
@import '../../mixins';

#image-dropzone-container {
  height: 205px;
  position: relative;
  font-size: 12px;
  margin-bottom: 20px;
  .drag-n-drop-container {
    width: 264px;
    margin-left: auto;
    margin-right: auto;
    .dropzone {
      background-color: $dropzone-bg;
      border-radius: 4px;
      border: dashed 2px $heading-color;
      padding: 34px 0;
      &.error-1, &.error-2 {
        background-color: $errorFieldBg;
        border: dashed 2px $errorFieldBorder;
        &:hover, &:focus {
          border-color: $error-field-border-hover;
          outline: none;
        }
      }
      &.error-1 {
        height: calc(205px - 19px);
        padding: 25px 0;
      }
      &.error-2 {
        height: calc(205px - 38px);
        padding: 16px 0;
      }
      &:hover, &:focus {
        border-color: $blue;
        outline: none;
      }
    }
    p {
      margin-bottom: 0px;
    }
  }
  .thumbnail-preview-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    display: block;
    .thumbnail-preview-inner-container {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      .thumbnail-preview-img {
        max-width: 693px;
        max-height: 205px;
        width: auto;
        height: 100%;
      }
      .btn-close {
        position: absolute;
        right: -11px;
        top: -11px;
        width: 22px;
        @include fa-clickable(22px, 50%);
      }
    }
  }
  .drag-n-drop-upload-icon {
    width: 29px;
    height: 29px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .drag-n-drop-update-header {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 500;
    color: $heading-color;
    line-height: 1.8em;
  }
  .drag-n-drop-update-warning {
    margin-top: 4px;
    color: $gray-text;
  }
  .text-danger {
    font-size: 13px;
  }
}
