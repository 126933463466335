.rcs-custom-scroll {
  .rcs-inner-handle {
    width: 6px;
    background-color: $blue;
    @include border-radius(3px);
    opacity: 1;
    margin-top: 0;
    @include border-radius(3px);
    height: 100%;
  }
  .rcs-outer-container {
    &:hover {
      .rcs-custom-scrollbar {
        background-color: fade($gray-darker-background, 20%);
        @include border-radius(3px);
      }
    }
    .rcs-custom-scrollbar {
      background-color: fade($gray-darker-background, 20%);
      @include border-radius(3px);
      opacity: 1;
    }
  }
}

// setting height for components
.timeline-container {
  .rcs-custom-scrollbar {
    height: 440px;
  }
}

.crux-print-dialog-body {
  .rcs-custom-scrollbar {
    height: calc(100vh - 261px);
  }
  .rcs-inner-container {
    > div {
      > div {
        min-height: 1263px;
      }
    }
  }
}

.list-description-body {
  .rcs-custom-scrollbar {
    height: calc(100vh - 460px);
    min-height: 150px;
  }
}

.similar-property-details {
  .scroll-container {
    .rcs-custom-scrollbar {
      height: calc(100vh - 343px);
    }
  }
}

#nearby-school-listing-container {
  .small-panel {
    .rcs-custom-scrollbar {
      height: 290px;
    }
  }
  .rcs-custom-scrollbar {
    height: calc(100vh - 336px);
  }
}

.group-marker-bubble-properties {
  .rcs-custom-scrollbar {
    height: 303px;
  }
}

.identify-popup-content {
  .group-marker-bubble-properties {
    .rcs-custom-scrollbar {
      height: 303px;
    }
  }
  .group-marker-bubble-properties-mini {
    .rcs-custom-scrollbar {
      height: 210px;
    }
  }
}

.owners-modal-container,
.radius-search-modal-container,
.list-description-agents,
.layer-menu-item-container {
  .rcs-custom-scrollbar {
    height: calc(100vh);
  }
}

.campaign-modal-body,
.sale-history-modal-body,
.development-application-details-modal-body {
  .rcs-custom-scrollbar {
    height: calc(100vh - 424px);
  }
}

.campaign-rental-modal-body{
  .rcs-custom-scrollbar {
    height: calc(100vh - 400px);
  }
}

.update-property-modal-container {
  .rcs-custom-scrollbar {
    height: 61.1vh;
  }
}

.live-avm-modal-container {
  .rcs-custom-scroll {
    max-height: 541px;
  }
}
