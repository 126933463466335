@import './css/crux/bootstrap.min.css';
@import './css/crux/components/rc-calendar/index.css';
@import './css/crux/variable';
@import './css/crux/mixins';
@import './css/crux/common';
@import './css/index';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
