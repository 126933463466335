#filter-for-sale {
  .row {
    > div.col-md-5 {
      div.react-datepicker-wrapper {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
    p {
      width: 40px;
      height: 18px;
      font-size: 13px;
      color: $heading-color;
    }
  }
  label {
    height: 18px;
    font-size: 13px;
    color: $heading-color;
  }
}
