

.dropdown-select {
    & .filter-period-container {
        display: flex;
        padding: 5px 20px;
        & .period-option {
            display: flex;
            flex-direction: row;
            width: 25%;
            font-size: 12px;
            font-weight: normal;
            margin: 3px;
            & > input {
                display: none;
                &:checked {
                    & + .checkbox-label {
                        background-color: #38424a;
                        color: #fff;
                    }
                }
            }
            &:hover {
                & > .check-icon {
                    border-color: $heading-color;
                }
            }
            & > .checkbox-label {
                display: flex;
                width: 100%;
                height: 23px;
                align-items: center;
                text-transform: capitalize;
                justify-content: center;
                border-radius: 50px;
                border: 1px solid #C1C1C1;
            }
        }
    }
}
