@import '_variable.scss';
@import 'source/css/crux/mixins';

html {
  font-size: 16px;
  font-family: $poppins;
}

body {
  padding: 0;
  font-family: $poppins;
  background-color: $body-bg;
  font-size: 13px;
  color: $gray-text;
}

#root > div {
  #crux-layout {
    & {
      margin-left: auto;
      margin-right: auto;
    }

    &.is-home-page {
      width: initial;
    }
  }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: white !important;
}

.no-image {
  width: 100%;
}

.dropdown-radio-option {
  visibility: hidden;
}

.container {
  @media screen and (min-width: 1365px) {
    width: 1280px;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.v-align-middle {
  vertical-align: middle !important;
}

// Custom style img icon.
.img-icon {
  margin-right: 5px;
}

.half-opacity {
  opacity: 0.5;
}

.custom-tool-tip.__react_component_tooltip {
  background-color: $heading-color;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 400;
  opacity: 1 !important;
  width: 237px;
  border-radius: 5px;
  @extend .tooltip-box-shadow;
  &.place-left::after {
    border-left-color: $heading-color;
  }
  &.place-bottom::after {
    border-bottom-color: $heading-color;
  }
  &.type-dark.place-right:after, &.type-light.place-right:after {
    border-right-color: $heading-color;
  }
  &.type-dark.place-top:after, &.type-light.place-top:after {
    border-top-color: $heading-color;
  }

  strong {
    font-size: 12px;
  }

  li {
    list-style: initial;
    margin-left: 15px;
  }
}
div.userPrefActions {
  .custom-tool-tip.__react_component_tooltip {
    &.place-left::after {
      border-left-color: $heading-color;
    }
  }
}
.crux-typo-link {
  font-weight: 500;
  font-size: 13px;
  color: $black-87-opaque;
  letter-spacing: 0.1px;
  line-height: 157%;
  margin-bottom: 5px;
  position: relative;
  z-index: 0;
  &:hover {
    color: $blue;
    text-decoration: underline;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.crux-typo-label {
  color: $heading-color;
  font-weight: 500;
}

.view-list-desc {
  border: 0;
  color: #3399ff;
  background-color: transparent;
  font-weight: 500;
  margin-left: 30px;
}

.crux-typo-content {
  color: $gray-text;
  font-weight: 400;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.undisclosed-price-info {
  color: inherit;
  z-index: 10;
  position: absolute;
  text-align: left;
  sup {
    font-weight: normal;
    font-size: 10px;
    top: -7px;
  }
  .undisclosed-tooltip, .sidebar-link-tooltip {
    cursor: hand;
    cursor: pointer;
    line-height: 1px;
    display: inline-block;
  }
  div[id*='undisclosed-price'] {
    line-height: 1.5em;
  }
}

.text-danger {
  color: $errorFieldBorder !important;
  line-height: 1.6;
}

ul {
  padding-left: 0;
  li {
    list-style: none;
  }
}

i.fa.fa-times {
  font-weight: normal;
  font-size: 20px;
}

a {
  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }
}
strong {
  font-weight: 600;
}

.no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.hyper-link {
  & > p {
    cursor: pointer;
    display: inline-block;
    color: $blue !important;
    &:hover {
      color: $blue-hover !important;
    }
  }
}

#card-loader {
  min-height: 500px;
  .block-ui-overlay {
    background-color: transparent;
  }
}

.error-fetching {
  min-height: 150px;
  @include center-align;
  padding: 0 30px 30px;
  height: 100%;
}

.fill-container {
  display: block;
  height: 100%;
  .error-fetching {
    padding: 0;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-icon {
  &:hover {
    color: $blue;
  }
}
.close-button-mui {
  color: $black-54-opaque;
  font-size: 24px !important;
  cursor: pointer;
  &:hover {
    color: $blue;
  }
  &-disabled {
    pointer-events: none;
    cursor: default;
    &:hover {
      color: $black-54-opaque;
    }
  }
}

.modal-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  span {
    font-size: 18px;
  }
}

.slas-match-suggestion {
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  &+ span {
    white-space: pre;
  }
}

.hidden {
  display: none;
}

iframe#launcher {
  z-index: 999!important;
}

.wrapper {
  & > .block-ui {
    min-height: 500px;
    height: calc(100vh - 371px);
  }
}

.default-text-input-outlined {
  width: 100%;
  height: 36px;
  input[type=text] {
    height: 36px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 13px;
  }
}

.rpd-search-highlight {
  background-color: yellow;
  color: black;
}
