.legals-references-container {
  .legals-references-row {
    padding: 16px 0;
    // workaround for container to not hit the scrollbar
    margin-right: 15px;

    &:first-child {
      padding-top: 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $gray-border-light;
    }
    .legal-row {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .title {
        flex-basis: 35%;
        font-size: 13px;
        font-weight: 500;
        color: $charcoal-gray;
      }
      .content {
        flex-basis: 65%;
        font-size: 13px;
        color: $gray-text;
      }
    }
  }
}
