@import 'source/css/crux/mixins';

.parent{
  width: 200px;
  height: 100px;
  border: 1px solid red;
  background-color: green;
  position: relative;
}
.overlay-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  @include center-align;
  z-index: 2;
}

.property-panel {
  flex-grow: 1;
  &.panel-modal-loader {
    height: 100%;
    z-index: 1;
  }
}
