@import '../../../variable';

.gm-style-iw{
  border-radius: 2px 2px 10px 10px;
}
.map-column {
  > .map-container {
    .gm-style .gm-style-iw {
      top: 0 !important;
      left: 0 !important;
      overflow: visible;
      min-height: 101px;
      & > div:first-child {
        overflow: visible;
        height: calc(100% - 30px);
        > div {
          overflow: visible;
        }
      }
    }
    .gm-ui-hover-effect {
      display: none !important;
    }
  }
}

#similar-property-view {
  .gm-style {
    font-family: $poppins;
    > div:first-child {
      div[style*="border-radius: 2px;"] {
        border-radius: 2px 2px 10px 10px !important;
      }
    }
  }
}
.school-map{
  height: 100%;
  .gm-style-iw{
    max-width: 200px;
    overflow: visible !important;
    padding-right: 17px !important;
    padding-bottom: 12px !important;
  }
}

#drawing-instructions-header {
  display: flex;
  justify-content: center;
  & > div {
    opacity: 0.9;
    z-index: 5;
    position: absolute;
    background: #000;
    color: #fff;
    padding: 11px;
    border-radius: 0 0 3px 3px;
  }
}

.distance-overlay-view {
  position: absolute;
  & > span {
    position: relative;
    left: -50%;
    top: -8px;
    white-space: nowrap;
    border-radius: 6px;
    background-color: rgba(51, 153, 255, 0.9);
    padding: 9px;
    color: #ffffff;
    font-size: 12px;
  }
}

.pdp-map-dialog-content {
  .map-loader {
    .backdrop-message {
      .custom-loader {
        text-align-last: center;
      }
      .loader-message {
        font-size: 14px;
        color: #888c8e;
        margin: 0 0 10px;
      }
      .cancel-download {
        cursor: pointer;
      }
    }
    .block-ui-container {
      cursor: default;
      .block-ui-overlay {
        opacity: 0.9;
        background-color: #fff;
      }
    }
  }
  &.download-error {
    .map-loader {
      .backdrop-message {
        .loading-indicator {
          display: none;
        }
        .custom-loader {
          .loading-indicator {
            display: block;
          }
        }
      }
    }
  }
}

.gmnoprint {
  [title="Stop drawing"], [title="Draw a line"], [title="Draw a shape"] {
    visibility: hidden;
  }
}

// heights for page map loader
#crux-layout {
  .property-map-init-loader,
  .searchResult-map-init-loader,
  .parcelSearchResult-map-init-loader,
  .companySearchResult-map-init-loader,
  .nameSearchResult-map-init-loader,
  .legalDescriptionSearchResult-map-init-loader,
  .titleReferenceSearchResult-map-init-loader,
  .radiusSearchResult-map-init-loader,
  .rollValuationSearchResult-map-init-loader,
  .buildingSearchResult-map-init-loader,
  .volumeFolioSearchResult-map-init-loader,
  .savedSearch-map-init-loader {
    height: calc(100vh - 63px);
  }
}

.custom-tool-tip[id$="map-not-available-tool-tip"] {
  width: unset;
}

#map-dropdown-menu-list-grow {
  border-radius: 4px;
  background: #FAFAFA;
  border: 0.2px solid #D6D6D6;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.18);
  width: 133.54px;
  > .dropdown-map-type-icon {
    align-items: center;
    text-align: center;
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    svg:nth-child(2) {
      margin-left: 4px;
      margin-bottom: 2px;
    }
    .corelogic-map-types-logo {
      height: 30px;
      margin-left: -3px;
    }
  }
  ul {
    .MuiListItem-root {
      height: 25px;
      padding-left: 1px;
      font-size: 12px !important;

      &.MuiCheckbox-colorSecondary.Mui-checked {
        padding: unset;
        color: unset;
      }
      .child-mapType-select {
        color: $black-60-opaque;
        letter-spacing: 0.16px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        padding-left: 28px;
        &:hover {
          background-color: unset;
        }
      }
      .parent-mapType-select {
        color: $black-60-opaque;
        &:hover {
          background-color: unset;
        }
      }
    }
    .MuiMenuItem-root {
      > .MuiCheckbox-root {
        &:hover {
          background-color: unset;
        }
      }
    }
  }
  > hr {
    margin-top: unset;
    margin-bottom: unset;
    width: 120px;
  }
}
#crux-google-map-type-dropdown {
  .button-map-type-icon {
    display: flex;
    margin-top: 2px;
    svg {
      margin-left: 5px;
    }
    .esri-icon-img {
      padding-left: 5px;
      height: 16px;
    }
    .corelogic-icon-img {
      padding-left: 5px;
      height: 19px;
    }
  }
}
.map-layer-container {
  .map-layer-menu-container {
    &.hide {
      &+.map-parent-container {
        .map-container {
          .crux_map-custom-control {
            position: relative !important;
            left: 11px !important;
          }
        }
      }
    }
    &+.map-parent-container {
      .map-container {
        .crux_map-custom-control {
          position: relative !important;
          left: 267px !important;
        }
      }
    }
  }
}
#crux-map-dropdown-popper {
  left: 21px !important;
  top: 5px !important;
}
.crux_map-custom-control {
  position: relative !important;
  left: 11px !important;
}
.map-container {
  #map {
    .gm-style {
      > div:nth-child(2){
        > div:nth-child(1) {
          > div:nth-child(1) {
            > div:nth-child(2) {
              z-index: 1 !important;
            }
          }
        }
      }
    }
  }
}


