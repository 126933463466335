@import 'source/css/crux/variable';

#saved-lists-panel {
  padding-top: 24px;

  .saved-lists-search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .saved-lists-search-textfield {
      width: 312px;
      margin-right: 24px;

      input[type=text] {
        box-sizing: border-box;
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 5px;
        font-size: 14px;
      }
    }
  }

  .saved-lists-cards-panel {
    margin-top: 16px;

    .saved-list-card {
      height: 161px;
      color: $black-87-opaque;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20);
      padding: 16px;

      .saved-list-name {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 21.98px;
        letter-spacing: 0.1px;
      }
      .saved-list-property-count {
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.15px;
      }
      .saved-list-alerts {
        margin-top: 10px;
        min-height: 24px;
        font-size: 12px;
        line-height: 19.92px;
        letter-spacing: 0.4px;

        .saved-list-alert-update {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 24px;
          padding: 2px 4px;
          background-color: rgba(76, 175, 80, 0.08);
        }
        .saved-list-alert-error {
          display: flex;
          align-items: center;
          height: 24px;
          padding: 2px 4px;
          background-color: rgba(255, 0, 0, 0.08);
        }
        .saved-list-text-indicator {
          width: 100%;
          height: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
        }
      }
      hr {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .saved-list-action {
        display: flex;
        align-items: center;
        height: 30px;
      }
      .saved-list-notification {
        display: flex;
        align-items: center;
        height: 30px;
        justify-content: flex-end;
      }
      &:hover {
        box-shadow: 0 0 5px 1px $blue;
        transition: all ease 0.3s;
      }
    }
  }

  .saved-list-button {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;

    .result-count-limit {
      margin: 0;
    }

    .block-ui.loader {
      display: flex;
      justify-content: center;
      height: auto;
      min-height: 17px;
      min-width: 100px;
    }
  }
}

.saved-list-drawer {
  width: 356px;
  padding: 16px;

  .saved-list-drawer-header {
    font-size: 18px;
    color: $black-87-opaque;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 28.8px;

    .close-button-style {
      padding: 0;
      min-width: auto;
      color: $black-54-opaque;
      &:hover {
        color: $blue;
      }
    }
  }
  hr {
    margin: 0;
  }
  .saved-list-description-name {
    padding-top: 12px;
    padding-bottom: 12px;

    .MuiOutlinedInput-root:hover fieldset {
      border-color: $blue;
    }
  }
  .saved-list-drawer-button {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
      line-height: 24.5px;
    }
  }
}
