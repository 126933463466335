@import '../../variable';
@import '../forms/imageDropzone';

.update-property-modal-container {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $heading-color !important;

  & > div:last-child {
    overflow-y: visible;
    min-width: 753px;
    border-radius: 6px;
  }
  & * :not(.fa) {
    font-family: $poppins;
  }

  .update-property-modal-title {
    background-color: $body-bg;
    color: $charcoal-gray;
    border-radius: 6px 6px 0 0;
    padding: 16px 30px;
    font-size: 21px;
    .update-property-title {
      font-weight: 500;
      color: $heading-color;
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      background: transparent;
      &:hover {
        color: $blue;
      }
    }
    .modal-title-wrapper {
      align-items: flex-start;
      h6.MuiTypography-root {
        line-height: 20px;
      }
    }
  }

  .update-property-modal-body {
    padding: 0;
    overflow-x: visible;
    overflow-y: auto;
    &> div {
      max-height: 541px;
    }
    .update-property-modal-body-container {
      padding: 15px 30px;
    }

    .error-message {
      padding-top: 7px;
      text-align: center;
      min-height: 27.8px;
    }

    .update-button-container {
      display: flex;
      justify-content: flex-end;

      .update-button {
        &.disabled {
          background-color: $gray-border-light !important;
          cursor: default;
        }
      }
    }

    .simplebar-content {
      margin-right: 0px;
    }
  }

  .update-results-body {
    text-align: center;
    height: 500px;
    width: 753px;
    transform: translate3d(0, 0, 0);
    .block-ui {
      top: 50%;
      transform: translateY(-50%);
      .block-ui-overlay {
        background-color: transparent;
      }
    }
    .update-results-container {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      .update-result-icon {
        height: 50px;
        margin-bottom: 20px;
      }
      .update-result {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .update-result-subtext {
        white-space: nowrap;
      }
      .update-results-button {
        margin-top: 35px;
      }
    }
  }

  .update-property-modal-button {
    border-radius: 2px;
    width: 100px;
    padding: 5px 12px;
    background-color: #39f;
    & > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #fff;
    }
  }

  .update-additional-details {
    margin: 10px 0 20px 0;
    .attribute-label {
      color: $charcoal-gray;
      font-size: 14px;
      font-weight: 500;
    }
    textarea {
      margin-top: 9px;
      width: 692px;
      height: 89px;
      resize: none;
      border: 1px solid #ccc;
      padding: 10px;
    }
  }

}

// NZ Override
.update-property-modal-container-nz {
  & > div:last-child {
    min-width: 500px;
    height: 378px;
  }

  .update-property-modal-body {
    overflow-x: unset;
    overflow-y: unset;
    & > .rcs-custom-scroll {
      max-height: 320px;
      max-width: 600px;
    }
    .update-button-container {
      display: flex;
      justify-content: space-between;
      .update-property-modal-disclaimer{
        font-size: 10px;
        width: 75%;
        color: $gray-text;
        margin-bottom: 0;
      }
    }
  }

  .update-results-body {
    height: 378px;
    width: 500px;
    .update-results-container {
      .update-result-subtext {
        white-space: unset;
        width: 360px;
      }
    }
  }

  .update-additional-details {
    textarea {
      width: 100%;
    }
  }
}
