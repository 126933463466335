@import "source/css/crux/variable";

.radius-picker-modal {
  color: #38424a;
  & > div {
    &:last-child {
      max-width: 1023px;
      width: 100%;
      max-height: calc(100vh - 27px);
    }
  }

  .title {
    background-color: $dirty-white;
    padding: 20px 35px 20px 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 24.5001px;
    }
    span {
      font-weight: 500;
      font-size: 21px;
    }
    .close-icon {
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .content {
    font-size: 14px;
    padding: 15px 40px;
    line-height: 30px;
    width: 1023px;
    & > p {
      margin-bottom: 10px;
    }

    .address {
      font-weight: 500;
      text-align: center;
    }

    .map-container {
      margin: 20px 0;
      min-height: calc(100vh - 300px);
      width: 100%;
      position: relative;
      .map {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      .custom-checkbox {
        margin: 0 30px;
        & > label {
          @include generic-checkbox-styling;
          padding: 0;
          .checkbox-label {
            font-size: 12px;
            font-weight: normal;
          }
          input:checked + .check-icon {
            background-color: $blue;
            border-color: $blue;
          }
          &:hover {
            .check-icon {
              border-color: $blue;
            }
          }
        }
      }
      button {
        &.button-primary {
          font-size: 13px;
          background-color: $link;
          color: white;
        }
      }
    }

    .fill-container {
      margin: 20px 0;
      height: calc(100vh - 300px);
    }
  }
}
