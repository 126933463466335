.filter-date-dropdown > .dropdown-options {
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff !important;
    right: 0;

    &.dropdown-modal {
        left: initial;
    }
}

#range-calendar-date-picker{
    background-color: #fff;
}
.filter-date-dropdown > .dropdown-extra-class {
    width: 705px !important;
}
.filter-date-dropdown > .dropdown-options > div:first-child {
    border-right: 1px solid #E4E4E6;
}

.filter-date-dropdown{
    position: relative;
}

.rc-calendar-picker {
    &.fixed {
        position: fixed;
    }
}

@media screen and (max-width: 1364px) {
    .filter-date-dropdown > .dropdown-options {
        flex-direction: row;
        right: initial;

        &.dropdown-modal {
            left: 0;
        }
    }
}
