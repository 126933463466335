@import "../../variable";

#account-my-details {
  .large-col {
    flex-basis: 70%;
  }
  .small-col {
    flex-basis: 30%;
  }
  .box{
    transition: height 0.4s;
    .box-content{
      .box-content-body {
        > .flex-row+.flex-row {
          margin-top: 20px;
        }
      }
      .block-ui-container{
        margin: 0 -27px;
        .block-ui-overlay {
          background-color: white;
        }
      }
      .error-fetching {
        padding: 0;
      }
    }
  }
  .action-buttons {
    flex-direction: column;
    .flex-col {
      &:first-child {
        flex-direction: row;
      }
    }
  }
  .button-primary {
    height: 37px !important;
    border-radius: 2px;
    padding: 7px 20px !important;
  }
  .button-clear {
    height: 37px !important;
    border-radius: 2px;
    padding: 7px 0px !important;
    margin-left: 10px;
    &:disabled {
      opacity: 0.6;
    }
  }
  & .crux-field-error-container {
    & > span {
      display: block;
    }
  }
  span {
    &.error-message{
      color: $error;
      line-height: 1.6;
    }

    &.api-error-message{
      color: $error;
      line-height: 1.6;
      padding: 8.5px 0;
      font-size: 13px;
    }

    &.success-message{
      color: $soft-green;
      padding: 8.5px 0;
      font-size: 13px;
      &.padding-less {
        padding: 0;
      }
    }

    i {
      &.fa {
        font-size: 13px;
      }
      + span {
        margin-left: 5px;
      }
    }
  }
}
