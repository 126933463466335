#sale-history-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  .sale-history-modal-body {
    padding: 10px 10px 24px 10px !important;
    display: flex;
    overflow-y: hidden !important;
    .sale-history-loader{
      width: 100%;
      .toggle-collapse-trigger {
        width: 30px;
        height: 1px;
        padding-right: 10px;
        padding-left: 10px;
        float: left;
        button {
          border: 0;
          color: $blue;
          background-color: transparent;
          font-weight: 500;
        }
        .fa {
          font-size: 18px;
        }
      }
      hr {
        background-color: $gray-border-light;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
      }
      & .panel-group {
        margin-bottom: 0;
        .sale-history {
          .panel-title {
            display: flex;
            .panel-header {
              flex-grow: 1;
              display: flex;
              .heading {
                width: 20%;
                padding: 0 15px;
              }
            }
          }
        }
      }
      .sale-history-panel {
        .row {
          margin: 0;
          .sale-history-panel-content {
            position: relative;
            display: flex;
            & > div {
              width: 20%;
              padding: 0 15px;
            }
          }
        }
        & > .panel-collapse {
          .panel-body {
            .row {
              width: 100%;
            }
            .sale-history-item-header{
              padding-left: 45px;
              & > div {
                flex: none;
                width: 33.33%;
                color: $heading-color;
                font-weight: 500;
                font-size: 13px;
                text-transform: capitalize;
                padding: 5px 10px 5px 0;
                border-bottom: 1px solid #eee;
              }
              +.withheld-tooltip-info {
                padding-left: 45px;
                padding-top: 5px;
              }
            }
            .expanded-panel-content {
              display: flex;
              flex-wrap: wrap;
              padding-left: 45px;
              .sale-history-item-content {
                & > div {
                  flex: none;
                  width: 33.33%;
                  padding: 5px 0px 5px 0;
                  .value {
                    padding: 7px 0px 7px;
                  }
                }
                .sale-history-person-info {
                  margin-bottom: 5px;
                  &+.sale-history-person-info {
                    border-top: 1px solid #eee;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#sale-history {
  .sale-history--dialog-content-header,
  .sale-item,
  .sale-item .sale-history-panel-content .title,
  .panel-collapse {
    font-family: $poppins;
    .sale-history-item-content {
      .purchaser-name {
        & > div {
          .common-component__deed7--tooltip-info {
            display: block;
            padding: 7px 0px;
          }
        }
      }
    }
  }
  .panel-header .heading,
  .additional-sales-item .item-header,
  .sale-history-item-header .title {
    font-weight: 500 !important;
  }
  .collapse-panel {
    .child-panel {
      .collapse-button {
        min-width: 55px;
      }
    }
  }
}
