.loader-container {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  flex-grow: 1;
  .block-ui-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}