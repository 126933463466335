@import 'source/css/crux/variable';

.owners-modal-container {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $heading-color !important;

  & * :not(.fa) {
    font-family: $poppins;
  }
  .scroll-body {
    max-width: 900px;
    width: 100%;
    overflow: hidden;
  }
  .modal-title {
    font-size: 20px;
    font-weight: 500;
    background-color: $dirty-white;
    padding: 20px 39px;
  }
  .modal-body {
    overflow: hidden;
    padding: 0 24px 24px;
  }
  .row.owner {
    color: $heading-color !important;
  }
  .owner-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24.5px;
    color: rgba(0, 0, 0, 0.87);
  }
  .content-header {
    font-size: 13px;
    font-weight: 500;
    margin: 10px 2px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(222, 221, 222, 0.7);
    color: $heading-color !important;
  }
  .owner-count {
    font-size: 14px;
    font-weight: normal;
    color: #949494;
    line-height: 24.5px;
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    background: transparent;
    &:hover {
      color: $blue;
    }
  }
  .owner-name {
    padding: 3px 16px;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: $blue;
    &.no-link {
      color: $gray-text;
    }
    button {
      border: none;
      background: transparent;
      padding: 0;
      text-align: left;
    }
  }
  .owner-address {
    padding: 3px 24px;
    font-size: 13px;
    font-weight: normal;
    color: $charcoal-gray;
  }
}

.see-all-owner-link {
  color: $blue;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;

  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.owner-link-container {
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  margin-top: 8px;
}
