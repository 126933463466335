@import '../../variable';
@import '../../mixins';

/**
 * Menu Component
 */
#crux-nav {
  position: relative;
  z-index: 1100;
  &.sticky-header {
    .crux-header {
      position: fixed;
      width: 100%;
      top: 0;
      -webkit-box-shadow: 0px 1px 5px 0px #dfdfdf;
      -moz-box-shadow: 0px 1px 5px 0px #dfdfdf;
      box-shadow: 0px 1px 5px 0px #dfdfdf;
      z-index: 999;
      & > div {
        z-index: 999;
        &:first-child {
          top: 0;
        }
        &:last-child {
          top: 68px;
          z-index: -1;
        }
      }
    }
  }
  &.sticky-header-shadow-less {
    .crux-header {
      box-shadow: none;
      & > div {
        position: fixed;
        z-index: 999;
        &:first-child {
          top: 0;
        }
      }
    }
  }
  &.sticky-header.name {
    & .crux-header.name {
      & > div {
        &:last-child {
          top: 106px;
        }
      }
    }
  }
  .crux-header {
    .heading-container {
      background-color: $heading-color;
      min-height: 68px;
    }
    .container-fluid {
      width: 100%;
      padding: 0 40px;
      display: flex;
      &:before, &:after {
        display: none;
      }
    }

    .logo {
      margin-left: -10px;
      display: flex;
      align-items: center;
      .cl-logo {
        display: flex;
        align-items: flex-end;
        &-img {
          width: auto;
          height: auto;
          max-width: 175px;
          max-height: 34.23px;
        }
        h4 {
          margin-top: 0;
          margin-bottom: 6px;
        }
      }
      .cl-logo-txt{
        color: $gray-text;
        text-transform: none;
        font-weight: 500;
        letter-spacing: initial;
        vertical-align: -webkit-baseline-middle;
        display: inline-block;
        font-size: 12px;
        margin-left: 12px;
      }
      button.cl-logo {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    #crux-search-bar{
      flex-grow: 1;
      max-height: 68px;
      @media screen and (max-width: 1024px) {
        margin-left: 10px;
      }
      & > #crux-advance-search {
        width: 500px;
        padding: 14px 0;
        @include center-align;
        & > .input-group {
          width: 100%;
          .input-group-addon {
            height: 40px;
          }
          span {
            &:first-child {
              width: 109px;
              text-align: center;
            }
          }
        }
        .slas-suggestions {
          width: 500px;
          bottom: -1px;
          left: -109px;
          z-index: 999;
          ul {
            max-height: 100%;
          }
        }
        .form-control {
          height: 40px;
        }
      }
    }

    .menu-extras {
      display: flex;
      width: 300px;
      justify-content: flex-end;
      margin-left: auto;
      .user-icon-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        max-height: 68px;
        & > span {
          .user-photo {
            display: block;
            border: 2px solid white;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;
            .loader-container {
              .block-ui-overlay {
                background-color: rgba(255, 255, 255, .1);
              }
              .loading-indicator {
                .loading-bullet {
                  font-size: 20px;
                  color: #b9b9b9;
                }
              }
            }
            .userIcon {
              margin: 0;
            }
            .no-image {
              margin-left: -24%;
              width: 147%;
            }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
            }
            &--default {
              img {
                margin: -2px 0 0 -1px;
              }
            }
          }
        }
      }
      .userIcon {
        background-color: rgba(255, 255, 255, 0.1);
        width: 38px;
        height: 38px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .userInitials {
        font-size: 15px;
        color: white;
      }
      .userPrefDlg {
        padding: 1px;
        background-color: $white;
        top: 63px;
        width: 268px;
        border-radius: 5px;
        left: initial;
        right: 5px;
        max-height: calc(100vh - 75px);
        .userName {
          color: #38424a;
          width: 194px;
          font-weight: 500;
          text-align: left;
        }
        .userEmail {
          width: 194px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
        .userIcon {
          background-color: #38424a;
          margin: 0;
          cursor: auto;
        }
        .userDetailContent {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: 8px;
          & > div {
            @include ellipsis(initial);
          }
        }
        .userDetails, .userSettings {
          display: flex;
        }
        .userDetails {
          background-color: rgba(250, 250, 250, 1);
          border-bottom: 2px solid rgba(0, 0, 0, 0.12);
          padding: 12px;
          align-items: center;
          .user-icon-container {
            .loader-container {
              .block-ui-overlay {
                background-color: #9b9b9b;
              }
              .loading-indicator {
                .loading-bullet {
                  font-size: 20px;
                  color: #4e4e4e;
                }
              }
            }
          }
          .user-photo {
            display: block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            .userIcon {
              width: 40px;
              height: 40px;
            }
            .loader-container {
              .loading-indicator {
                .loading-bullet {
                  font-size: 20px;
                }
              }
            }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
            }
          }
        }
        .userPrefContent {
          background-color: white;
          .userSettings {
            align-items: center;
            width: 100%;
            padding: 0 8px;
          }
        }
        .userPrefActions {
          width: 100%;
          background-color: white;
          & > hr {
            margin: 9px 0;
          }
          & > a {
            color: $heading-color;
            &:hover {
              color: $blue;
            }
          }
          #logoutAction {
            color: #38424a;
            cursor: pointer;
            &:hover {
              color: $blue;
            }
          }
        }
      }
      #navigation {
        display: table !important;
        height: 80px;
        .navigation-menu {
          display: table-cell;
          vertical-align: middle;
          > li > a, > li > a i {
            color: #888888;
          }
          & > li {
            a {
              padding: 16px 20px;
            }
          }
        }
      }
    }
  }
  .historical-property-banner {
    clear: both;
    text-align: center;
    padding: 13px 0px;
    width: 100%;
    height: 44px;
    color: #FFFFFF;
    background-color: $search-historical-tag;
  }
  .bread-crumb-container {
    background-color: white;
    border-bottom: 1px solid #dfdfdf;
    min-height: 52px;
    .activity-bar-actions {
      display: flex;
      white-space: nowrap;
      justify-content: flex-end;
      padding: 9px 0;
      button {
        &.disabled {
          cursor: default;
          background-color: $disabled !important;
          box-shadow: none;
          border: none;
          margin-left: 8px;
          opacity: 0.6;
          & > span:last-child{
            display: none;
          }
        }
      }
      .dropdown {
        & > .dropdown-menu {
          margin-top: 9px;
          display: block;
          background-color: transparent;
          box-shadow: none;
          border: none;
        }
      }
      .cma-report-button {
        overflow: hidden;
        position: relative;
        .cma-loader {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(254, 254, 254, 0.6);
        }
      }
    }
    .breadcrumb-print-container {
      width: 100%;
      display: flex;
      align-items: center;
      & > div:first-child {
        flex-grow: 1;
      }
      #breadcrumbs {
        padding: 15px 0;
        .breadcrumb {
          padding: 0;
          background-color: transparent;
        }
      }
    }
  }
  .nav-section {
    display: flex;
    width: 300px;
    .feature-portal-sidebar {
      height: 100%;
      #rpd-sidebar {
        height: 100%;
      }
    }
  }
  &.search-type-name,
  &.search-type-company {
    #rpd-sidebar {
      .rpd-sidebar-menu {
        align-items: flex-start;
        padding-top: 24px;
      }
    }
  }
  &.search-type-address {
    &.historic {
      #sticky-header {
        & > div {
          &:last-child {
            top: 106px;
          }
        }
      }
      #rpd-sidebar {
        .rpd-sidebar-menu {
          align-items: flex-start;
          padding-top: 24px;
        }
      }
      #crux-search-bar {
        #crux-advance-search {
          &.crux-search {
            align-items: center;
          }
        }
        .search-bar-nav {
          & > div {
            &#crux-advance-search {
              .address-name-search-panel {
                min-width: 740px;
              }
            }
          }
        }
      }
    }
  }
  .property-print-header-container {
    background-color: white;
  }
}

.sticky-header {
  + .wrapper {
    > div:first-child {
      margin-top: 68px;
    }
    #account-container {
      margin-top: 98px;
    }
    #property-print,
    #property-detail {
      margin-top: 120px;
    }
  }
  &.historic {
    + .wrapper {
      #property-detail {
        margin-top: 164px;
      }
    }
  }
}
