@import "source/css/crux/variable";

#rating-valuation-panel {
  padding: 20px 30px 30px;
  .header-container {
    display: flex;
    margin-bottom: 20px;
    h4 {
      color: $heading-color;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
  }
  .rating-valuations {
    .flex {
      &-container {
        display: flex;
        > div {
          display: block;
          @media screen and (max-width: 991px) {
            flex: none;
            width: 100%;
          }
        }
      }
      &-label {
        flex-basis: 53%;
        p {
          color: $heading-color;
          font-weight: 500;
        }
      }
      &-content {
        flex-basis: 47%;
        flex-grow: 1;
      }
    }
    .valuation-modal-trigger-container {
      margin-top: 20px;
      text-align: center;
      & a {
        font-size: 14px;
        font-weight: 500;
        color: $blue;
      }
    }
  }
}
.rating-valuation-modal {
  .scroll-body {
    width: 100%;
    max-width: 565px;
    height: 583px;
    overflow: hidden;
  }
  .modal-body {
    overflow: hidden;
    padding: 0 0 20px;
  }
  .rating-valuation-modal-header {
    background-color: $body-bg;
    padding: 20px;
    h2 {
      color: $heading-color;
      font-size: 18px;
      display: flex;
      align-items: center;
      .close-button {
        margin-left: auto;
        background-color: $body-bg;
        &:hover {
          color: $blue;
        }
      }
    }
  }
  .list-header {
    display: flex;
    flex-grow: 1;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    & .header-item {
      font-size: 14px;
      color: $charcoal-gray;
      font-weight: 500;
      flex-basis: 33.33%;
      flex-grow: 1;
      & #rating-valuation-type-tip {
        max-width: 155px;
      }
    }
  }
  .history-rows {
    padding: 0 20px;
    & > .history-row {
      display: flex;
      & > .column {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: $charcoal-gray;
        flex-basis: 33.33%;
        flex-grow: 1;
        & > span {
          margin-bottom: 10px;
        }
      }
    }
  }
}
