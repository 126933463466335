@import "source/css/crux/variable";

.school-map {
  .gm-style-iw {
    > div:first-child {
      overflow: visible !important;
      .school-info-window{
        margin-top: -8px;
        overflow: visible;
        position: relative;
        .school-marker-content{
          padding-top: 0;
          min-width: 170px;
        }
        h4 {
          font-family: $poppins;
          margin-bottom: 3px;
          font-size: 14px;
          font-weight: 500;
          color: $heading-color;
          &.target-property-error {
            font-weight: normal;
          }
        }
        p {
          font-family: $poppins;
          font-size: 13px;
          color: $gray-text;
        }
      }
    }
  }
}
