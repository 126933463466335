@import '../../../mixins';

#nearby-school-view {
  .tab-container{
    background-color: #f3f2f1;
    margin-bottom: 5px;
    @include border-radius(5px);
    .tabs{
      .tab-menu {
        min-height: 40px;
      }
      padding: 5px;
    }
  }
  #nearby-school-listing-container {
    height: 85%;
    .content {
      padding: 20px 15px 15px;
      .scroll-container{
        height: calc(100vh - 336px);
        position: relative;
        &.small-panel {
          min-height: 121px;
          max-height: 290px;
          height: auto;
          .error-fetching {
            position: absolute;
            width: 100%;
          }
          .nearby-school-list-container {
            .no-info {
              top: 0;
              bottom: 0;
              min-height: 120px;
              p {
                text-align: center;
                margin-bottom: -15px;
              }
            }
          }
        }
      }
    }
  }
}
.nearby-school-listing-container {
  .place-address{
    font-size: 14px;
    color: $gray-text;
  }
  .school-name {
    font-size: 14px;
    color: $heading-color;
    font-weight: 500;
  }
}
