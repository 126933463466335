@import '../../../variable';

.ownership-detail {
  .container {
    padding: 0;
    .flex-heading{
      display: flex;
      align-items: center;
      & > div {
        flex-grow: 1;
        &:last-child{
          text-align: right;
        }
        a{
          color: $link;
        }
      }
    }
    hr {
      margin: 15px 0;
    }
    #no-ownership {
      margin-top: 0;
    }
    .row {
      &.owner-detail-row {
        margin-bottom: 10px;
        .name-button {
          border: none;
          background: transparent;
          padding: 0;
          text-align: left;
          .owner-name {
            color: $blue;
            &:hover {
              color: $blue-hover;
            }
          }
        }
      }
      &.tenure-row {
        margin-top: 20px;
        & > div {
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
          &:first-child {
            @media screen and (max-width: 1024px) {
              margin-bottom: 10px;
            }
          }
        }
      }
      .owner-label {
        margin-bottom: 5px;
        color: $heading-color;
        font-weight: 500;
      }
      .owner-address-label {
        margin-bottom: 5px;
      }
      @extend .hyper-link;
      .owner-type-label {
        & > #occupancy-tooltip {
          display: inline-block;
          margin-bottom: 0;
          margin-left: 3px;
          font-size: 16px;
          vertical-align: middle;
          line-height: 1px;
          color: #38424a;
          cursor: pointer;
        }
      }
      .contact-dropdown {
        button.dropdown-toggle {
          color: #4C4C4C;
          padding-left: 0;
          padding-right: 0;
          span {
            &:first-child {
              padding-right: 3px;
            }
          }
        }
      }
      hr {
        margin: 15px 0;
      }
      #no-ownership {
        margin-top: 10px;
      }
      .row {
        &.owner-detail-row {
          padding: 2px 0;
        }
        .owner-label {
          color: #4C4C4C;
          font-weight: 500;
        }
        .owner-name {
          color: $blue;
        }
        .owner-type-label {
          line-height: 1;
          & > #occupancy-tooltip {
            display: inline-block;
            margin-bottom: 0;
            margin-left: 3px;
            font-size: 22px;
            vertical-align: sub;
            line-height: 1;
            color: $gray-text;
          }
        }
        .contact-dropdown {
          button.dropdown-toggle {
            color: #4C4C4C;
            padding-left: 0;
            padding-right: 0;
            span {
              &:first-child {
                padding-right: 3px;
              }
            }
          }
        }
      }
    }
  }
}
.household-contact {
  position: relative;
  .container {
    hr{
      margin: 20px 0;
    }
    .flex-heading {
      display: flex;
      align-items: center;
      padding-top: 10px;
      & > div {
        flex-grow: 1;
        &:last-child {
          text-align: right;
        }
        a {
          color: $link;
        }
      }
    }
    .content {
      min-height: 70px;
      position: relative;
      .row{
        & > div {
          .contact-number {
            &:last-child {
              display: inline-flex;
              align-items: center;
              img {
                margin-left: 3px;
                position: relative;
                top: -2px;
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 7px;
          }
        }
        .contact-label{
          display: inline-block;
          color: $heading-color;
          font-weight: 500;
          padding-right: 10px;
          min-width: 64.13px;
        }
        .mailing-address-val {
          display: inline-flex;
          align-items: center;
          img {
            margin-left: 3px;
          }
        }
      }
      .no-info-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 70px;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      height: 35px;
      justify-content: space-between;
      .legend {
        font-size: 12px;
        display: flex;
        align-items: baseline;
        & > img {
          margin-right: 5px;
        }
        #privacy-compliance {
          margin-left: 3px;
          display: flex;
          align-self: center;
        }
      }
    }
  }
}
