@import "source/css/crux/variable";

.terms-of-use {
    margin: 60px auto 0;
    width: 822px;
    color: $charcoal-gray;
    min-height: calc(100vh - 168px);
    &__heading {
        h1 {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: normal;
            height: 41px;
            line-height: 2;
            margin: 0;
        }
    }

    &__content {
        margin-top: 28px;
        p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
    }

    &__checkbox {
        margin: 30px 0 8px;
        .custom-checkbox {
            margin: 0 30px;
            & > label {
                @include generic-checkbox-styling ;
                padding: 0;
                justify-content: flex-end;
                .checkbox-label {
                    font-size: 12px;
                    font-weight: normal;
                    margin-left: 8px;
                    span {
                        text-transform: none;
                    }
                }

                .check-icon {
                    width: 14px;
                    height: 14px;
                    border-radius: 2px;
                    > img {
                        width: 10px;
                        height: 10px;
                    }
                }

                input:checked + .check-icon {
                    background-color: $charcoal-gray;
                    border-color: $charcoal-gray;
                }

                &:hover {
                    .check-icon {
                        border-color: $charcoal-gray;
                    }
                }
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
    }
}
