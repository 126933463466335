@import 'source/css/crux/mixins';

.retry-report {
  height: 100%;
  @include center-align;
  font-size: 14px;
  &__message {
    text-align: center;
    justify-content: center;
    color: $charcoal-gray;
    p {
      margin-bottom: 20px !important;
    }
    button {
      width: 94px;
    }
  }
}

.pdf-viewer-footer {
  &.crux-print-dialog-footer {
    .pdf-btn {
      margin: 0 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 6px 16px;
    }
  }
}

.pdp-pdf-loader {
  .backdrop-message {
    font-size: 20px;
    font-weight: 500;
    top: 44%
  }
}
