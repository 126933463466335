#market-insights-card-container {
  &.card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.smaller {
      width: 80%;
      margin: 0 auto;
    }
  }

  .filler-cards {
    height: 0; // to collapse extra divs if it goes to another row
    width: 289px;
  }
}

.market-insights-card.no-data-message {
  font-size: 20px;
  font-weight: 500;
  margin: 100px 0;
  color: $charcoal-gray;
  text-align: center;
}
