@import "../../variable";

#profile-photo {
  padding: 24px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .user-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 20px;
    & > img {
      height: 100%;
      object-fit: cover;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    button, label {
      font-weight: 500;
      margin: 0 5px 20px 5px;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px;
    }
  }

  .message {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  .info-message {
    color: $black-60-opaque;
  }

  .error-message {
    color: $error;
    margin-bottom: 20px;
  }

  .upload-loading {
    margin-bottom: 20px;
  }
}
