.panel-group {
  font-size: 13px;
  font-family: $poppins;
  color: $heading-color;
  .panel {
    border-radius: 0;
    box-shadow: none;
    &:nth-child(even) {
      background-color: $light-gray-bg;
    }
    &:nth-child(odd) {
      background-color: white;
    }
    &+.panel {
      margin-top: initial;
    }
    .panel-heading {
      font-weight: 400;
      &+.panel-collapse {
        >.panel-body {
          border-top: initial;
        }
      }
    }
  }
}
.panel-heading {
  border-radius: 0;
  font-weight: 500;
}
.panel-title {
  font-size: inherit;
  font-family: inherit;
}
