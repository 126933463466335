@import 'source/css/crux/variable';

.save-search-panel,
.save-territory-panel {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  width: 100%;
  .save-search-label,
  .save-territory-label {
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: #38424a;
  }
  .save-search-button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $blue;
  }

  .saved-search-buttons-container {
    display: flex;
    gap: 5px;
    .saved-search-bookmark {
      display: flex;
      align-items: center;
    }
  }
}

.save-search-settings-cont {
  .MuiGrid-container {
    width: 100% !important;
    margin: 0px !important;
  }
  .right-position {
    display: block;
    text-align: right;
  }
  .head-label {
    color: $black-87-opaque;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
  .section-label {
    font-size: 12px;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $black-60-opaque;
  }
  .switch-filter__label {
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: $black-87-opaque;
    position: absolute;
    left: 21px;
    padding-top: 10px;
  }

  .settings-general-grid,
  .settings-notifications,
  .settings-email, .settings-email {
    padding-left: 6px;
    padding-right: 20px;
    .settings-general {
      padding-bottom: 12px;
    }
  }
  .head-grid {
    padding-left: 20px;
    padding-top: 20px;
  }
  .notifications-grid, .new-property-added-grid,
  .property-removed-grid, .time-on-market-grid,
  .withdrawn-listing-grid, .found-leads-grid,
  .email-digest-grid, .push-notifications-grid,
  .recent-sale-grid, .for-sale-grid, .for-rent-grid {
    padding-right: 20px;
    .MuiGrid-root{
      display: block;
      text-align: end;
    }
  }
  .hr-item {
    padding-right: 16px;
    >hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .settings-general-grid {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .button-cancel {
    padding-left: 20px !important;
    display: block;
    text-align: left;
  }
  .button-done {
    padding-right: 20px !important;
    display: block;
    text-align: right;
  }

  .settings-general-grid {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: $blue;
    }
    .has-error {
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #f44336 !important;
      }
    }
  }

  .reached-limit-container {
    padding: 20px;
    .warning-icon {
      padding-left: 5px;
    }
    .reached-limit-message {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.15px;
      color: $black-60-opaque;
    }
    .reached-limit-wrapper {
      width: 315px;
      background-color: #FDF4E7;
      border-radius: 4px;
      display: flex;
      padding: 14px;
      align-items: center;
    }
  }
}

.head-close .MuiButton-root {
    color: $black-54-opaque;
    cursor: pointer;
    &:hover {
      color: $blue;
      background-color: #FFFFFF;
    }
}
