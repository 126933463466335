@import "myDetails";
#security-question {
  @extend #account-my-details;
  &.box {
    & > div {
      overflow: visible;
    }
    .box-content {
      .box-content {
        &-body {
          & > div {
            & .flex-container-row {
              & .flex-row {
                &.action-buttons {
                  margin-top: 28px;
                  margin-right: 0;
                  margin-left: 0;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .sec-answer-panel {
    padding-top: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
  .security-question-buttons {
    padding-left: 15px;
    padding-top: 23px;
  }
  .block-ui-overlay {
    background-color: #ffffff;
  }
  .msg-panel {
    margin-left: 28%;
    padding-left: 15px;
    height: 17px;
  }
}
