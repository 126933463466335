.list-report-preview {
  padding: 27px 0 35px;
  min-height: calc(100vh - 106.47px);
  background-color: $dirty-white;
  &__wrapper{
    width: 895px;
    margin: 0 auto;
    font-size: 10px;
    color: $charcoal-gray;
    .row:nth-child(2) {
      border: 1px solid $black-12-opaque;
    }
    .rcs-outer-container{
      margin-right: -13px;
    }
    .rcs-custom-scroll{
      max-height: 658px;
    }
  }
  &__retry{
    border-radius: 2px;
    padding: 20px;
    margin-right: 4px;
    border: solid 1px $errorFieldBorder;
    background-color: $errorFieldBg;
    font-size: 12px;
    color: $charcoal-gray;
    & > p {
      margin-bottom: 5px;
      &:first-child{
        font-weight: 500;
      }
    }
    & > button{
      width: 76px;
      font-size: 12px !important;
    }
  }
  &__download{
    display: flex;
    & > div {
      flex-grow: 1;
      text-align: right;
      padding: 10px 0;
    }
  }
  .backdrop-cont {
    .custom-message {
      font-size: 26px;
      color: #FFF;
      display: flex;
      flex-direction: column;
      padding-top: 22px;
    }
    .crux-loader-svg {
      fill: #FFF;
    }
  }
  .pdf-loader {
    position: absolute;
    left: 2px;
    top: 0px;
    z-index: 2;
  }
  .pdf-canvas-wrapper {
    min-height: 700px;
    position: relative;

    canvas {
      display: block;
      margin: 0 auto;
      border: 1px solid $black-12-opaque;
      max-width: 100%;
      height: auto!important;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
.list-report-preview-page {
  margin-bottom: 10px;
  -webkit-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, .09);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, .09);
  user-select: none;
  &:last-child{
    margin-bottom:0;
  }
  &__head {
    display: flex;
    padding: 10px 15px;
    background-color: #FFF;
    & > div:first-child {
      flex-grow: 1;
    }
    & > div:last-child {
      font-weight: 500;
    }
    .report-preview__logo-container {
      direction: rtl;
      .report-preview__img-responsive {
        height: 30px;
      }
    }
  }
  &__content {
    background-color: #FFF;
    min-height: 595px;
    .table {
      margin-bottom: 0;
      table-layout: fixed;
      & > thead {
        background-color: $table-header-light-gray;
        & > tr {
          & > th {

            padding: 3px 8px;
            border-top: solid 0.5px #d3d3d3 !important;
            border-bottom: solid 0.5px #d3d3d3;
            font-weight: 500;
            vertical-align: top;
            &:first-child {
              padding-left: 15px;
            }
            &:last-child {
              padding-right: 15px;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            padding: 4px 8px;
            border-bottom: solid 0.5px #f2eded;
            word-break: break-word;
            &:first-child {
              padding-left: 15px;
            }
            &:last-child {
              padding-right: 15px;
            }
          }
        }
        & > .disclaimers {
          & > td {
            vertical-align: bottom;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
            & > .corelogic-disclaimers {
              padding: 15px 0;
              p:first-child{
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  &__foot {
    padding: 3px 15px 10px;
    background-color: #FFF;
    font-size: 8px;
    .report-preview_in-footer {
      display: flex;
      padding-bottom: 7px;
      & > div:first-child {
        flex-grow: 1;
      }
      & > div:last-child {
        font-weight: 500;
      }
      & > div:not(.legend) {
        -webkit-box-flex: 1;
        font-size: 10px;
        color: $charcoal-gray;
      }
    }
  }

  .no-layout {
    padding: 0 !important;
    border: none !important;
  }
  .avg-dom-panel {
    width: 500px;

    table > thead > tr {
      th:nth-child(1) {
        width: 120px;
      }
      th:nth-child(2) {
        width: 120px;
      }
      th:nth-child(3) {
        width: 100px;
      }
      th:nth-child(4) {
        width: 160px;
      }
    }
  }
  .summary-sales-panel, .attribute-sales-panel, .avg-dom-panel {
    padding-bottom: 15px;

    .sales-history-header, .rental-comparison-header {
      font-weight: 500;
      color: $charcoal-gray;
      font-size: 14px;
      padding-bottom: 10px;
      padding-left: 15px;
    }

    table > thead > tr > th, table > tbody > tr > td {
      padding: 5px 10px;
      color: $charcoal-gray;
      &:first-child {
        padding-left: 15px;
      }
      &:last-child {
        padding-right: 15px;
      }
    }
    table > tbody > tr > td {
      border-bottom: solid 0.5px #f2eded;

      &.preview-dark-bg {
        background-color: $table-header-light-gray;
      }
    }
    table > thead > tr > th {
      background-color: $table-header-light-gray;
      border-top: solid 0.5px #d3d3d3;
      border-bottom: solid 0.5px #d3d3d3;
      font-weight: 500;
    }
  }

  // property list report table headers
  &.property-list-report {
    .th-address {
      width: 145px; // remaining width will be place here
    }
    .th-sale-price {
      width: 86px;
    }
    .th-sale-date {
      width: 75px;
    }
    .th-lot {
      width: 165px;
    }
    .th-year-built {
      width: 75px;
    }
    .th-bed {
      width: 42px;
    }
    .th-bath {
      width: 45px;
    }
    .th-car {
      width: 42px;
    }
    .th-land-size {
      width: 72px;
    }
    .th-property-type {
      width: 100px;
    }
    &.with-distance {
      .th-address {
        width: 135px;
      }
      .th-lot {
        width: 119px;
      }
      .th-distance {
        width: 72px
      }
    }
  }
  // sales history report table headers
  &.sales-history-report {
    .th-sale-price {
      width: 70px;
    }
    .th-sale-date {
      width: 63px;
    }
    .th-sale-type {
      width: 82px;
    }
    .th-lot {
      width: 170px;
    }
    .th-year-built {
      width: 42px;
    }
    .th-land-size {
      width: 58px;
    }
    .th-property-type {
      width: 80px;
    }
    .th-bed {
      width: 30px;
    }
    .th-bath {
      width: 30px;
    }
    .th-car {
      width: 30px;
    }
    .th-sale-type {
      width: 70px;
    }
    .th-agency {
      width: 70px;
    }

    tbody > tr > td {
      &:nth-child(5), &:nth-child(6) {
        word-break: break-word;
      }
    }

    // remove border top for disclaimer
    tr.disclaimers > td {
      border-top: none;
    }

    .th-summary {
      &-type {
        width: 115px;
      }
      &-attr {
        width: 95px;
      }
      &-quantity {
        width: 95px;
      }
      &-lowest-sale {
        width: 100px;
      }
      &-highest-sale {
        width: 115px;
      }
      &-median-value {
        width: 115px;
      }
      &-avg-value {
        width: 115px;
      }
    }

    &.with-distance {
      .th-property-type {
        width: 73px;
      }
      .th-sale-type {
        width: 70px;
      }
      .th-agency {
        width: 70px;
      }
      .th-distance {
        width: 67px;
      }

      .th-summary {
        &-type {
          width: 115px;
        }
        &-attr {
          width: 100px;
        }
        &-quantity {
          width: 100px;
        }
        &-lowest-sale {
          width: 100px;
        }
        &-highest-sale {
          width: 115px;
        }
        &-median-value {
          width: 115px;
        }
        &-avg-value {
          width: 115px;
        }
      }
    }
  }
  &.on-the-market-report {
    .th-address {
      width: 160px;
    }
    .th-listing-price {
      width: 71px;
    }
    .th-listing-date {
      width: 66px;
    }
    .th-listing-type {
      width: 55px;
    }
    .th-DOM {
      width: 35px;
    }
    .th-lot {
      width: 93px;
    }
    .th-year-built {
      width: 40px;
    }
    .th-land-size {
      width: 58px;
    }
    .th-property-type {
      width: 61px;
    }
    .th-bed {
      width: 27px;
    }
    .th-bath {
      width: 32px;
    }
    .th-car {
      width: 27px;
    }
    .th-sale-type {
      width: 100px;
    }
    .th-agency {
      width: 67px;
    }
    .th-distance {
      width: 62px;
    }
    &.with-distance {
      .th-property-type {
        width: 73px;
      }
    }
    tbody > tr > td,
    thead > tr > th {
      padding-left: 5px;
      padding-right: 5px;
    }
    tbody > tr > td {
      &:nth-child(6), &:nth-child(2) {
        word-break: break-word;
      }
    }
  }
  // rental comparison report table headers
  &.rental-comparison-report {
    .th-address {
      width: 170px;
    }
    .th-rental-price {
      width: 78px;
    }
    .th-rental-date {
      width: 70px;
    }
    .th-DOM {
      width: 30px;
    }
    .th-agency {
      width: 83px;
    }
    .th-lot {
      width: 108px;
    }
    .th-year-built {
      width: 43px;
    }
    .th-land-size {
      width: 59px;
    }
    .th-property-type {
      width: 81px;
    }
    .th-bed {
      width: 30px;
    }
    .th-bath {
      width: 30px;
    }
    .th-car {
      width: 30px;
    }
    .th-sale-type {
      width: 100px;
    }

    // remove border top for disclaimer
    tr.disclaimers > td {
      border-top: none;
    }
    thead > tr > th {
      padding: 3px 6px;
    }
    tbody > tr > td {
      padding: 3px 6px;
      &:nth-child(5), &:nth-child(6) {
        word-break: break-word;
      }
    }

    &.with-distance {
      thead > tr > th {
        padding: 3px 5px;
        &:last-child {
          padding-right: 10px;
        }
        &:first-child {
          padding-left: 10px;
        }
      }
      tbody > tr > td {
        padding: 3px 5px;
        &:last-child {
          padding-right: 10px;
        }
        &:first-child {
          padding-left: 10px;
        }
      }
      .th-address {
        width: 145px;
      }
      .th-distance {
        width: 53px;
      }
      .th-agency {
        width: 100px;
      }
      .th-lot {
        width: 102px;
      }
      .th-bed {
        width: 30px;
      }
      .th-bath {
        width: 30px;
      }
      .th-car {
        width: 30px;
      }
      .th-DOM {
        width: 35px;
      }
      .th-property-type {
        width: 76px;
      }
      .th-rental-date {
        width: 70px;
      }
      .list-report-preview-page__head {
        padding: 10px 10px;
      }
    }
  }
  .legend {
    font-size: 8px;
    margin-right: 20px;
    align-self: center;
    &__historic {
      margin-right: 3.5px;
      sup {
        font-size: 8px;
      }
    }
    &__historic--header {
      margin-right: 6px;
      sup {
        font-size: 8px;
      }
    }
  }
}
.ssr {
  .pdf-canvas-wrapper {
    canvas {
      display: block;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@-moz-document url-prefix() {
  .list-report-preview {
    padding: 27px 0 42px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .list-report-preview {
    padding: 27px 0 46px;
  }
}
