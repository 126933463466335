@import "source/css/crux/variable";
@import 'source/css/crux/mixins';

.crux-print-dialog {
  .scroll-body {
    width: 100%;
    max-width: 960px;
  }
  .crux-print-dialog-title {
    padding: 24px 24px 20px;
    border: 0;
    font-size: 20px;
    color: $charcoal-gray;
    word-break: break-all;
    font-weight: 500;
    line-height: 1.2;
  }
  .crux-print-dialog-footer{
    padding: 15px 35px;
    margin: 0;
    .pdf-btn{
      text-transform: none !important;
      color: #FFFFFF;
      background-color: $heading-color;
      width: 119px;
      font-size: 13px !important;
      font-weight: 400 !important;
      &:hover{
        background-color: rgba(0, 0, 0, 0.87);
      }
      &:focus {
        background-color: rgba(0, 0, 0, 0.87);
      }
      &.pdf-btn-disabled {
        background-color: $disabled !important;
        border: none !important;
       }
    }
    img{
      margin-right: 5px;
    }
  }
  .crux-print-dialog-body {
    height: calc(100vh - 237px);
    overflow-y: auto;
    margin: 0 3px 0 0;
    padding: 0 24px 24px;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

.crux-panel-dialog {
  & > div {
    &:last-child {
      max-width: 1326px;
      width: 100%;
      max-height: calc(100vh - 27px);
    }
  }
  & {
      .crux-panel-header {
        padding: 0;
          & {
              .container {
                  display: flex;
                  align-items: center;
              }
              .col-md-3{
                  width: 25.7% !important;
              }
          }
        .flex-container{
          padding:  25px 0 0 15px;
          display: flex;
          & > div{
            @media screen and (max-width: 1279px) {
              padding: 0 15px;
            }
            &:first-child{
              max-width: 50%;
              padding-left: 0;
              display: flex;
              align-items: flex-end;
              & > .street-label {
                color: $heading-color;
                font-size: 14px;
                font-weight: 500;
              }
            }
            &.panel-modal-btn-container{
              position: absolute;
              right: 5px;
              align-items: flex-end;
              max-width: 50%;
              padding-right: 10px;
              display: flex;
              flex-grow: 1;
              .btn-crux-primary i{
                vertical-align: sub;
                font-size: 18px;
              }
              & > div {
                &:first-child {
                  display: flex;
                  justify-content: flex-end;
                  flex-wrap: wrap;
                  button {
                    @media screen and (max-width: 1024px) {
                      margin-bottom: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        .align-base{
          padding:  0 0 10px 15px;
          display: flex;
          align-items: flex-end;
          .list-inline{
            margin-right: 0;
            margin-bottom: 5px;
          }
          .land-area-tool-tip-div{
            display: inline-block;
            margin-left: 5px;
            & > p {
              margin-bottom: 0;
            }
          }
        }
      }
      .panel-modal-btn{
        text-transform: none !important;
        width: 100px;
        height: 40px;
        border: 0;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);
        @include center-align;
        position: absolute;
        top: 9px;
        right: 8px;
        z-index: 3;
        font-size: 13px;
        color: $heading-color;
        & > i {
          display: inline-block;
          margin-left: 18px;
          color: $heading-color;
          font-size: 20px;
          font-weight: 400;
        }
        &:hover {
          background-color: #fff;
          color: $blue;
          & > i {
            color: $blue;
          }
        }
        &:not(:first-child) {
              margin-left: 10px;
          }
      }
      .panel-modal-btn-container{
        text-align-last: end;
        position: initial;
        .btn-crux-primary i{
          vertical-align: sub;
          font-size: 18px;
          color:pink;
        }
        & > div {
          &:first-child {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            button {
              @media screen and (max-width: 1024px) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      .crux-panel-dialog-body{
        padding: 0 !important;
        position: relative;
        overflow-y: hidden;
        .property-header{
          background-color: $body-bg;
          margin: 0px -15px 0px -15px;
          .address-attribute{
            padding-left: 43px;
            .street-label {
              color: $heading-color;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .media {
            padding: 20px 20px 10px 20px;
            .media-body {
              .media-heading {
                font-size: 14px;
                font-weight: 500;
                color: $heading-color;
              }
            }
            .error-fetching {
              min-height: 55px;
              padding: 0;
            }
          }
          .tab-container.underlined-tabs .tabs .tab-menu {
            max-width: 264px;
            font-size: 13px;
            color: $gray-text;
            font-weight: 500;
            text-transform: capitalize;
            opacity: 0.7;
            &.Mui-selected {
              opacity: 1!important;
            }
          }
        }
        .nearby-school-container {
          height: 100%;
          & > #nearby-school-view {
            height: 100%;
            #nearby-school-listing-container {
              height: 100%;
              .content {
                height: 100%;
                .scroll-container {
                  .simplebar-content {
                    margin-right: 0;
                  }
                }
                .nearby-school-listing-tabs {
                  background-color: $lighter-gray-bg;
                  margin-bottom: 5px;
                  border-radius: 5px;
                  min-height: 40px;
                  .tab-menu {
                    min-height: 40px;
                  }
                }
              }
            }
          }
        }
        .error-fetching {
          padding: 0;
          height: 85%;
          color: $gray-text;
        }
        .map-column{
          height: calc(100vh - 100px);
          padding: 0 !important;
          @media only screen and (min-width: 1326px) {
            width: calc(100% - 410px);
          }
        }
        .list-column{
          height: calc(100vh - 100px);
          @media only screen and (min-width: 992px){
            max-width: 410px;
          }
        }
        .group-marker-bubble-properties {
          min-width: 443px;
          .simplebar-wrapper {
            max-height: 303px;
            .simplebar-content {
              margin-right: 0;
            }
          }
        }
      }
    }
    .map-container{
      height: 100%;
      width: 100%;
      .map{
          width: 100%;
          height: 100%;
        .map-control {
          margin-left: -10px;
          margin-top: -4px;
          float: top;
          background-color: rgb(255, 255, 255);
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
          cursor: pointer;
          text-align: center;
          position: absolute;
          font-family: $poppins;
          font-size: 11px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgb(86, 86, 86);
          padding: 13px;
          min-width: 68px;
          min-height: 32px;
        }
        .map-control:hover {
          background-color: rgb(235, 235, 235);
          color: black;
        }
      }
      .transaction-summary {
        position: relative;
        color: $gray-text;
        > p {
          // Preventing undisclosed icon to break into new line
          .undisclosed-sales {
            white-space: nowrap;
            // Setting the container (.undisclosed-sales) to nowrap makes
            // the tooltip text to display in a single line manner.
            // Counter ruling parent's 'white-space: nowrap'
            .__react_component_tooltip {
              white-space: pre-wrap;
            }
          }
          .undisclosed-price-info{
            position: initial;
          }
        }
      }
    }
}
